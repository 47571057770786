import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { CustomTooltip } from "../shared/customTooltip";

export const IconList = ({ listData, validate = false }: any) => {
  const validateEntry = (x: any) => !validate || (validate && x.valid);

  return (
    <List
      disablePadding
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {listData.filter(validateEntry).map((item: any, key: any) => (
        <ListItem disablePadding sx={{ gap: 4 }} key={`iconList${key}`}>
          <ListItemIcon sx={{ minWidth: "auto" }}>{item.icon}</ListItemIcon>
          <ListItemText
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              margin: 0,
            }}
            disableTypography
          >
            <Typography variant="body1" color="textSecondary">
              {item.text}
            </Typography>
            {item.tooltip && (
              <CustomTooltip title={item.tooltip} maxWidth={{xs: "163px"}} isDark>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                  }}
                />
              </CustomTooltip>
            )}
            {item.action && (
              <Chip
                label="Action required"
                color="primary"
                sx={{
                  width: "max-content",
                  "& .MuiChip-label": {
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 600,
                  },
                }} />
            )}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
