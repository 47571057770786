import axios from "axios";
import { useAppSelector } from "../store/hook";

interface FAQ {
  groupId: string;
  groupName: string;
  question: string;
  answer: string;
  link: boolean;
  isProjectStageVisible: boolean;
}

interface FAQProject {
  title: string;
  details: string;
}

interface GroupedFAQ {
  id: string;
  title: string;
  faqs: FAQ[];
}

interface UseFaqHook {
  faqsData: GroupedFAQ[];
  fetchFAQData: () => Promise<GroupedFAQ[]>;
  groupFaqProjectVisible: (groupId: any) => FAQProject[];
}

export default function useFaq(): UseFaqHook {
  const { isBatteryOnly } = useAppSelector(
    (state) => state.portal || ([] as any)
  );
  const faqsData = useAppSelector((state) => state.faq || ([] as any));

  const fetchFAQData = async () => {
    const csvUrl =
      (isBatteryOnly
        ? process.env.REACT_APP_FAQ_BATTERY_GSHEET_URL
        : process.env.REACT_APP_FAQ_GSHEET_URL) || "";

    try {
      const response = await axios.get(csvUrl);
      const parsedCsvData = parseCSV(response.data);
      const groupedFaqData = groupCsvData(parsedCsvData);
      return groupedFaqData;
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return [];
    }
  };

  const parseCSV = (csvText: string): FAQ[] => {
    const rows = csvText.split(/\r\n/);
    const headers = rows[0].split(/,(?![ ])/);
    const data = [];
    for (let i = 1; i < rows.length; i++) {
      const rowData = rows[i].split(/,(?![ ])/);
      const rowObject: any = {};
      for (let j = 0; j < headers.length; j++) {
        const key = headers[j].trim();
        rowObject[key] = formatValue(rowData[j]);
      }
      data.push({ ...rowObject, link: false });
    }
    return data;
  };

  const groupCsvData = (dataArray: FAQ[]): GroupedFAQ[] => {
    let groupedData = dataArray.reduce((acc: any, obj: any) => {
      const groupId = obj.groupId;

      if (!acc[groupId]) {
        acc[groupId] = {
          id: groupId,
          title: obj.groupName,
          faqs: [],
        };
      }

      acc[groupId].faqs.push({
        question: obj.question,
        answer: obj.answer,
        link: obj.link,
        isProjectStageVisible: obj.isProjectStageVisible === "TRUE",
      });

      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const formatValue = (value: string) => {
    let formattedValue = value;

    //remove quotes from the start and end
    formattedValue = formattedValue.replace(/^"|"$/g, "");
    //fix a tag
    formattedValue = formattedValue.replace('href=""', 'href="');
    formattedValue = formattedValue.replace('"">', '">');
    //newline
    formattedValue = formattedValue.replace(/<\/li>\n/g, "</li>");
    formattedValue = formattedValue.replace(/\n/g, "<br/>");

    return formattedValue;
  };

  const groupFaqProjectVisible = (groupId: any) => {
    const group = faqsData.find((g) => g.id === groupId);

    if (!group) {
      return [];
    }

    const groupedProjectVisible = group.faqs
      .filter((q) => q.isProjectStageVisible)
      .map((q) => ({
        title: q.question,
        details: q.answer,
      }));

    return groupedProjectVisible;
  };

  return { faqsData, fetchFAQData, groupFaqProjectVisible };
}
