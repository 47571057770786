import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { VerificationCode } from "../../components/signup/verificationCode";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";

import * as api from "../../api";

import { normalizeUSPhone } from "../../utils/normalizeUSPhone";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

export const PhoneNumberVerifyOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState('');

  const { id, phoneNumber, phoneNumberVerified, secondaryContactId, secondaryContact } =
    useAppSelector((state) => state.portal || ({} as any));
    const { customerId } = useAppSelector(
      (state) => state.me || ({} as any)
    );
    const isSecondaryContact = secondaryContactId === customerId;
    const currentStep = isSecondaryContact ? 6 : 2;
  
  const [isLoading, setIsLoading] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);

  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    if (phoneNumberVerified) {
      const nextPage = isSecondaryContact ? "/onboarding/success" : "/onboarding/second-contact";
      setTimeout(() => {
        navigate(nextPage);
      }, 1000);
      return
    }

    let validPhone = null;
    if (isSecondaryContact && secondaryContact.phoneNumber) {
      validPhone = secondaryContact.phoneNumber;
    } else if (phoneNumber) {
      validPhone = phoneNumber;
    }

    if (!!validPhone) {
      setVerifyPhoneNumber(validPhone);
    } else {
      navigate("/onboarding/phone");
    }
  }, []);

  const handleSubmit = async (finalCode = []) => {
    setIsLoading(true);
    try {
      await api.onboarding.verifyPhoneNumber(id, {
        to: verifyPhoneNumber as string,
        code: finalCode.join("") as string,
      });

      const nextPage = isSecondaryContact ? "/onboarding/success" : "/onboarding/second-contact";
      const payload = { phoneNumberVerified: true, onboardingStep: nextPage };
      api.portal.update(id, payload).catch((err) => console.error(err));

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("phoneVerification", {
          phone: phoneNumber
        }),
      });
      
      dispatch(setPortalProps(payload));
      setTimeout(() => {
        navigate(nextPage);
      }, 1000);
    } catch (error) {
      setCodeError(true);
      setIsLoading(false);
    }
  };

  const handleResend = () => {
    if (resendIsLoading) return;

    setResendIsLoading(true)
    api.onboarding
      .sendPhoneVerification(id, verifyPhoneNumber)
      .then(() => {
        setResendIsLoading(false)
        enqueueSnackbar("Verification code sent!", {
          variant: "default",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
      })
      .catch(() => {
        setResendIsLoading(false)
        enqueueSnackbar("Failed to resend code. Please try again!", {
          variant: "error",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
      });
  };

  return (
    <VerificationLayout
      currentStep={currentStep}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/phone")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/phone")}
        headerText="Verify your phone number"
        headerVariant="h5"
        subHeaderText={
          phoneNumberVerified ? (
            "Thank you for verifying your phone!"
          ) : (
            <>
              We’ve sent a verification code to{" "}
              {normalizeUSPhone(verifyPhoneNumber)}.<br />
              Please enter it below.
            </>
          )
        }
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={currentStep}
      />

      <VerificationCode
        formLabel="Phone verification"
        errorText="Incorrect code"
        inputLength={6}
        isLoading={isLoading}
        resendIsLoading={resendIsLoading}
        isVerified={phoneNumberVerified}
        error={codeError}
        setError={setCodeError}
        handleSubmit={handleSubmit}
        resendNote="Didn’t get a code?"
        resendText="Resend phone verification"
        handleResend={handleResend}
      />
    </VerificationLayout>
  );
};
