import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const Dialogs = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Button variant="outlined" onClick={handleOpen}>
        Open dialog
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            paddingTop: 6,
            paddingBottom: 3,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Box marginLeft={"auto"}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography variant="h6" textAlign={"center"}>
            You have unsaved changes
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBottom: 5,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Typography variant="subtitle2" textAlign={"center"}>
            Are you sure you want to leave without saving?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 6,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 4,
            }}
            justifyContent={{
              xs: "center",
              sm: "space-between",
            }}
          >
            <Button
              variant="text"
              size="small"
              color="error"
              autoFocus
              onClick={handleClose}
            >
              Leave without saving
            </Button>
            <Button
              variant="contained"
              size="small"
              autoFocus
              onClick={handleClose}
            >
              Save changes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
