import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { CustomTooltip } from "../shared/customTooltip";

export const AccordionIconList = ({ listData, validate = false }: any) => {
  const validateEntry = (x: any) => !validate || (validate && x.valid);
  return (
    <Stack gap={2}>
      {listData.filter(validateEntry).map((item: any, key: any) => (
        <Accordion
          defaultExpanded={!item.collapse}
          disableGutters
          elevation={0}
          square
          sx={{
            background: "transparent",
            "&::before": {
              display: "none",
            },
            "& .MuiButtonBase-root": {
              padding: 0,
              minHeight: "unset",
              width: "100%",
              justifyContent: "flex-start",
              gap: 4,
              cursor: item.timeline ? 'pointer' : 'default !important'
            },
            "& .MuiAccordionSummary-content": {
              margin: 0,
              gap: 4,
              flex: "unset",
              alignItems: "center",
              cursor: item.timeline ? 'pointer' : 'default'
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#B4BDC5",
            },
          }}
          key={`item${key}`}
        >
          <AccordionSummary expandIcon={item.timeline && <ExpandMoreIcon />} sx={{
            cursor: item.timeline ? 'pointer' : 'default'
          }}>
            {item.icon}
            <Typography variant="body1" color="textSecondary">
              {item.title}
            </Typography>
            {item.tooltip && (
              <CustomTooltip title={item.tooltip} maxWidth={{xs: "163px"}} isDark>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                  }}
                />
              </CustomTooltip>
            )}

          </AccordionSummary>
          {item.timeline && (
            <AccordionDetails sx={{ padding: "0.5rem 0.5rem 0 2.5rem" }}>
              <Timeline
                sx={{
                  margin: 0,
                  padding: 0,
                  "& .MuiTimelineItem-root": {
                    minHeight: "unset",
                  },
                  "& .MuiTimelineItem-root:before": {
                    flex: 0,
                    padding: 0,
                  },
                  "& .MuiTimelineSeparator-root": {
                    minWidth: "12px",
                  },
                  "& .MuiTimelineDot-root": {
                    margin: "5px auto",
                  },
                  "& .MuiTimelineContent-root": {
                    flex: "unset",
                    padding: "0 0.5rem 1rem 1rem",
                  },
                }}
              >
                {item.timeline.filter(validateEntry).map((timelineItem: any, key: any) => (
                  <TimelineItem key={`timelineItem${key}`}>
                    <TimelineSeparator>
                      {timelineItem.icon}
                      {key !== item.timeline.filter(validateEntry).length - 1 && (
                        <TimelineConnector sx={{ width: "1px" }} />
                      )}
                    </TimelineSeparator>
                    <TimelineContent
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Typography variant="body1" color="textSecondary">
                        {timelineItem.text}
                      </Typography>
                      {timelineItem.subText && (
                        <Typography variant="body2" color="secondary.light">
                          {timelineItem.subText}
                        </Typography>
                      )}
                      {timelineItem.action && (
                        <Chip label="Action required" onClick={item.action} />
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </Stack>
  );
};
