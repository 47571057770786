import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { MobileFooter } from "../shared/mobileFooter";

export const ContainerLayout = ({
  children,
  headerText,
  subHeaderText,
  cta,
  withMobileHeaderText,
  withMobileSubHeaderText,
}: any) => {
  return (
    <>
      <Stack
        width={"100%"}
        direction="column"
        p={{ xs: "1.5rem", sm: 10 }}
        gap={6}
      >
        <Stack
          direction="row"
          alignItems={subHeaderText ? "flex-start" : "center"}
          justifyContent="space-between"
          gap={6}
          display={{
            xs:
              withMobileHeaderText || withMobileSubHeaderText ? "flex" : "none",
            sm: "flex",
          }}
        >
          <Stack gap={2}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              display={{
                xs: withMobileHeaderText ? "block" : "none",
                sm: "block",
              }}
            >
              {headerText}
            </Typography>
            {subHeaderText && (
              <Typography
                variant="body1"
                display={{
                  xs: withMobileSubHeaderText ? "block" : "none",
                  sm: "block",
                }}
                textAlign={{
                  xs: "center",
                  sm: "left",
                }}
              >
                {subHeaderText}
              </Typography>
            )}
          </Stack>
          {cta && (
            <Stack
              gap={6}
              direction="row"
              alignItems="center"
              display={{
                xs: "none",
                sm: "flex",
              }}
            >
              {cta}
            </Stack>
          )}
        </Stack>
        {children}
      </Stack>
      {cta && <MobileFooter cta={cta} />}
    </>
  );
};
