import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface MeState {
  id: string | null;
  email: string | null;
  auth: string | null;
  lastVisitedPortal: string | null;
  portals: IPortal[];
  pendingDownloadFiles: string[];
  isImpersonating: boolean;
  impersonatePortal: string | null;
  impersonateTtl: string | null;
  customerId: string | null;
  isPromotionDisplayed: boolean;
  showOffer: boolean;
}

interface IPortal extends IPortalData {
  orgId: string; // Sunrise org ID
  id: string; // Sunrise project ID
  contactId: string; // Sunrise customer ID
  secondaryContactId?: string | null;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
  addressConfirmed: boolean;
  onboardingStep: string;
}

interface IPortalData extends IPortalContact {
  secondaryContact?: IPortalContact;
  notificationPrefs?: IPortalNotificationPrefs;
  propertyDetails?: IPortalPropertyDetails;
  hoaDetails?: IPortalHoaDetails;
  systemDetails?: IPortalSystemDetails;
}

interface IPortalNotificationPrefs {
  email?: boolean; // Computed property from sunrise
  sms?: boolean; // Computed property from sunrise
  preferredTimeOfDay?: string; // Computed property from sunrise
}

interface IPortalContact {
  firstName?: string; // Computed property from sunrise
  lastName?: string; // Computed property from sunrise
  phoneNumber?: string; // Computed property from sunrise
  email?: string; // Computed property from sunrise
  address?: string; // Computed property from sunrise
}

interface IPortalPropertyDetails {
  utilityCompany: string; // Computed property from sunrise
  gateCode: string; // Computed property from sunrise
  wifiNetwork: string; // Computed property from sunrise
  wifiPassword: string; // Computed property from sunrise
}

interface IPortalHoaDetails {
  name: string; // Computed property from sunrise
  email: string; // Computed property from sunrise
  phoneNumber: string; // Computed property from sunrise
  portalWebsite: string; // Computed property from sunrise
  portalUsername: string; // Computed property from sunrise
  portalPassword: string; // Computed property from sunrise
}

interface IPortalSystemDetails {
  panelType: string; // Computed property from sunrise
  panelQuantity: string; // Computed property from sunrise
  monitoringDevice: string; // Computed property from sunrise
  warrantyNumber: string; // Computed property from sunrise
  systemSize: string; // Computed property from sunrise
  teslaPowerwallsQuantity: string; // Computed property from sunrise
  amount: string; // Computed property from sunrise
  lender: string; // Computed property from sunrise
  yearlyProduction: string; // Computed property from sunrise
}

const initialState: MeState = {
  id: null,
  email: null,
  auth: null,
  lastVisitedPortal: null,
  portals: [],
  pendingDownloadFiles: [],
  isImpersonating: !!localStorage.getItem("impersonator"),
  impersonatePortal: localStorage.getItem("impersonateportal"),
  impersonateTtl: localStorage.getItem("impersonatettl"),
  customerId: null,
  isPromotionDisplayed: false,
  showOffer: false
};

export const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setMyProfileProps: (state, action: PayloadAction<any>) => {
      const updateObj = action.payload;

      _.each(updateObj, (v, k) => _.set(state, k, v));
      return state;
    },
    setMyProfile: (state, action: PayloadAction<any>) => {
      return Object.assign(state, action.payload);
    },
    logout: (state) => {
      return Object.assign(state, initialState);
    },
    setPendingDownloadFile: (state, action: PayloadAction<string[]>) => {
      return Object.assign(state, { pendingDownloadFiles: action.payload });
    }
  }
});

export const {
  setMyProfileProps,
  setMyProfile,
  logout,
  setPendingDownloadFile
} = meSlice.actions;

export default meSlice.reducer;
