export const typography: any = {
  htmlFontSize: 16,
  fontFamily: "Poppins, sans-serif",
  h1: {
    fontSize: "4.5rem",
    lineHeight: "5.625rem",
    fontWeight: "inherit",
  },
  h2: {
    fontSize: "3.75rem",
    lineHeight: "4.625rem",
    fontWeight: "inherit",
  },
  h3: {
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: "inherit",
  },
  h4: {
    fontSize: "2.25rem",
    lineHeight: "2.75rem",
    fontWeight: "inherit",
  },
  h5: {
    fontSize: "2rem",
    lineHeight: "2.375rem",
    fontWeight: "inherit",
  },
  h6: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
    fontWeight: "inherit",
  },
  button: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    textTransform: "none",
    fontWeight: "600",
  },
  subtitle1: {
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
    fontWeight: "inherit",
  },
  subtitle2: {
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    fontWeight: "inherit",
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: "inherit",
  },
  body2: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: "inherit",
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    fontWeight: "inherit",
  },
};
