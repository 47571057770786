import { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import CircularProgress from "@mui/material/CircularProgress";

import { MiddleContainer } from "./middleContainer";
import { Link } from "@mui/material";

export const VerificationCode = ({
  formLabel,
  errorText,
  isLoading,
  resendIsLoading,
  isVerified,
  error,
  setError,
  handleSubmit,
  resendNote,
  resendText,
  handleResend,
  inputLength = 5,
}: any) => {
  const [code, setCode] = useState([]);

  const handleCodeChange = (e: any, i: any) => {
    const { value } = e.target;
    const nextInput = `codeInput${i + 1}`;
    const regex = /^[0-9]+$/;

    if (
      (!regex.test(value) && value !== "") ||
      e.key === "Backspace" ||
      value === ""
    ) {
      e.preventDefault();
      return;
    }

    if (value.length > 2) {
      handleOnPaste(value);
    } else {
      setError(false);
      let newCode: any = [...code];
      newCode[i] = value.slice(-1);
      setCode(newCode);

      if (i < inputLength - 1) {
        document.getElementById(nextInput)?.focus();
      }

      const checkNotEmpty = newCode.filter((e: any) => e && e !== "");

      if (checkNotEmpty.length === inputLength && i === inputLength - 1) {
        handleSubmit(newCode);
      }
    }
  };

  const handleKeyDown = (e: any, i: any) => {
    let newCode: any = [...code];
    const prevInput = i === 0 ? `codeInput${i}` : `codeInput${i - 1}`;
    const nextInput = `codeInput${i + 1}`;

    if (e.key === "Backspace") {
      setError(false);
      newCode[i] = "";
      setCode(newCode);

      e.preventDefault();
      document.getElementById(prevInput)?.focus();
    }
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      document.getElementById(prevInput)?.focus();
    }
    if (e.key === "ArrowRight") {
      e.preventDefault();
      document.getElementById(nextInput)?.focus();
    }
  };

  const handleOnPaste = (code: any) => {
    const newCode = code.split("");
    const nextInput = `codeInput${code.length - 1}`;
    setCode(newCode);
    document.getElementById(nextInput)?.focus();

    if (newCode.length === inputLength) {
      handleSubmit(newCode);
    }
  };

  return (
    <MiddleContainer>
      {isVerified && (
        <Stack direction="row" justifyContent="center">
          <i
            className="i-verified-check"
            style={{
              width: "11rem",
              minWidth: "11rem",
              height: "9.5rem",
            }}
          />
        </Stack>
      )}
      {!isVerified && (
        <Stack gap={2}>
          {isLoading && (
            <Typography
              variant="body1"
              color="text.light"
              textAlign="right"
              display={{ xs: "none", sm: "block" }}
            >
              <CircularProgress size={16} />
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            display={{ xs: "flex", sm: "none" }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              textAlign="left"
              fontWeight={600}
            >
              {formLabel}
            </Typography>
            {isLoading && <CircularProgress size={16} />}
          </Stack>

          <Stack direction="row" gap={2.5}>
            {[...Array(inputLength)].map((c, i) => (
              <Box
                width={`calc(100% / ${inputLength})`}
                height="auto"
                key={`boxCodeKey${i}`}
              >
                <TextField
                  id={`codeInput${i}`}
                  key={`codeKey${i}`}
                  sx={{
                    width: "100%",
                    height: "100%",
                    "& input": {
                      textAlign: "center",
                      fontSize: "2.25rem",
                      lineHeight: "2.75rem",
                    },
                  }}
                  value={code[i] || ""}
                  onChange={(e) => handleCodeChange(e, i)}
                  onKeyDown={(e) => handleKeyDown(e, i)}
                  error={error}
                  disabled={isLoading}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "d*",
                  }}
                />
              </Box>
            ))}
          </Stack>
          {error && (
            <Typography variant="body1" color="error.dark">
              {errorText}
            </Typography>
          )}

          <Typography variant="body1" color="text.light">
            {resendNote}{" "}
            <Link
              component="button"
              onClick={() => handleResend()}
              disabled={isLoading}
              sx={{
                textDecoration: "none",
                fontWeight: 600,
              }}
            >
              {resendText}
            </Link> { resendIsLoading && <CircularProgress size={16} /> }
            
          </Typography>
        </Stack>
      )}
    </MiddleContainer>
  );
};
