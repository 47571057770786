import { useParams, useNavigate } from "react-router-dom";
import { VerificationLayout } from "../components/signup/verificationLayout";
import { EmailContent } from "../components/signup/emailContent";

export const SignupEmail = () => {
  const { id: inviteId } = useParams();
  const navigate = useNavigate();

  return (
    <VerificationLayout
      currentStep={0}
      withBackButton
      handleBack={() => navigate(-1)}
    >
      <EmailContent inviteId={inviteId} />
    </VerificationLayout>
  );
};
