import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { isBrowser } from "react-device-detect";
import { useSnackbar } from "notistack";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import { DashboardLayout } from "../../components/dashboard/dashboardLayout";
import { ErrorState } from "../../components/shared/errorState";

import {
  documentFolderLabels,
  formatDocument,
} from "../../components/constant/document";
import { downloadFiles } from "../../services/download";
import { gray } from "../../theme/colors";
import * as api from "../../api";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setPendingDownloadFile } from "../../store/modules/meSlice";

const imagePaperStyle = {
  display: "flex",
  flexDirection: "column",
  boxShadow: 3,
  padding: "1rem",
  gap: "1rem",
  width: {
    xs: "calc(100% / 2 - 10.5px)",
    sm: "calc(100% / 4 - 18px)",
  },
  cursor: "pointer",
  position: "relative",
};

const ellipsisStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  whiteSpace: "nowrap",
};

export const Folder = () => {
  const navigate = useNavigate();
  const { portalId, folder } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pendingDownloadFiles } = useAppSelector(
    (state) => state.me || ({} as any)
  );

  const [isError, setIsError] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [waitToDownload, setWaitToDownload] = useState(null);

  const pathData = {
    name: _.get(documentFolderLabels, [folder as string], "--"),
    exact: false,
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        const res = await api.documents.get(portalId as string, { folder });
        setDocuments(formatDocument(res.data));
      } catch (error) {
        console.error(error);
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchDocuments();
  }, []);

  useEffect(() => {
    if (_.isEmpty(pendingDownloadFiles) && waitToDownload) {
      setIsDownloading(false);
      setWaitToDownload(null);
      setSelected([]);
    }
  }, [pendingDownloadFiles]);

  const handleClick = (event: any, id: string) => {
    const selectedIndex = selected.indexOf(id);
    const newSelected = _.cloneDeep(selected);

    if (event.target.nodeName === "BUTTON") {
      return;
    }

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const downloadDocs = selected.map((id) => ({ fileId: id }));
      const toZip = isBrowser && downloadDocs.length > 1; // Assume we don't have folders in here

      const res = await api.documents.download(
        portalId as string,
        toZip,
        downloadDocs
      );
      const { immediate, files, zipFile } = _.get(res, "data", {});
      if (!_.isEmpty(files)) {
        if (immediate) {
          // Download the files immediately - For mobile users
          await downloadFiles(files);
          setSelected([]);
          setIsDownloading(false);
        } else {
          // Save file to async download
          const newPendingDownloads = [
            ...pendingDownloadFiles,
            zipFile.fileName,
          ];
          dispatch(setPendingDownloadFile(newPendingDownloads));
          setWaitToDownload(zipFile.fileName);
        }
      }
    } catch (error) {
      setIsDownloading(false);
      enqueueSnackbar("Failed to download files. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  return (
    <DashboardLayout customPathData={pathData}>
      {isError && (
        <Stack
          sx={{
            minHeight: "calc(100dvh - 5rem)",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <ErrorState />
        </Stack>
      )}
      {isLoading && (
        <Stack
          sx={{
            minHeight: "100dvh",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={60} />
        </Stack>
      )}
      {!isLoading && !isError && (
        <Paper
          sx={{
            display: { xs: "none", sm: "flex" },
            borderRadius: {
              xs: 0,
              sm: "8px 8px 0px 0px",
            },
            boxShadow: {
              xs: 0,
              sm: 3,
            },
            borderBottom: `1px solid ${gray[100]}`,
            padding: "1rem",
          }}
        >
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                size="large"
                onClick={() => navigate(`/${portalId}/documents`)}
              >
                <KeyboardArrowLeftIcon fontSize="inherit" />
              </IconButton>
            </Stack>

            <Stack direction="row" alignItems="center" gap={2}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                fontWeight={600}
              >
                {pathData.name}
              </Typography>
              <Typography variant="body1" color="text.light">
                {selected.length > 0
                  ? `${selected.length} selected`
                  : `${documents.length} images`}
              </Typography>
            </Stack>
            <Button
              variant="contained"
              size="large"
              sx={{
                visibility: selected.length > 0 ? "visible" : "hidden",
              }}
              onClick={handleDownload}
              disabled={isDownloading}
            >
              {isDownloading ? (
                <>
                  <CircularProgress color="inherit" size={16} thickness={4.5} />
                  Downloading
                </>
              ) : (
                "Download"
              )}
            </Button>
          </Stack>
        </Paper>
      )}

      <Box
        sx={{
          minHeight: { xs: "calc(100dvh - 71px)", sm: "auto" },
          background: {
            xs: "#F9F9FB",
            sm: "transparent",
          },
          padding: 4,
          paddingX: {
            xs: 3,
            sm: 0,
          },
          paddingBottom: { xs: 3, sm: 0 },
        }}
      >
        <Stack direction="row" width="100%" gap={6} flexWrap="wrap">
          {documents.map((doc: any, key: any) => {
            const isItemSelected = isSelected(doc.id);
            return (
              <Paper
                tabIndex={-1}
                sx={{
                  ...imagePaperStyle,
                  backgroundColor: isItemSelected ? "#FFEDE0" : "#ffffff",
                }}
                onClick={(e) => handleClick(e, doc.id)}
                key={`file${key}`}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0.5rem",
                    left: "0.5rem",
                    zIndex: 1,
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    sx={{
                      color: "#ffffff",
                    }}
                  />
                </Box>
                <Box
                  component="img"
                  src={`${process.env.REACT_APP_SUNRISE_FILES_URL}/${doc.thumbnail}`}
                  alt="sample image"
                  loading="lazy"
                  width="100%"
                />
                {/* <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={ellipsisStyle}
                >
                  {doc.name}
                </Typography> */}
              </Paper>
            );
          })}
        </Stack>
      </Box>
      {selected.length > 0 && (
        <Button
          variant="contained"
          size="large"
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
          }}
          disabled={isDownloading}
          onClick={handleDownload}
        >
          {isDownloading ? (
            <>
              <CircularProgress color="inherit" size={16} thickness={4.5} />
              Downloading
            </>
          ) : (
            "Download"
          )}
        </Button>
      )}
    </DashboardLayout>
  );
};
