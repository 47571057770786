import axios from 'axios';

export const downloadFiles = async (files: any) => {
  await Promise.all(files.map((file: any) => {
    const encodedKey = encodeURIComponent(file.key)
    return axios.get(
      `${process.env.REACT_APP_SUNRISE_FILES_URL}/${encodedKey}`,
      {
        responseType: 'blob'
      }
    )
    .then((res) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([res.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        file.name,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link?.parentNode?.removeChild(link);
    })
    .catch(err => console.error(err));
  }))
  };