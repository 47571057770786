import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { gray } from "../../theme/colors";

export const EmptyRow = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={6}
        sx={{
          border: 0,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          minHeight="calc(100dvh - 16.5rem)"
          gap={8}
        >
          <i
            className="i-no-result"
            style={{
              width: "50px",
              minWidth: "50px",
              height: "50px",
              color: gray[400],
            }}
          />
          <Stack alignItems="center" justifyContent="center" gap={6}>
            <Typography
              variant="h6"
              fontWeight={600}
              sx={{
                color: "#000000",
              }}
            >
              No results found
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#000000",
                textAlign: "center",
              }}
            >
              Sorry, we couldn’t find what you’re looking for.
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
