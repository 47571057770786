import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { CustomTooltip } from "../shared/customTooltip";

export const AccordionList = ({ listData, forFaq }: any) => {
  return (
    <Stack gap={2}>
      {listData.map((item: any, key: any) => (
        <Accordion
          disableGutters
          elevation={0}
          square
          sx={{
            background: "transparent",
            "&::before": {
              display: "none",
            },
            "& .MuiButtonBase-root": {
              padding: 0,
              minHeight: "unset",
              width: "100%",
              justifyContent: "flex-start",
              gap: 4,
            },
            "& .MuiAccordionSummary-content": {
              flex: "unset",
              margin: 0,
              gap: 4,
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              color: "#B4BDC5",
            },
          }}
          key={`item${key}`}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1" color="textSecondary">
              {item.title}
            </Typography>
            {item.tooltip && (
              <CustomTooltip
                title={item.tooltip}
                maxWidth={{ xs: "163px" }}
                isDark
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                  }}
                />
              </CustomTooltip>
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0.5rem 0.5rem 0 1.5rem" }}>
            {forFaq ? (
              <Typography
                sx={{ ul: { margin: 0 } }}
                dangerouslySetInnerHTML={{ __html: item.details }}
              />
            ) : (
              <Typography>{item.details}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
};
