import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FullLogo from "../../assets/images/full_logo.svg";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { VerificationProgress } from "./verificationProgress";

export const VerificationLayout = ({
  children,
  mobileNoGap,
  currentStep,
  handleBack,
  withBackButton,
}: any) => {
  return (
    <>
      <Box
        component="header"
        width="100%"
        position="fixed"
        top={0}
        paddingTop={"2rem"}
        flexDirection={"column"}
        display={{ xs: "none", sm: "flex" }}
        borderBottom="1px solid #EEEEEE"
        gap={4.2}
        sx={{
          background: "#FFFFFF",
          zIndex: 2,
        }}
      >
        <Box padding={"0 2.5rem"}>
          <img
            src={FullLogo}
            alt="full logo"
            loading="lazy"
            style={{ height: "32px" }}
          />
        </Box>
        <VerificationProgress currentStep={currentStep} />
      </Box>
      <Stack
        paddingTop={{
          xs: 0,
          sm: 35,
        }}
        paddingBottom={{
          xs: 0,
          sm: 14,
        }}
        alignItems="center"
        gap={{
          xs: mobileNoGap ? 0 : 6,
          sm: 12,
        }}
        boxSizing="border-box"
        minHeight="100dvh"
      >
        {withBackButton && (
          <IconButton
            onClick={() => handleBack()}
            sx={{
              fontSize: "2.5rem",
              position: "absolute",
              top: "7rem",
              left: "2.5rem",
              padding: 0,
              display: {
                xs: "none",
                sm: "inline-flex",
              },
            }}
          >
            <KeyboardArrowLeftIcon fontSize="inherit" />
          </IconButton>
        )}

        {children}
      </Stack>
    </>
  );
};
