import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import ErrorIcon from '@mui/icons-material/Error';

export const ListIcon = ({ checked, errorInfo, errorDanger } :any) => {
    if(errorInfo) {
       return <ErrorIcon sx={{color: "#2660DF"}} />
    }

    if(errorDanger) {
       return  <WatchLaterRoundedIcon sx={{color: "#D92D20"}} />
    }

    return checked ? <CheckCircleIcon sx={{color: "#B4BDC5"}} /> : <RadioButtonUncheckedIcon sx={{color: "#B4BDC5"}} />
}