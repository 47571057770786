import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SolarWoman from "../../assets/images/solar_woman.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch } from "../../store/hook";
import { setMyProfileProps } from "../../store/modules/meSlice";

const dialogPaperStyle = {
  display: { lg: "flex", xs: "block" },
  flexDirection: "row",
  borderRadius: 0,
  width: "845px",
  maxWidth: { lg: "unset", xs: "100%" },
  height: { lg: "430px", xs: "auto" },
};

export const OfferDialog = ({ open }: any) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setMyProfileProps({showOffer: false}))
  }

  const handleSubmit = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSeyTO4Lo8ymhhkGEilyKqICYmlK1zC6ZDN6IufdBnoa6fRzWw/viewform?_hsenc=p2ANqtz-8RWU7WNgRJdztBt442wHmfCHRov_Ld62sD_flZ8fVxfkK5FmQ0hTth1goDmTnqNqIRx1Ek", '_blank')?.focus()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: dialogPaperStyle }}
    >
      <Stack
        sx={{
          flex: "auto",
          alignItems: "center",
          padding: "20px 5px",
          textAlign: "center",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "23.34px",
            fontWeight: 700,
            display: { lg: "block", xs: "none" },
          }}
        >
          HOST A SOLAR BLOCK PARTY
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "40px", xs: "14px" },
            lineHeight: { lg: "46.68px", xs: "16.34px" },
            fontWeight: 700,
            maxWidth: "472px",
          }}
        >
          Earn $1,500 for hosting a block party
        </Typography>
        <Typography sx={{ display: { lg: "block", xs: "none" } }}>
          We’ll create an Evite invitation and flyer that you can use to promote the event, bring the snacks, drinks, and a tent.
          As long as 10 homeowners attend your event, you’ll receive $1,500!
        </Typography>
        <Typography sx={{ display: { lg: "none", xs: "block" } }}>
          We’ll help promote the event, bring the snacks, drinks, and a tent. As
          long as 10 homeowners attend your event, you’ll receive $1,500.
        </Typography>
        <Button
          LinkComponent="a"
          variant="contained"
          sx={{
            marginTop: "auto",
            borderRadius: "100px",
            width: { lg: "332px", xs: "166px" },
          }}
          onClick={handleSubmit}
          // target="_blank"
          // href="https://docs.google.com/forms/d/e/1FAIpQLSeyTO4Lo8ymhhkGEilyKqICYmlK1zC6ZDN6IufdBnoa6fRzWw/viewform?_hsenc=p2ANqtz-8RWU7WNgRJdztBt442wHmfCHRov_Ld62sD_flZ8fVxfkK5FmQ0hTth1goDmTnqNqIRx1Ek"
        >
          Sign me up!
        </Button>
        <Typography
          sx={{
            fontSize: "10px",
            lineHeight: "11.67px",
            maxWidth: "400px",
            display: { lg: "block", xs: "none" },
          }}
        >
          Clicking Sign me up will direct you to fill out a Google Form
          providing your information and agreeing to be contacted by a Freedom
          Solar representative
        </Typography>
      </Stack>
      <Box
        component="img"
        src={SolarWoman}
        alt="solar women"
        loading="lazy"
        sx={{ display: { lg: "block", xs: "none" } }}
      />

      <IconButton
        color="inherit"
        size="small"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: { lg: "5px", xs: 0 },
          right: { lg: "5px", xs: 0 },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Dialog>
  );
};
