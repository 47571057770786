import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { gray, primary } from "../../theme/colors";
import { CustomNavLink } from "../shared/customNavLink";
import { useAppSelector } from "../../store/hook";
import { getCurrentProjectStage } from "../../components/constant/navigation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import ErrorIcon from "@mui/icons-material/Error";

const defaultStages = [
  {
    key: "projectKickoff",
    icon: "i-project-kickoff",
    heading: "Project Kickoff",
    description:
      "We agree on the estimated cost and total production of your new solar energy system, and you upload key docs.",
    to: "project",
    exact: true,
    estimatedDate: "",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "siteAssessment",
    icon: "i-site-assessment",
    heading: "Site Assessment",
    estimatedDate: "",
    description:
      "We use our handy drone to analyze your property's solar energy production potential.",
    to: "project/site-assessment",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "systemDesign",
    icon: "i-system-design",
    heading: "System Design",
    estimatedDate: "",
    description:
      "We use our handy drone to analyze your property's solar energy production potential.",
    to: "project/system-design",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "applicationsAndPermits",
    icon: "i-applications-permits",
    heading: "Applications & Permits",
    estimatedDate: "",
    description:
      "We submit applications to your HOA, utility, and local government to confirm that your solar system will be fully compliant, safe, and reliable.",
    to: "project/applications-permits",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "onSiteInstallation",
    icon: "i-onsite-installation",
    heading: "On-Site Installation",
    estimatedDate: "",
    description:
      "We schedule a visit to your property to install your new system. This might take a few trips, depending on the design of your unique system.",
    to: "project/on-site-installation",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "thirdPartyInspections",
    icon: "i-thirdparty-inspections",
    heading: "Third-Party Inspections",
    estimatedDate: "",
    description:
      "We schedule any on-site inspections required by your local government or utility provider to confirm that your newly installed solar system meets all regulations for safety and reliability.",
    to: "project/third-party-inspections",
    complete: false,
    completedDate: "",
    disabled: false,
  },
  {
    key: "systemLaunch",
    icon: "i-system-launch",
    heading: "System Launch",
    estimatedDate: "Month YYYY",
    description:
      "After your third-party inspections are complete, we confirm that you're ready to energize your new system so that you can start generating clean, low-cost power for your property.",
    to: "project/system-launch",
    complete: false,
    completedDate: "",
    disabled: false,
  },
];

const navStyle = {
  padding: "16px",
  width: "305px",
  minHeight: "92px",
  maxWidth: "100%",
  boxSizing: "border-box",
  border: "1px solid #FFFFFF",
  display: "flex",
  alignItems: "center",
  "& i": {
    color: gray[500],
  },
  "&:hover": {
    background: "#FFF6F0",
    borderColor: "#FFF6F0",
  },
  "&.active": {
    background: "#FFF6F0",
    borderColor: primary[400],
  },
  "&.active i, &:hover i": {
    color: primary[400],
  },
  "&:hover h6, &.active h6, &.current h6": {
    fontWeight: 600,
  },
  "&.disabled:hover i": {
    color: gray[500],
  },
  "&.disabled:hover h6": {
    fontWeight: 400,
  },
  "&.disabled:hover": {
    background: "#FFFFFF",
    borderColor: "#FFFFFF",
  },
};

export const Stages = ({ forMobile }: any) => {
  const [stages, setStages]: any = useState(defaultStages);
  const { tracker, fspProperties, id, banners } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { to: currentProjectStageTo, key: currentProjectStageKey } =
    getCurrentProjectStage({ id, tracker });
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (tracker) {
      const updateStages = [];
      for (let i = 0; i < stages.length; i++) {
        const stage = stages[i];
        if (_.has(tracker, stage.key)) {
          stage.complete = _.get(tracker, [stage.key, "complete"], false);
          stage.disabled = _.get(tracker, [stage.key, "disabled"], false);

          if (stage.key === "systemLaunch") {
            if (stage.complete) {
              const completedDate = _.get(
                tracker,
                `systemLaunch.task.completed_at`,
                null
              );
              stage.completedDate = completedDate
                ? moment.utc(completedDate).local().format("MM/DD/YYYY")
                : null;
            } else {
              // Formatted as 1710576208066
              const estimatedDate = _.get(
                fspProperties,
                "projected_commissioning_timestamp",
                null
              );
              stage.estimatedDate = estimatedDate
                ? moment(estimatedDate).format("MMMM YYYY")
                : null;
            }
          }

          stage.isCurrentStage =
            i > 0 &&
            _.get(tracker, [stages[i - 1].key, "complete"], false) &&
            !stage.complete;

          updateStages.push(stage);
        }
      }

      setStages(updateStages);
    }
  }, []);

  const renderIcon = (stage: any) => {
    const {
      key: stageKey,
      icon: baseIcon,
      complete: isComplete,
      to: stageTo,
    } = stage;

    const extendedProjectBanner = _.find(
      banners,
      (b) => b.type === "extendedProject"
    );
    const punchListBanner = _.find(banners, (b) => b.type === "punchList");

    const isCurrentPage = pathname === `/${id}/${stageTo}`;
    const isCurrentProjectStage = currentProjectStageKey === stageKey;

    if (extendedProjectBanner && !isCurrentPage && isCurrentProjectStage) {
      return (
        <WatchLaterRoundedIcon fontSize="medium" sx={{ color: "#D92D20" }} />
      );
    }

    if (punchListBanner && !isCurrentPage && isCurrentProjectStage) {
      return <ErrorIcon fontSize="medium" sx={{ color: "#2660DF" }} />;
    }

    if (!isCurrentPage && isComplete) {
      return <CheckCircleIcon fontSize="medium" color="primary" />;
    }

    if (!isCurrentPage && isCurrentProjectStage) {
      return <LocationOnIcon fontSize="medium" color="primary" />;
    }

    return (
      <i
        className={baseIcon}
        style={{
          width: "1.5rem",
          minWidth: "1.5rem",
          height: "1.5rem",
        }}
      />
    );
  };

  const renderContent = (stage: any) => {
    return (
      <Stack
        direction={"row"}
        alignItems={{ xs: "flex-start", md: "center" }}
        gap={4}
      >
        {renderIcon(stage)}
        <Stack gap={{ xs: 2, md: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: stage.disabled ? gray[500] : gray[800],
              fontWeight: "400",
            }}
          >
            {stage.heading}
          </Typography>

          {(stage.estimatedDate || stage.completedDate) && (
            <Typography variant="body2" sx={{ color: gray[500] }}>
              {!stage.complete
                ? ` Estimated: ${stage.estimatedDate}`
                : `Completed: ${stage.completedDate}`}
            </Typography>
          )}

          {!stage.complete && (
            <Typography
              variant="body2"
              sx={{ color: gray[700] }}
              display={{ sm: "block", md: "none" }}
            >
              {stage.description}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack gap={4} sx={{ position: "sticky", top: "2.5rem" }}>
      {stages.map((stage: any) => {
        const isCurrentProjectStage = currentProjectStageKey === stage.key;

        return stage.disabled ? (
          <Paper
            elevation={3}
            className="disabled"
            sx={navStyle}
            key={stage.key}
          >
            {renderContent(stage)}
          </Paper>
        ) : (
          <Paper
            component={CustomNavLink}
            elevation={3}
            sx={navStyle}
            key={stage.key}
            to={
              stage.to === "project" && forMobile
                ? `/${id}/${stage.to}/kickoff`
                : `/${id}/${stage.to}`
            }
            activeClassName={"active"}
            className={isCurrentProjectStage ? "current" : ""}
            forceActive={forMobile && stage.isCurrentStage}
            exact={stage.exact}
          >
            {renderContent(stage)}
          </Paper>
        );
      })}
    </Stack>
  );
};
