import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";

import { Feature } from "./feature";

export const Features = () => {
  return (
    <Box width="50%" padding={6} display={{ xs: "none", lg: "block" }}>
      <Stack
        width="100%"
        height="961px"
        bgcolor="secondary.main"
        borderRadius="8px"
        alignItems="center"
        justifyContent="center"
      >
        <Stack width="500px" gap={22}>
          <Feature
            icon={
              <ConstructionOutlinedIcon
                sx={{ fontSize: "48px" }}
                color="primary"
              />
            }
            header={"Get step-by-step project updates"}
            description={
              <>
                From permitting to final inspection, your solar journey is
                documented in detail in your customer portal.
                <br /> <br />
                Track the progress of your solar installation every step of the
                way with real-time status updates.
              </>
            }
          />
          <Feature
            icon={
              <i
                className="i-appointments"
                style={{
                  width: "3rem",
                  minWidth: "3rem",
                  height: "3rem",
                }}
              />
            }
            header={"View and manage upcoming appointments"}
            description={`Get an instant overview of scheduled site visits, construction dates, and inspections in your portal calendar.`}
          />
          <Feature
            icon={
              <i
                className="i-documents"
                style={{
                  width: "3rem",
                  minWidth: "3rem",
                  height: "3rem",
                }}
              />
            }
            header={"Access your documents all in one place"}
            description={`Your agreements, invoices, permits, and system information can be accessed 24/7 in your secure document vault.`}
          />
          <Feature
            icon={
              <HeadsetMicRoundedIcon
                sx={{ fontSize: "48px" }}
                color="primary"
              />
            }
            header={"Customer support at your fingertips"}
            description={`Your personal portal gives you access to the key contacts for assistance during your project.`}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
