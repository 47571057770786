import _ from "lodash";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { DashboardLayout } from "../../components/dashboard/dashboardLayout";
import Paper from "@mui/material/Paper";
import { EnhancedTable } from "../../components/documents/table";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import * as api from "../../api";
import { formatDocument } from "../../components/constant/document";
import { ErrorState } from "../../components/shared/errorState";
import { useAppSelector } from "../../store/hook";

export const Documents = () => {
  const { systemDetails, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { portalId } = useParams();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        const res = await api.documents.get(portalId as string);
        let docs = res.data;

        if (
          _.get(systemDetails, "monitoringDevice", null) ===
          "Enphase IQ Gateway"
        ) {
          // Remove register warranty doc if enphase monitoring device
          docs = _.filter(docs, (d) => d.doc !== "registerWarranty");
        }

        if (isBatteryOnly) {
          // Remove solar installation photos if is battery only
          docs = _.filter(docs, (d) => d.doc !== "solarInstallationPhotos");
        }

        setDocuments(formatDocument(docs));
      } catch (error) {
        console.error(error);
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchDocuments();
  }, []);

  const headers = [
    {
      id: "label",
      label: "Document",
    },
    {
      id: "trackerStage",
      label: "Stage",
    },
    {
      id: "added",
      label: "Added",
    },
  ];

  return (
    <DashboardLayout>
      <Paper
        sx={{
          borderRadius: {
            xs: 0,
            sm: "8px",
          },
          boxShadow: {
            xs: 0,
            sm: 3,
          },
          minHeight: "calc(100dvh - 5rem)",
        }}
      >
        {isError ? (
          <ErrorState />
        ) : isLoading ? (
          <Stack
            sx={{
              minHeight: "100dvh",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={60} />
          </Stack>
        ) : (
          <EnhancedTable data={documents} headers={headers} />
        )}
      </Paper>
    </DashboardLayout>
  );
};
