import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import LinkElment from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import CircularProgress from "@mui/material/CircularProgress";
import { StageTopContent } from "../../components/project/stageTopContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { ListLayout } from "../../components/project/listLayout";
import { CustomDialog } from "../../components/shared/customDialog";
import { useAppSelector } from "../../store/hook";
import * as api from "../../api";
import { ListIcon } from "../../components/project/listIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const SiteAssessment = () => {
  const { id, tracker, fields, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(
    false
  ) as any;
  const [todo, setTodo] = useState({}) as any;
  const [complete, setComplete] = useState(false) as any;
  const [steps, setSteps] = useState([]) as any;
  const [siteAssessmentDate, setSiteAssessmentDate] = useState(null) as any;
  const [allowRequestReschedule, setAllowRequestReschedule] = useState(
    false
  ) as any;
  const [reschedulePending, setReschedulePending] = useState(false) as any;
  const [assessmentIsToday, setAssessmentIsToday] = useState(false) as any;
  const [loading, setLoading] = useState(false) as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("siteAssessment");

  useEffect(() => {
    if (tracker && tracker.siteAssessment) {
      setComplete(_.get(tracker.siteAssessment, "complete", false));
      // setCustomData();

      const todo: any = {};
      const stageSteps: any = _.sortBy(
        _.get(tracker.siteAssessment, "steps", []),
        "stepId"
      );
      setSteps(stageSteps);

      // const confirmSiteAssessment = _.find(
      //   stageSteps,
      //   (s) => s.textKey === "confirm site assessment"
      // );
      // if (
      //   confirmSiteAssessment &&
      //   confirmSiteAssessment.valid &&
      //   !confirmSiteAssessment.completed
      // )
      //   todo.confirmSiteAssessment = true;

      // const completeSiteAssessment = _.find(
      //   stageSteps,
      //   (s) => s.textKey === "complete site assessment"
      // );
      // if (
      //   !todo.confirmSiteAssessment &&
      //   completeSiteAssessment &&
      //   completeSiteAssessment.valid &&
      //   !completeSiteAssessment.completed
      // )
      //   todo.confirmSiteAssessment = true;

      // setTodo(todo);
    }
  }, []);

  const setCustomData = () => {
    let assessmentIsToday = false;

    const assessmentDate = _.get(fields, "site_assessment_time", null);
    const requestedValue = _.get(
      fields,
      "site_assessment_reschedule_requested",
      null
    );
    const rescheduleConfirmValue = _.get(
      fields,
      "site_assessment_reschedule_confirmed",
      null
    );

    if (assessmentDate) {
      // String should have 10/16/2023 at 01:30 PM format
      const date = moment(assessmentDate, "MM/DD/YYYY at LT").local();
      const now = moment().local();

      if (date.isValid()) {
        assessmentIsToday = date.isSame(now, "day") && now.isBefore(date);
        setSiteAssessmentDate({
          text: date.clone().format("dddd, MMMM D"),
          time: date.clone().format("(LT)"),
        });

        const allowedRescheduleDate = date
          .clone()
          .subtract(1, "day")
          .set({ hour: 13, minute: 0, second: 0, millisecond: 0 });
        setAllowRequestReschedule(
          requestedValue !== "Yes" && now.isBefore(allowedRescheduleDate)
        );
      }
    }

    setAssessmentIsToday(assessmentIsToday);
    setReschedulePending(
      !assessmentIsToday &&
        requestedValue === "Yes" &&
        rescheduleConfirmValue !== "Yes"
    );
  };

  const requestReschedule = async () => {
    setLoading(true);
    const rescheduleActionPayload = _.get(
      tracker,
      "siteAssessment.portalActions.rescheduleSiteAssessment"
    );
    await api.portal
      .update(id, { fields: [rescheduleActionPayload] })
      .catch((err) => console.error(err));
    setLoading(false);
    setOpenRescheduleDialog(false);
    setReschedulePending(true);
  };

  const stageCopy: any = {
    ...extendedProjectsCopy,
    "confirm site assessment": "Confirm site assessment",
    "complete site assessment": "Complete site assessment",
  };

  const keySteps = _.map(steps, (step, i) => ({
    valid: _.get(step, "valid", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    text: _.get(stageCopy, [step.textKey], "--"),
  }));

  return (
    <>
      <StageTopContent
        stage={tracker.siteAssessment}
        headerText="Site Assessment"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.siteAssessment?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : "Usually takes 2-3 weeks"
        }
        subHeaderText={
          isBatteryOnly
            ? "Our team will analyze your current electrical system, and determine the best location for your new battery."
            : "We use our drone to analyze your property's solar energy production potential."
        }
      >
        {!complete && todo.confirmSiteAssessment && !siteAssessmentDate && (
          <Requirements>
            <Requirement
              headText="Confirm site assessment"
              subText={
                <Typography variant="body1" color="textSecondary">
                  You’ll receive an email with your proposed date and time.
                  Contact{" "}
                  <LinkElment
                    color="primary"
                    href="mailto:scheduling@freedomsolarpower.com"
                  >
                    scheduling
                  </LinkElment>{" "}
                  if you do not see this email within a few days.
                </Typography>
              }
            />
          </Requirements>
        )}

        {!complete && todo.confirmSiteAssessment && siteAssessmentDate && (
          <Requirements>
            <Requirement
              headText={
                <>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    fontWeight={600}
                  >
                    {"Site assessment"}{" "}
                    {assessmentIsToday && (
                      <Typography
                        component="span"
                        sx={{
                          color: "#F98A3C",
                          marginLeft: "10px",
                        }}
                      >
                        Happening Today!
                      </Typography>
                    )}
                  </Typography>
                </>
              }
              subText={
                siteAssessmentDate ? (
                  <Typography variant="body1" color="textSecondary">
                    {siteAssessmentDate.text}{" "}
                    <Typography component="span" color="secondary.light">
                      {siteAssessmentDate.time}
                    </Typography>
                  </Typography>
                ) : (
                  ""
                )
              }
              cta={
                <Button
                  disabled={!allowRequestReschedule || reschedulePending}
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setOpenRescheduleDialog(true);
                  }}
                >
                  Request reschedule
                </Button>
              }
              note={
                <>
                  {assessmentIsToday && (
                    <>
                      Window to reschedule is past. Contact{" "}
                      <LinkElment
                        component={Link}
                        to="mailto:customerservice@freedomsolarpower.com"
                      >
                        Customer Care
                      </LinkElment>{" "}
                      for assistance.
                    </>
                  )}

                  {reschedulePending && (
                    <>
                      Once you call{" "}
                      <LinkElment
                        color="primary"
                        href="tel:(800) 504-2337"
                        style={{
                          fontSize: "inherit",
                        }}
                      >
                        (800) 504-2337
                      </LinkElment>{" "}
                      or{" "}
                      <LinkElment
                        color="primary"
                        href="mailto:scheduling@freedomsolarpower.com"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        email
                      </LinkElment>
                      , our team will work to reschedule your appointment.
                    </>
                  )}
                </>
              }
            />
          </Requirements>
        )}

        {complete && (
          <Requirements isComplete>
            <Requirement
              headText="Site assessment"
              subText={
                <Typography variant="body1" color="textSecondary">
                  {`Completed ${moment
                    .utc(steps[1]?.task?.completed_at)
                    .local()
                    .format("dddd, MMMM D")}`}
                </Typography>
              }
            />
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Step"
          listType="icon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={["You", "Freedom Solar Site Assessor"]}
        />
        <ListLayout
          width="100%"
          title="On-Site Visit Reminders"
          listType="simple"
          listData={[
            "Average time on-site: 30 minutes-1 hour",
            <>
              If your property has a gate code, add it to your{" "}
              <LinkElment
                component={Link}
                to={`/${id}/account/property-details`}
              >
                Account
              </LinkElment>
            </>,
            "Clear your driveway to give our site assessor space to park",
            isBatteryOnly
              ? "We'll need access to your garage and electrical panel. You can help speed things up by removing obstructions before we arrive"
              : "We'll need access to your garage, attic, and electrical panel. You can help speed things up by removing obstructions before we arrive",
            "Put away any large pets—we love dogs but we want to keep your pets and our site assessor safe!",
          ]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
      <CustomDialog
        open={openRescheduleDialog}
        handleClose={() => setOpenRescheduleDialog(false)}
        title="Request an appointment reschedule?"
        content={
          <>
            <Typography
              variant="subtitle2"
              textAlign="center"
              color="textSecondary"
            >
              Please contact our scheduling team to select a new time.
            </Typography>
            <Stack
              justifyContent="center"
              gap={4}
              sx={{
                width: { xs: "100%", sm: "max-content" },
                margin: "0 auto",
              }}
            >
              <Stack direction="row" gap={4}>
                <LocalPhoneIcon color="primary" />
                <LinkElment color="primary" href="tel:(800) 504-2337">
                  (800) 504-2337
                </LinkElment>
              </Stack>

              <Stack direction="row" gap={4}>
                <EmailIcon color="primary" />
                <LinkElment
                  color="primary"
                  href="mailto:scheduling@freedomsolarpower.com"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  scheduling@freedomsolarpower.com
                </LinkElment>
              </Stack>
            </Stack>
          </>
        }
        lCta={
          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={() => setOpenRescheduleDialog(false)}
          >
            <Typography color="textPrimary">Cancel</Typography>
          </Button>
        }
        rCta={
          <Button
            variant="contained"
            size="small"
            disabled={loading}
            onClick={requestReschedule}
          >
            {loading && (
              <CircularProgress color="inherit" size={16} thickness={4.5} />
            )}
            Done
          </Button>
        }
      />
    </>
  );
};
