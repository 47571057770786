import { API } from "aws-amplify";

interface IUpdatePortalPayload {
  email?: string;
  phoneNumber?: string;
  addressConfirmed?: boolean;
  notificationPrefs?: any;
  secondaryContact?: any;
  hoaDetails?: any;
  fields?: IUpdatePortalFieldPayload[];
  onboardingStep?: string;
}

interface IUpdatePortalFieldPayload {
  id: string;
  text: string;
}

export const get = (id: string) => API.get("SECURED_API", `/portal/${id}`, {});
export const getVelocity = (id: string) =>
  API.get("SECURED_API", `/portal/${id}/velocity`, {});
export const update = (id: string, body: IUpdatePortalPayload) =>
  API.patch("SECURED_API", `/portal/${id}`, { body });
