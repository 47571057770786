import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export const Tooltips = () => {
  return (
    <Box component={"section"} paddingY={10} paddingX={10}>
      <Stack direction="row" spacing={5} flexWrap={"wrap"}>
        <Tooltip title="This is a tool tip top." placement="top-start" arrow>
          <Button>With Tooltip Top</Button>
        </Tooltip>
        <Tooltip title="This is a tool tip left." placement="left-end" arrow>
          <Button>With Tooltip Left</Button>
        </Tooltip>
        <Tooltip
          title="This is a tool tip bottom."
          placement="bottom-end"
          arrow
        >
          <Button>With Tooltip Bottom</Button>
        </Tooltip>
        <Tooltip title="This is a tool tip right." placement="right-end" arrow>
          <Button>With Tooltip Right</Button>
        </Tooltip>
      </Stack>
    </Box>
  );
};
