import Stack from "@mui/material/Stack";
import { DashboardNav } from "./dashboardNav";
import { gray } from "../../theme/colors";

export const DashboardLayout = ({ children, customPathData = null }: any) => {
  return (
    <Stack direction={{ xs: "column", lg: "row" }} minHeight="100dvh">
      <DashboardNav customPathData={customPathData} />
      <Stack
        sx={{
          width: "100%",
          minHeight: { lg: "100dvh" },
          padding: { xs: 0, sm: 10 },
          background: { sm: gray[25] },
          flex: {
            xs: "auto",
            lg: "unset",
          },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
