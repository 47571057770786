import styled from "@emotion/styled";
import { MaterialDesignContent } from "notistack";
import { gray, error, warning, success } from "./colors";

const customSnackbar: any = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: gray[800],
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: warning[600],
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: error[600],
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: success[600],
  },
}));

export const customSnackbarComponents: any = {
  default: customSnackbar,
  warning: customSnackbar,
  error: customSnackbar,
  success: customSnackbar,
};
