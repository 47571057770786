import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

export const TableTopFilter = ({
  docCount,
  selectedCount,
  search,
  setSearch,
  downloadIsVisible,
  isDownloading,
  handleDownload,
}: any) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="1rem 1.5rem"
      gap={4}
      display={{
        xs: "none",
        sm: "flex",
      }}
    >
      <Typography variant="body1" color="text.light">
        {selectedCount > 0
          ? `${selectedCount}/${docCount} selected`
          : `${docCount} documents`}
      </Typography>
      <Stack direction="row" gap={4}>
        <Box width={{ xs: "450px" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label=""
              type={"text"}
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    sx={{
                      visibility: search !== "" ? "visible" : "hidden",
                    }}
                    aria-label="clear"
                    onClick={() => setSearch("")}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              placeholder=" "
            />
          </FormControl>
        </Box>
        {downloadIsVisible && (
          <Button
            variant="contained"
            size="large"
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <>
                <CircularProgress color="inherit" size={16} thickness={4.5} />
                Downloading
              </>
            ) : (
              "Download"
            )}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
