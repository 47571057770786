import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "lodash";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import HouseIcon from "@mui/icons-material/HolidayVillage";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/OpenInBrowser";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../components/shared/phoneInput";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";

import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultValue = {
  name: "",
  email: "",
  phoneNumber: "",
  portalWebsite: "",
  portalUsername: "",
  portalPassword: "",
};

const errorDefault = {
  email: "",
  phoneNumber: "",
};

export const HoaDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, hoaDetails } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(errorDefault);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (hoaDetails) {
      setFormData({
        name: _.get(hoaDetails, "name", "") || "",
        email: _.get(hoaDetails, "email", "") || "",
        phoneNumber: _.get(hoaDetails, "phoneNumber", "") || "",
        portalWebsite: _.get(hoaDetails, "portalWebsite", "") || "",
        portalUsername: _.get(hoaDetails, "portalUsername", "") || "",
        portalPassword: _.get(hoaDetails, "portalPassword", "") || "",
      });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const nextPage = "/onboarding/success";
    if (_.isEqual(formData, _.omit(hoaDetails, ["hoaExist"]))) {
      handleSkip(false);
      return;
    }

    setIsLoading(true);
    try {
      const payload: any = { hoaDetails: formData, onboardingStep: nextPage };
      await api.portal.update(id, payload);

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("accCreationPage8", {
          hoa_name_added: formData.name,
          hoa_email_added: formData.email,
          hoa_phone_added: formData.phoneNumber,
          hoa_website_added: formData.portalWebsite,
          hoa_username_added: formData.portalUsername,
          hoa_password_added: formData.portalPassword,
          page8_continue_clicked: true,
          page8_no_hoa_clicked: false,
          page8_skip_clicked: false,
        }),
      });

      dispatch(setPortalProps(payload));
      navigate(nextPage);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Failed to update hoa details. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  const handleSkip = (noHOA: boolean) => {
    const onboardingUpdate = { onboardingStep: "/onboarding/success" };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    TagManager.dataLayer({
      dataLayer: GenerateGTMArgs("accCreationPage8", {
        hoa_name_added: formData.name,
        hoa_email_added: formData.email,
        hoa_phone_added: formData.phoneNumber,
        hoa_website_added: formData.portalWebsite,
        hoa_username_added: formData.portalUsername,
        hoa_password_added: formData.portalPassword,
        page8_continue_clicked: false,
        page8_no_hoa_clicked: noHOA,
        page8_skip_clicked: noHOA ? false : true,
      }),
    });

    dispatch(setPortalProps(onboardingUpdate));
    navigate("/onboarding/success");
  };

  useEffect(() => {
    const { email, phoneNumber } = formData;

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phone = `+1${phoneNumber}`;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPhone = "",
        withError = false;

      if (!emailPattern.test(email) && email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (phoneNumber !== "" && !matchIsValidTel(phone)) {
        errPhone = "Invalid phone number";
        withError = true;
      }

      if (phoneNumber !== "" && matchIsValidTel(phone)) {
        errPhone = "";
        withError = false;
      }

      setErrors({
        email: errEmail,
        phoneNumber: errPhone,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  return (
    <VerificationLayout
      currentStep={6}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/wifi-details")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/wifi-details")}
        headerText="Add your HOA details"
        headerVariant="h5"
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={6}
      />

      <MiddleContainer>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="HOA name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HouseIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="HOA email"
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
            error={errors.email !== "" ? true : false}
            helperText={errors.email}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            value={formData.phoneNumber}
            setValue={(v: any) => setFormData({ ...formData, phoneNumber: v })}
            error={errors.phoneNumber !== ""}
            setError={(e: any) => {
              setErrors({
                ...errors,
                phoneNumber: e ? "Invalid phone number" : "",
              });
            }}
            disabled={isLoading}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="HOA portal website"
            type="text"
            name="portalWebsite"
            value={formData.portalWebsite}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WebsiteIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="HOA portal username"
            type="text"
            name="portalUsername"
            value={formData.portalUsername}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
            autoComplete="off"
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="HOA portal password"
            type={showPassword ? "text" : "password"}
            name="portalPassword"
            value={formData.portalPassword}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
            autoComplete="off"
          />
        </FormControl>
        <Box
          sx={{
            padding: "1.5rem",
            width: "295px",
            border: "1px solid #FFEDE0",
            background: "#FFF6F0",
            top: 0,
            left: "calc(100% + 3rem)",
            borderRadius: "8px",
          }}
          position={{
            xs: "static",
            lg: "absolute",
          }}
          marginX="auto"
        >
          <Stack gap={4}>
            <Typography
              variant="body2"
              textAlign="center"
              color={"primary"}
              sx={{
                fontWeight: `700 !important`,
              }}
            >
              Why are we asking for your HOA details?
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              color={"textPrimary"}
            >
              Providing your HOA details allows us to inform them about the
              upcoming project, submit paperwork on your behalf, and secure any
              necessary permissions.
            </Typography>
          </Stack>
        </Box>
      </MiddleContainer>

      <BottomCtaContainer>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => handleSubmit()}
          disabled={isDisabled || isLoading}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => handleSkip(true)}
        >
          I don’t belong to an HOA
        </Button>
        <Button variant="text" onClick={() => handleSkip(false)}>
          I’ll do this later
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
