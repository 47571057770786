import Box from "@mui/material/Box";

export const VerificationProgress = ({ currentStep, total = 7 }: any) => {
  const num = Math.ceil((currentStep/total) * 100);
  return (
    <Box
      sx={{
        width: `${num}%`,
        maxWidth: "100%",
        height: { xs: "4px", sm: "8px" },
        backgroundColor: "#F98A3C",
        borderTopRightRadius: {
          xs: num >= 100 ? "0px" : "4px",
          sm: num >= 100 ? "0px" : "8px",
        },
        borderBottomRightRadius: {
          xs: num >= 100 ? "0px" : "4px",
          sm: "0",
        },
      }}
    ></Box>
  );
};
