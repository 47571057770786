import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import LinkElment from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { StageTopContent } from "../../components/project/stageTopContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { ListLayout } from "../../components/project/listLayout";
import { useAppSelector } from "../../store/hook";
import { ListIcon } from "../../components/project/listIcon";
import { SubListIcon } from "../../components/project/subListIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

const majorPunchlist = {
  Anchor: {
    text: "Additional roof work",
    schedule: "Schedule additional roof work",
    complete: "Complete additional roof work",
  },
  Attic: {
    text: "Attic work",
    schedule: "Schedule attic work",
    complete: "Complete attic work",
  },
  Commissioning: {
    text: "Return visit to resolve commissioning issues",
    schedule: "Scheduling commissioning",
    complete: "Complete commissioning",
  },
  Damage: {
    text: "Property repair",
    schedule: "Schedule damage repair",
    complete: "Complete damage repair",
  },
  Documentation: {
    text: "Return visit for missing photos",
    schedule: "Schedule return visit",
    complete: "Complete return visit",
  },
  EMT: {
    text: "Additional electrical work",
    schedule: "Schedule electrical work",
    complete: "Complete electrical work",
  },
  Enclosures: {
    text: "Additional electrical work",
    schedule: "Schedule electrical work",
    complete: "Complete electrical work",
  },
  "Ground Mount": {
    text: "Additional ground mount work",
    schedule: "Schedule ground mount work",
    complete: "Complete ground mount work",
  },
  "Junction Box": {
    text: "Additional electrical work",
    schedule: "Schedule electrical work",
    complete: "Complete electrical work",
  },
  Labeling: {
    text: "Return visit for missing safety labels",
    schedule: "Schedule return visit",
    complete: "Complete return visit",
  },
  Modules: {
    text: "Module inspection",
    schedule: "Schedule module inspection",
    complete: "Complete module inspection",
  },
  Rail: {
    text: "Racking inspection",
    schedule: "Schedule racking inspection",
    complete: "Complete racking inspection",
  },
  "Roof Attachment": {
    text: "Additional roof work",
    schedule: "Schedule additional roof work",
    complete: "Complete additional roof work",
  },
  "Terminations/Breakers": {
    text: "Additional electrical work",
    schedule: "Schedule electrical work",
    complete: "Complete electrical work",
  },
};

export const OnSiteInstallation = () => {
  const { id, tracker, fields, velocity, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const [schedules, setSchedules] = useState([]) as any;
  const [completedSchedules, setCompletedSchedules] = useState([]) as any;
  const [complete, setComplete] = useState(false) as any;
  const [additionalPunchlist, setAdditionalPunchlist] = useState(null) as any;
  const [steps, setSteps] = useState([]) as any;
  const [electricalStage, setElectricalStage] = useState(null) as any;
  const [panelInstallationStage, setPanelInstallation] = useState(null) as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("onSiteInstallation");

  useEffect(() => {
    if (tracker && tracker.onSiteInstallation) {
      setComplete(_.get(tracker.onSiteInstallation, "complete", false));
      const stageSteps: any = _.sortBy(
        _.get(tracker.onSiteInstallation, "steps", []),
        "stepId"
      );

      const electricalStage = _.find(
        stageSteps,
        (s) => s.textKey === "electrical"
      );
      const panelInstallationStage = _.find(
        stageSteps,
        (s) => s.textKey === "panel installation"
      );

      setElectricalStage(electricalStage);
      setPanelInstallation(panelInstallationStage);
      setSteps(stageSteps);
      populateSchedules(stageSteps);
      populateCompletedSchedules(stageSteps);
      populateAdditionalPunchlist(stageSteps);
    }
  }, []);

  const getDate = (val: string) => {
    if (!val) return null;

    // String should have 10/16/2023 at 01:30 PM format
    const date = moment(val, "MM/DD/YYYY at LT").local();
    return date.isValid() ? date : null;
  };

  const getDateUnix = (val: number) => {
    if (!val) return null;

    // String should have 10/16/2023 at 01:30 PM format
    const date = moment.utc(val).local();
    return date.isValid() ? date : null;
  };

  const populateSchedules = (steps: any) => {
    const shutdownElectricianTime = getDate(
      _.get(fields, "shutdown_electrician_time", null)
    );
    const electricianTime = getDate(_.get(fields, "electrician_time", null));
    const installationTime = getDate(_.get(fields, "installation_time", null));

    const dates = [];

    const electricalStage = _.find(steps, (s) => s.textKey === "electrical");
    const panelInstallationStage = _.find(
      steps,
      (s) => s.textKey === "panel installation"
    );

    if (
      !_.get(electricalStage, "completed", false) &&
      _.get(electricalStage, "steps.0.completed", false) &&
      _.get(electricalStage, "steps.0.valid", false) &&
      shutdownElectricianTime
    ) {
      dates.push({
        title: "Utility shutdown",
        text: shutdownElectricianTime.clone().format("dddd, MMMM D"),
        time: shutdownElectricianTime.clone().format("(LT)"),
        timestamp: shutdownElectricianTime.clone().valueOf(),
      });
    }

    if (
      !_.get(electricalStage, "completed", false) &&
      _.get(electricalStage, "steps.1.completed", false) &&
      _.get(electricalStage, "steps.1.valid", false) &&
      electricianTime
    ) {
      dates.push({
        title: "Electrical work",
        text: electricianTime.clone().format("dddd, MMMM D"),
        time: electricianTime.clone().format("(LT)"),
        timestamp: electricianTime.clone().valueOf(),
      });
    }

    if (
      !_.get(panelInstallationStage, "completed", false) &&
      _.get(panelInstallationStage, "steps.0.completed", false) &&
      _.get(panelInstallationStage, "steps.0.valid", false) &&
      installationTime
    ) {
      dates.push({
        title: "Panel installation",
        text: installationTime.clone().format("dddd, MMMM D"),
        time: installationTime.clone().format("(LT)"),
        timestamp: installationTime.clone().valueOf(),
      });
    }
    setSchedules(_.sortBy(dates, "timestamp"));
  };

  const populateCompletedSchedules = (steps: any) => {
    const electricalStage = _.find(steps, (s) => s.textKey === "electrical");
    const panelInstallationStage = _.find(
      steps,
      (s) => s.textKey === "panel installation"
    );

    const shutdownCompletedDate = getDateUnix(
      _.get(electricalStage, "steps.0.task.completed_at", null)
    );
    const electricianCompletedDate = getDateUnix(
      _.get(electricalStage, "steps.2.task.completed_at", null)
    );
    const installationCompletedDate = getDateUnix(
      _.get(panelInstallationStage, "steps.1.task.completed_at", null)
    );

    const dates = [];

    if (
      _.get(electricalStage, "steps.0.completed", false) &&
      shutdownCompletedDate
    ) {
      dates.push({
        title: "Utility shutdown",
        text: shutdownCompletedDate.clone().format("dddd, MMMM D"),
        timestamp: shutdownCompletedDate.clone().valueOf(),
      });
    }

    if (
      _.get(electricalStage, "steps.2.completed", false) &&
      electricianCompletedDate
    ) {
      dates.push({
        title: "Electrical work",
        text: electricianCompletedDate.clone().format("dddd, MMMM D"),
        timestamp: electricianCompletedDate.clone().valueOf(),
      });
    }

    if (
      _.get(panelInstallationStage, "steps.1.completed", false) &&
      installationCompletedDate
    ) {
      dates.push({
        title: "Panel installation",
        text: installationCompletedDate.clone().format("dddd, MMMM D"),
        timestamp: installationCompletedDate.clone().valueOf(),
      });
    }

    setCompletedSchedules(_.sortBy(dates, "timestamp"));
  };

  const populateAdditionalPunchlist = (steps: any): void => {
    const punchListTasks = _.filter(
      steps,
      (s) => s.textKey.includes("punchlist") && s.valid
    );
    const additionalPunchlistStage = _.find(
      punchListTasks,
      (s) => !s.completed
    );
    if (additionalPunchlistStage) {
      const scheduled = _.get(
        additionalPunchlistStage,
        "steps.0.completed",
        false
      );
      const completed = _.get(
        additionalPunchlistStage,
        "steps.1.completed",
        false
      );

      const scheduleTime = getDate(_.get(fields, "workmanship_time", null));

      const details = {
        keyStep: additionalPunchlistStage.textKey,

        scheduleLabel:
          punchListTasks.length > 1
            ? "multiple major categories punchlist"
            : _.get(additionalPunchlistStage, "textKey", false),
        scheduled,
        scheduleDate:
          scheduled && scheduleTime
            ? scheduleTime.clone().format("dddd, MMMM D")
            : null,
        scheduleTime:
          scheduled && scheduleTime
            ? scheduleTime.clone().format("(LT)")
            : null,

        completeLabel: _.get(
          additionalPunchlistStage,
          "steps.1.textKey",
          false
        ),
        completed,
      };
      setAdditionalPunchlist(details);
    }
  };

  const stageCopy: any = {
    ...extendedProjectsCopy,
    electrical: isBatteryOnly ? "Battery installation" : "Electrical",
    "schedule utility shutdown": isBatteryOnly
      ? "Schedule utility shutdown"
      : "Schedule utility shutdown",
    "schedule electrical work": isBatteryOnly
      ? "Schedule battery installation"
      : "Schedule electrical work",
    "complete electrical work": isBatteryOnly
      ? "Complete battery installation"
      : "Complete electrical work",
    "panel installation": "Panel installation",
    "schedule panel installation": "Schedule panel installation",
    "complete panel installation": "Complete panel installation",
    "complete quality assurance check": "Complete quality assurance check",
    "multiple major categories punchlist": "Additional on-site work",
  };

  _.each(majorPunchlist, (v, k) => {
    stageCopy[`${k.toLowerCase()} punchlist`] = v.text;
    stageCopy[`schedule ${k.toLowerCase()} punchlist`] = v.schedule;
    stageCopy[`complete ${k.toLowerCase()} punchlist`] = v.complete;
  });

  const keySteps = _.map(steps, (step) => ({
    valid: _.get(step, "valid", false),
    collapse: _.get(step, "completed", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    title: _.get(stageCopy, [step.textKey], "--"),
    timeline: _.isEmpty(step.steps)
      ? null
      : _.map(step.steps, (subStep) => ({
          valid: _.get(subStep, "valid", false),
          icon: <SubListIcon checked={_.get(subStep, "completed", false)} />,
          text: _.get(stageCopy, [subStep.textKey], "--"),
        })),
  }));

  const displayVelocity = velocity?.onSiteInstallation
    ? `${velocity?.onSiteInstallation?.min}-${velocity?.onSiteInstallation?.max}`
    : "4-6";

  return (
    <>
      <StageTopContent
        stage={tracker.onSiteInstallation}
        headerText="On-Site Installation"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.onSiteInstallation?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : `Usually takes ${displayVelocity} weeks`
        }
        subHeaderText={
          isBatteryOnly
            ? "We’ll complete the electrical work needed to connect your new battery and make sure the system is properly installed."
            : "We complete the electrical work needed to connect your new solar panels and make sure the system is properly mounted and optimized to maximize sunlight capture and energy production."
        }
      >
        {!complete && _.isEmpty(schedules) && _.isEmpty(completedSchedules) && (
          <Requirements>
            <Requirement
              headText="Confirm on-site installation"
              subText={
                <Typography variant="body1" color="textSecondary">
                  You’ll receive an email with your proposed date and time.
                  Contact{" "}
                  <LinkElment
                    color="primary"
                    href="mailto:scheduling@freedomsolarpower.com"
                  >
                    scheduling
                  </LinkElment>{" "}
                  if you do not see this email within a few days.
                </Typography>
              }
            />
          </Requirements>
        )}
        {!complete && !_.isEmpty(schedules) && (
          <Requirements
            note={
              <>
                Please note, shut down dates cannot be rescheduled without
                impacting your solar installation. Contact{" "}
                <LinkElment
                  component={Link}
                  to="mailto:customerservice@freedomsolarpower.com"
                >
                  Customer Care
                </LinkElment>{" "}
                if you need assistance.
              </>
            }
          >
            {schedules.map((sched: any, i: number) => (
              <Requirement
                headText={sched.title}
                key={`schedules-${i}`}
                subText={
                  <Typography variant="body1" color="textSecondary">
                    {sched.text}{" "}
                    <Typography component="span" color="secondary.light">
                      {sched.time}
                    </Typography>
                  </Typography>
                }
              />
            ))}
          </Requirements>
        )}
        {complete &&
          !_.isEmpty(completedSchedules) &&
          completedSchedules.length === 3 && (
            <Requirements isComplete>
              {completedSchedules.map((sched: any, i: number) => (
                <Requirement
                  headText={sched.title}
                  key={`completed-schedules-${i}`}
                  subText={
                    <Typography variant="body1" color="textSecondary">
                      {"Completed " + sched.text}
                    </Typography>
                  }
                />
              ))}
            </Requirements>
          )}

        {!complete &&
          _.get(electricalStage, "completed", false) &&
          _.get(panelInstallationStage, "completed", false) &&
          additionalPunchlist &&
          !additionalPunchlist.scheduled && (
            <Requirements>
              <Requirement
                headText={`Confirm ${_.get(
                  stageCopy,
                  additionalPunchlist.scheduleLabel,
                  "--"
                ).toLowerCase()}`}
                subText={
                  <Typography variant="body1" color="textSecondary">
                    You’ll receive an email with your proposed date and time.
                    Contact{" "}
                    <LinkElment
                      color="primary"
                      href="mailto:scheduling@freedomsolarpower.com"
                    >
                      scheduling
                    </LinkElment>{" "}
                    if you do not see this email within a few days.
                  </Typography>
                }
              />
            </Requirements>
          )}

        {!complete &&
          _.get(electricalStage, "completed", false) &&
          _.get(panelInstallationStage, "completed", false) &&
          additionalPunchlist &&
          additionalPunchlist.scheduled &&
          !additionalPunchlist.completed && (
            <Requirements>
              <Requirement
                headText={_.capitalize(
                  _.get(stageCopy, additionalPunchlist.scheduleLabel, "--")
                )}
                subText={
                  <Typography variant="body1" color="textSecondary">
                    {_.get(additionalPunchlist, "scheduleDate", "--")}{" "}
                    <Typography component="span" color="secondary.light">
                      {_.get(additionalPunchlist, "scheduleTime", "--")}
                    </Typography>
                  </Typography>
                }
              />
            </Requirements>
          )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="accordionIcon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={[
            "You",
            "Freedom Solar Scheduling Team",
            "Freedom Solar Installation Team",
            "Freedom Solar Licensed Electrician",
          ]}
        />
        <ListLayout
          width="100%"
          title="On-Site Visit Reminders"
          listType="simple"
          listData={[
            isBatteryOnly
              ? "Average installation time: 4-6 hours"
              : "Average time on-site: 4-6 hours, installation may take several days to complete",
            <>
              We need your Wi-Fi information to set up your solar monitoring
              system. Please make sure that it is added to your{" "}
              <LinkElment
                component={Link}
                to={`/${id}/account/property-details`}
              >
                Account
              </LinkElment>
            </>,
            <>
              If your property has a gate code, add it to your{" "}
              <LinkElment
                component={Link}
                to={`/${id}/account/property-details`}
              >
                Account
              </LinkElment>
            </>,
            "Clear your driveway to give our team space to park",
            ...(isBatteryOnly
              ? []
              : [
                  "We'll need access to your garage, attic, and electrical panel. You can help speed things up by removing obstructions before we arrive",
                ]),
            "Put away any large pets—we love dogs but we want to keep your pets and our team safe!",
          ]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
