import Stack from "@mui/material/Stack";
import { Stages } from "../../components/project/stages";
import { ProjectKickoff } from "./kickoff";

export const ProjectMainScreen = () => {
  return (
    <>
      <Stack display={{xs: "flex", md: "none"}} sx={{margin: "0 auto", paddingY: "2.5rem"}}>
        <Stages forMobile />
      </Stack>
      <Stack display={{ xs: "none", md: "flex" }}>
        <ProjectKickoff />
      </Stack>
    </>
  );
};
