import React, { useState, useRef } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Switch from "@mui/material/Switch";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";

import { customSelectDropdown } from "../../theme/index";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export const Inputs = () => {
  const [showPassword, setShowPassword] = useState(false);
  const codeRef1 = useRef(null);
  const codeRef2 = useRef(null);
  const codeRef3 = useRef(null);
  const codeRef4 = useRef(null);

  const handleCodeChange = (event: any, ref: any) => {
    const inputValue = event.target.value;

    if (inputValue.length === 1) {
      ref.current.focus();
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 1.5fr" },
          gap: 10,
        }}
      >
        <Box>
          <FormControl>
            <Checkbox defaultChecked />
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Default"
            />
            <FormControlLabel
              required
              control={<Checkbox size="small" />}
              label="Small Required"
            />
            <FormControlLabel
              disabled
              control={<Checkbox />}
              label="Disabled"
            />
          </FormGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <FormControl>
            {/* <FormLabel id="demo-radio-buttons-group-label">Radio</FormLabel> */}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="female" control={<Radio />} label="" />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Default"
              />
              <FormControlLabel
                value="other"
                control={<Radio size="small" />}
                label="Small"
              />
              <FormControlLabel
                value="disabled"
                control={<Radio />}
                label="Disabled"
                disabled
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <FormControlLabel control={<Switch defaultChecked />} label="" />
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Default"
            />
            <FormControlLabel
              required
              control={<Switch size="small" />}
              label="Small Required"
            />
            <FormControlLabel disabled control={<Switch />} label="Disabled" />
          </FormGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            position: "relative",
          }}
        >
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Email"
              helperText="Please enter your email"
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              placeholder=" "
            />
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              startAdornment={
                <InputAdornment position="start">kg</InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "TextField",
              }}
              error
            />
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Email"
              helperText="Please enter your email"
              sx={{ m: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              value="asdfasdf"
              disabled
              placeholder=" "
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            gap: 10,
            position: "relative",
          }}
        >
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Primary Address"
              helperText="Helper Text"
              multiline
              minRows={3}
              sx={{ m: 1, width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder=" "
            />
          </FormControl>
          <FormControl>
            <TextField
              id="outlined-select-currency"
              select
              label="Select"
              value=""
              helperText="Please select your currency"
              SelectProps={customSelectDropdown}
              placeholder=" "
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            position: "relative",
          }}
        >
          <TextField
            inputRef={codeRef1}
            sx={{
              m: 1,
              width: "4.75rem",
              height: "4.75rem",
              "& input": {
                textAlign: "center",
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
              },
            }}
            onChange={(event) => handleCodeChange(event, codeRef2)}
            inputProps={{ maxLength: 1 }}
          />
          <TextField
            inputRef={codeRef2}
            sx={{
              m: 1,
              width: "4.75rem",
              height: "4.75rem",
              "& input": {
                textAlign: "center",
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
              },
            }}
            onChange={(event) => handleCodeChange(event, codeRef3)}
            inputProps={{ maxLength: 1 }}
          />
          <TextField
            inputRef={codeRef3}
            sx={{
              m: 1,
              width: "4.75rem",
              height: "4.75rem",
              "& input": {
                textAlign: "center",
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
              },
            }}
            onChange={(event) => handleCodeChange(event, codeRef4)}
            inputProps={{ maxLength: 1 }}
          />
          <TextField
            inputRef={codeRef4}
            sx={{
              m: 1,
              width: "4.75rem",
              height: "4.75rem",
              "& input": {
                textAlign: "center",
                fontSize: "2.25rem",
                lineHeight: "2.75rem",
              },
            }}
            onChange={(event) => handleCodeChange(event, codeRef1)}
            inputProps={{ maxLength: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
};
