import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import EmailIcon from "@mui/icons-material/Email";
import CircularProgress from "@mui/material/CircularProgress";

import * as api from "../../api";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultData = {
  email: "",
};

const errorDefault = {
  email: ""
};

export const EmailOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, email, emailVerified, secondaryContactId, secondaryContact } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { customerId } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const isSecondaryContact = secondaryContactId === customerId;
  const onboardingStepCount = isSecondaryContact ? 3 : 1;
  const currentStep = emailVerified ? onboardingStepCount * 1 : onboardingStepCount * 0.5;

  const [formData, setFormData] = useState(defaultData);
  const [errors, setErrors] = useState(errorDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (isSecondaryContact) {
      setFormData({email: secondaryContact.email || ''});
    } else if (email) {
      setFormData({email});
    }
  }, []);

  useEffect(() => {
    const { email } = formData;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const debounce = setTimeout(() => {
      let errEmail = "", withError = false;

      if (email === "") {
        withError = true;
      }

      if (!emailPattern.test(email) && email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      setErrors({
        email: errEmail,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const currentEmail = isSecondaryContact ? secondaryContact.email : email;
    if (formData.email === currentEmail && emailVerified) {
      handleSkip();
      return;
    }

    const nextPage = "/onboarding/email/verify";
    setIsLoading(true);

    try {
      const payload: any = { emailVerified: false, onboardingStep: nextPage };

      if (isSecondaryContact) {
        payload.secondaryContact = { ...secondaryContact, email: formData.email };
      } else {
        payload.email = formData.email;
      }

      await api.portal.update(id, payload);
      await api.onboarding.sendEmailVerification(id, formData.email as string).catch((err) => console.error(err));

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("emailRequestCode", {
          email: formData.email
        }),
      });

      dispatch(setPortalProps(payload));
      navigate(nextPage);
    } catch (error) {
      console.error(error)
      setIsLoading(false);
      enqueueSnackbar("Something went wrong. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  const handleSkip = () => {
    const onboardingUpdate = { onboardingStep: "/onboarding/phone" };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    dispatch(setPortalProps(onboardingUpdate));
    navigate("/onboarding/phone");
  }

  return (
    <VerificationLayout
      currentStep={currentStep}
      withBackButton={false}
    >
      <HeaderContent
        headerText="Add your email address"
        headerVariant="h5"
        subHeaderText="We’ll contact you regarding appointments and other details needed for your solar installation."
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={false}
        currentStep={currentStep}
      />
          <MiddleContainer>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Email address"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                error={errors.email !== "" ? true : false}
                helperText={errors.email}
                placeholder=" "
                disabled={isLoading}
              />
              <Typography variant="body1" marginTop={2} color="text.light">
                We’ll email you a confirmation code.
              </Typography>
            </FormControl>
          </MiddleContainer>
          <BottomCtaContainer>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={() => handleSubmit()}
              disabled={isLoading || isDisabled}
            >
              {isLoading && (
                <CircularProgress color="inherit" size={16} thickness={4.5} />
              )}
              Continue
            </Button>
          </BottomCtaContainer>
    </VerificationLayout>
  );
};
