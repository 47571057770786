import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { matchIsValidTel } from "mui-tel-input";
import { PhoneInput } from "../../components/shared/phoneInput";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";

import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

export const PhoneNumberOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id, phoneNumber, phoneNumberVerified, secondaryContactId, secondaryContact } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { customerId } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const isSecondaryContact = secondaryContactId === customerId;
  const onboardingStepCount = isSecondaryContact ? 6 : 2;
  const currentStep = phoneNumberVerified ? onboardingStepCount * 1 : onboardingStepCount - 0.5;

  const [phoneValue, setPhoneValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isSecondaryContact) {
      setPhoneValue(secondaryContact.phoneNumber || "");
    } else if (phoneNumber) {
      setPhoneValue(phoneNumber);
    }
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      const phone = `+1${phoneValue}`;
      let withError = false;
      let disable = true;

      if (phoneValue !== "" && !matchIsValidTel(phone)) {
        disable = true;
        withError = true;
      }

      if (matchIsValidTel(phone) && phoneValue !== "") {
        disable = false;
        withError = false;
      }

      setError(withError);
      setIsDisabled(disable);
    }, 300);

    return () => clearTimeout(debounce);
  }, [phoneValue]);

  const handleSubmit = async () => {
    const currentPhone = isSecondaryContact ? secondaryContact.phoneNumber : phoneNumber;
    if (phoneValue === currentPhone && phoneNumberVerified) {
      handleSkip();
      return;
    }

    const nextPage = "/onboarding/phone/verify";

    setIsLoading(true);
    try {
      const payload: any = { phoneNumberVerified: false, onboardingStep: nextPage };

      if (isSecondaryContact) {
        payload.secondaryContact = { ...secondaryContact, phoneNumber: phoneValue };
      } else {
        payload.phoneNumber = phoneValue;
      }

      await api.portal.update(id, payload as any);
      await api.onboarding.sendPhoneVerification(id, phoneValue).catch((err) => console.error(err));

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("phoneRequestCode", {
          phone: phoneValue
        }),
      });

      dispatch(setPortalProps(payload));
      navigate(nextPage);

    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const handleSkip = () => {
    const nextPage = isSecondaryContact ? "/onboarding/success" : "/onboarding/second-contact";

    const onboardingUpdate = { onboardingStep: nextPage };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    dispatch(setPortalProps(onboardingUpdate));
    navigate(nextPage);
  }

  return (
    <VerificationLayout
      currentStep={currentStep}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/email")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/email")}
        headerText="Add your phone number"
        headerVariant="h5"
        subHeaderText="We’ll contact you regarding appointments and other details needed for your solar installation. "
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={currentStep}
      />
      <MiddleContainer>
        <FormControl variant="outlined" fullWidth>
          <PhoneInput
            value={phoneValue}
            setValue={setPhoneValue}
            error={error}
            setError={setError}
            disabled={isLoading}
          />
          <Typography variant="body1" marginTop={2} color="text.light">
            We’ll text you a confirmation code. Messaging and data rates may
            apply.
          </Typography>
        </FormControl>
      </MiddleContainer>
      <BottomCtaContainer>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => handleSubmit()}
          disabled={isLoading || isDisabled}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
