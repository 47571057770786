import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import GoogleLogo from "../../assets/icons/google.svg";
import FbLogo from "../../assets/icons/fb.svg";
import SignupMobileBG from "../../assets/images/signup-mobile-bg.svg";

export const OauthContent = ({ inviteId }: any) => {
  const navigate = useNavigate();
  return (
    <Stack
      width={{ xs: "100%", lg: "50%" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={12}
      padding={{
        xs: 6,
        sm: 0,
      }}
      sx={{
        position: "sticky",
        top: 0,
        height: "100dvh",
        backgroundImage: {
          xs: `url(${SignupMobileBG})`,
          sm: "none",
        },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
        backgroundPositionY: "-40px",
      }}
    >
      <Stack gap={3} textAlign="center">
        <Typography variant="h4" color="textPrimary" fontWeight={600}>
          Get started
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Create your Freedom Solar account
        </Typography>
      </Stack>

      <Stack width="450px" maxWidth="100%">
        <Button
          variant="contained"
          onClick={() => navigate(`/signup/${inviteId}/email`)}
          fullWidth
          size="large"
        >
          Sign up with email
        </Button>
      </Stack>

      <Stack
        direction="row"
        gap={4.5}
        alignItems="center"
        justifyContent="center"
        width="327px"
        maxWidth="100%"
      >
        <Divider sx={{ width: "137px", height: "2px" }} />
        <Typography variant="body1" color="textPrimary">
          or
        </Typography>
        <Divider sx={{ width: "137px", height: "2px" }} />
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <Button
          variant="contained"
          color="secondary"
          startIcon={<img src={GoogleLogo} alt="google logo" loading="lazy" />}
          fullWidth
          size="large"
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            })
          }
        >
          Sign up with Google
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<img src={FbLogo} alt="fb logo" loading="lazy" />}
          fullWidth
          size="large"
          onClick={() =>
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Facebook,
            })
          }
        >
          Sign up with Facebook
        </Button>
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap={6}
        flex={{
          xs: "auto",
          sm: "inherit",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          marginTop={{ xs: "auto", sm: 0 }}
          paddingBottom={7}
        >
          <Typography variant="body1" color="textPrimary">
            Already have an account?
          </Typography>

          <Button variant="text" onClick={() => navigate("/signin")}>
            Login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
