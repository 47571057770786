import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import LinkElment from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { StageTopContent } from "../../components/project/stageTopContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { ListLayout } from "../../components/project/listLayout";
import { useAppSelector } from "../../store/hook";
import { ListIcon } from "../../components/project/listIcon";
import { getRedesignCopy } from "../../utils/redesignTypeCopy";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const SystemDesign = () => {
  const { id, tracker, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const [todo, setTodo] = useState({}) as any;
  const [designDocument, setDesignDocument] = useState(null) as any;
  const [complete, setComplete] = useState(false) as any;
  const [steps, setSteps] = useState([]) as any;
  const [recreateDesignCtaCopy, setRecreateDesignCtaCopy] = useState("") as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("systemDesign");

  useEffect(() => {
    if (tracker && tracker.systemDesign) {
      setComplete(_.get(tracker.systemDesign, "complete", false));

      const systemDesignFiles = _.get(tracker.systemDesign, "files", []);
      const todo: any = {};
      const stageSteps: any = _.sortBy(
        _.get(tracker.systemDesign, "steps", []),
        "stepId"
      );
      setSteps(stageSteps);

      const createDesign = _.find(
        stageSteps,
        (s) => s.textKey === "create final system design"
      );
      if (createDesign && createDesign.valid && !createDesign.completed)
        todo.createDesign = true;

      const finalSystemDesign = _.find(
        systemDesignFiles,
        (f) => f._id && f.doc === "finalSystemDesign"
      );
      if (createDesign && createDesign.completed && finalSystemDesign) {
        setDesignDocument(finalSystemDesign);
      }

      const reCreateDesign = _.filter(
        stageSteps,
        (s) =>
          s.textKey === "recreate final system design" &&
          s.valid &&
          !s.completed
      );
      if (!todo.createDesign && reCreateDesign.length > 0) {
        todo.reCreateDesign = true;
        const recreateTask = _.sortBy(reCreateDesign, ["task.created_at"])[0];
        if (getRedesignCopy(recreateTask.task)) {
          setRecreateDesignCtaCopy(
            getRedesignCopy(recreateTask.task)?.description
          );
        }
      }

      const approveDesign = _.find(
        stageSteps,
        (s) => s.textKey === "approve final system design"
      );
      if (
        !todo.createDesign &&
        !todo.reCreateDesign &&
        approveDesign &&
        approveDesign.valid &&
        !approveDesign.completed
      )
        todo.approveDesign = true;

      setTodo(todo);
    }
  }, []);

  const stageCopy: any = {
    ...extendedProjectsCopy,
    "create final system design": isBatteryOnly
      ? "Create final design"
      : "Create final system design",
    "approve final system design": isBatteryOnly
      ? "Approve final design"
      : "Approve final system design",
    "recreate final system design": isBatteryOnly
      ? "Recreate final design"
      : "Recreate final system design",
  };

  const stageAction: any = {
    "approve final system design":
      _.get(steps, "0.completed", false) && !_.get(steps, "1.completed", false),
  };

  const keySteps = _.map(steps, (step, i) => ({
    valid: _.get(step, "valid", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorInfo={
          !_.get(step, "completed", false) &&
          step.textKey === "recreate final system design"
        }
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    text: _.get(stageCopy, [step.textKey], "--"),
    action: !complete && _.get(stageAction, [step.textKey], false),
  }));

  return (
    <>
      <StageTopContent
        stage={tracker.systemDesign}
        headerText="System Design"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.systemDesign?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : "Usually takes 2-3 weeks"
        }
        subHeaderText={
          isBatteryOnly
            ? "We design the best possible placement for your battery and send you the final layout for approval."
            : "We design the best possible solar system for your property and send you the final layout for approval."
        }
      >
        {!complete && todo.createDesign && (
          <Requirements>
            <Requirement
              headText="Your design is being created"
              subText={
                isBatteryOnly
                  ? "No action is needed at this time; we're actively creating your design and will contact you soon for you to review."
                  : "No action is needed at this time; we're actively designing your home's solar system and will contact you soon to review the layout of your panels."
              }
            />
          </Requirements>
        )}

        {!complete && todo.reCreateDesign && (
          <Requirements>
            <Requirement
              headText="Redesign in progress"
              subText={recreateDesignCtaCopy}
            />
          </Requirements>
        )}

        {!complete && todo.approveDesign && (
          <Requirements>
            <Requirement
              headText="Approve your design"
              subText={
                <Typography variant="body1" color="textSecondary">
                  {isBatteryOnly
                    ? "We’ll call you to review the location of your battery. You can view your design in your "
                    : "We’ll call you to review the layout of your solar system. You can view your design by clicking "}
                  <LinkElment
                    color="primary"
                    component={Link}
                    target="_blank"
                    to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${designDocument._id}/${designDocument.name}`}
                  >
                    here
                  </LinkElment>
                  .
                </Typography>
              }
            />
          </Requirements>
        )}

        {complete && designDocument && (
          <Requirements isComplete>
            <Requirement
              headText="Design approved"
              subText={
                <Typography variant="body1" color="textSecondary">
                  You can view your design by clicking{" "}
                  <LinkElment
                    color="primary"
                    component={Link}
                    target="_blank"
                    to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${designDocument._id}/${designDocument.name}`}
                  >
                    here
                  </LinkElment>
                  .
                </Typography>
              }
            />
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="icon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={["Freedom Solar Designer", "You"]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
