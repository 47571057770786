import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { VariantType, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";

export const Snackbars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickVariant = (msg: string, variant: VariantType) => () => {
    if (variant === "default") {
      enqueueSnackbar(msg, {
        variant,
        onClose: () => {},
        action: (key) => (
          <Stack direction="row">
            <Button variant="text" color="primary" size="small">
              Label
            </Button>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
      });
    } else {
      enqueueSnackbar(msg, {
        variant,
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Button
        onClick={handleClickVariant(" I Love Snacks", "default")}
        variant="contained"
        color="primary"
        sx={{ marginBottom: 4 }}
      >
        Show default snackbar
      </Button>
      <Button
        onClick={handleClickVariant("This is a success message!", "success")}
        variant="contained"
        color="success"
        sx={{ marginBottom: 4 }}
      >
        Show success snackbar
      </Button>
      <Button
        onClick={handleClickVariant("This is a warning message!", "warning")}
        variant="contained"
        color="warning"
        sx={{ marginBottom: 4 }}
      >
        Show warning snackbar
      </Button>
      <Button
        onClick={handleClickVariant("This is a error message!", "error")}
        variant="contained"
        color="error"
      >
        Show error snackbar
      </Button>
    </Box>
  );
};
