import React, { useState, useEffect } from "react";
import _ from "lodash";
import Link from "@mui/material/Link";
import { useSnackbar } from "notistack";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { matchIsValidTel } from "mui-tel-input";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";

import { PhoneInput } from "../../components/shared/phoneInput";
import { ContainerLayout } from "../../components/account/containerLayout";
import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

import { ChangePromptDialog } from "../../components/shared/dialogs/changePrompt";

const defaultValue = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const errorDefault = {
  email: "",
  phoneNumber: "",
};

export const PersonalInfo = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, firstName, lastName, email, phoneNumber, secondaryContactId, secondaryContact } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { customerId } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const isSecondaryContact = secondaryContactId === customerId;

  const [formData, setFormData] = useState(defaultValue);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(errorDefault);
  const [hasChanges, setHasChanges] = useState(false);
  const [toastShowing, setToastShowing] = useState(false);

  useEffect(() => {
    setDefault();
  }, []);

  useEffect(() => {
    const { firstName, lastName, email, phoneNumber } = formData;

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phone = `+1${phoneNumber}`;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPhone = "",
        withError = false;

      if (
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        phoneNumber === ""
      ) {
        withError = true;
      }

      if (!emailPattern.test(email) && email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (phoneNumber !== "" && !matchIsValidTel(phone)) {
        errPhone = "Invalid phone number";
        withError = true;
      }

      if (phoneNumber !== "" && matchIsValidTel(phone)) {
        errPhone = "";
        withError = false;
      }

      setErrors({
        email: errEmail,
        phoneNumber: errPhone,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  useEffect(() => {
    let oldData = {
      firstName,
      lastName,
      email,
      phoneNumber,
    };

    if (isSecondaryContact) {
      oldData = {
        firstName: secondaryContact.firstName || "",
        lastName: secondaryContact.lastName || "",
        email: secondaryContact.email || "",
        phoneNumber: secondaryContact.phoneNumber || "",
      };
    }

    setHasChanges(!_.isEqual(oldData, formData));
  }, [formData]);

  const setDefault = () => {
    if (isSecondaryContact) {
      setFormData({
        firstName: secondaryContact.firstName || "",
        lastName: secondaryContact.lastName || "",
        email: secondaryContact.email || "",
        phoneNumber: secondaryContact.phoneNumber || "",
      });
    } else {
      setFormData({
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        phoneNumber: phoneNumber || "",
      });
    }
  }

  const toggleEditing = () => {
    const _isEditing = !isEditing;
    setIsEditing(_isEditing);
    setDefault()
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      let payload: any = {};

      if (isSecondaryContact) {
        payload.secondaryContact = { ...secondaryContact, ...formData };
      } else {
        payload = formData;
      }

      await api.portal.update(id, payload);
      dispatch(setPortalProps(payload));
      setIsEditing(false);
      setHasChanges(false);
      setToastShowing(true);
      enqueueSnackbar("Changes saved successfully", {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error) {
      setToastShowing(true);
      enqueueSnackbar("Failed to update account. Please try again!", {
        variant: "error",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContainerLayout
      headerText="Personal information"
      cta={
        isEditing ? (
          <>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={toggleEditing}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={isLoading}
            >
              <Typography variant="button" color="secondary.light">
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleUpdate}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={
                isLoading ||
                isDisabled ||
                !hasChanges ||
                !!errors.email ||
                !!errors.phoneNumber
              }
            >
              {isLoading && (
                <CircularProgress color="inherit" size={16} thickness={4.5} />
              )}
              Update
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={toggleEditing}
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            disabled={toastShowing}
          >
            Edit
          </Button>
        )
      }
      withMobileBack
    >
      <Box>
        <Stack gap={9} direction="row" flexWrap="wrap">
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="First Name"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Last Name"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Email"
                type="email"
                id="email-start-adornment"
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isEditing || isLoading}
                error={errors.email !== "" ? true : false}
                helperText={errors.email}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <PhoneInput
                value={formData.phoneNumber}
                setValue={(v: any) =>
                  setFormData({ ...formData, phoneNumber: v })
                }
                error={errors.phoneNumber !== ""}
                setError={(e: any) => {
                  setErrors({
                    ...errors,
                    phoneNumber: e ? "Invalid phone number" : "",
                  });
                }}
                readOnly={!isEditing}
                disabled={isEditing || isLoading}
              />
            </FormControl>
          </Box>
        </Stack>
        <Typography
          variant="body1"
          sx={{
            paddingTop: "8px",
            color: "#5E6E7D",
          }}
        >
          Please contact{" "}
          <Link
            color="primary"
            href="mailto:customerservice@freedomsolarpower.com"
          >
            Customer Care
          </Link>{" "}
          if you need to update your phone number or email address.
        </Typography>
      </Box>

      <ChangePromptDialog
        block={hasChanges}
        isLoading={isLoading}
        onSave={handleUpdate}
      />
    </ContainerLayout>
  );
};
