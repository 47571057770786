import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook";
import moment from "moment";

import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { gray } from "../../theme/colors";
import { CustomTooltip } from "../shared/customTooltip";

const overflowEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const bodyCellStyle = {
  borderColor: gray[100],
  fontSize: "1rem !important",
  lineHeight: "1.5rem !important",
};

export const EnhancedTableRow = ({
  row,
  isItemSelected,
  labelId,
  handleClick,
}: any) => {
  const { id } = useAppSelector((state) => state.portal || ({} as any));
  const navigate = useNavigate();

  const getStageChip = (stageData: any, textOnly: boolean = false) => {
    if (!stageData) {
      return;
    }

    return textOnly ? (
      stageData.name
    ) : (
      <Chip
        icon={<i className={stageData.icon} />}
        label={stageData.name}
        sx={{ "& .MuiChip-label": { color: gray[600] } }}
      />
    );
  };

  const openFolder = (e: any, type: string, folder: string) => {
    const el = e.target;
    const elNodename = el.nodeName;
    const elClassname = ["svg", "path"].includes(elNodename)
      ? el.className.baseVal
      : el.className;
    const noAction =
      elClassname.includes("info-tooltip") || ["path"].includes(elNodename);

    if (noAction) {
      return;
    }

    if (type === "folder") {
      navigate(`/${id}/documents/${folder}`);
    } else {
      window.open(
        `${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${row._id}/${row.name}`,
        "_blank"
      );
    }
  };

  const renderTooltip = (title: string, placement: any) => {
    return (
      <CustomTooltip
        title={title}
        customClass="info-tooltip no-action"
        placement={placement}
        maxWidth={{
          xs: "250px",
          sm: "500px",
        }}
        isDark
      >
        <InfoOutlinedIcon
          fontSize="small"
          sx={{
            color: gray[400],
          }}
        />
      </CustomTooltip>
    );
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      <TableCell
        padding="checkbox"
        sx={{
          ...bodyCellStyle,
          verticalAlign: { xs: "top", sm: "middle" },
          paddingY: { xs: "7px", sm: "inherit" },
        }}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
          sx={{
            visibility: row.actionNeeded ? "hidden" : "visible",
          }}
        />
      </TableCell>
      <TableCell
        id={labelId}
        scope="row"
        sx={{ ...bodyCellStyle, paddingLeft: 0 }}
      >
        <Stack
          display={{ xs: "none", sm: "flex" }}
          direction="row"
          alignItems="center"
          gap={2}
          onClick={(e) => openFolder(e, row.type, row.folderName)}
          width="max-content"
          maxWidth="100%"
          sx={overflowEllipsis}
          className="no-action"
        >
          <i
            className={`${row.icon} no-action`}
            style={{
              width: "2.125rem",
              minWidth: "2.125rem",
              height: "2.125rem",
              color: gray[500],
            }}
          />
          <Box width="100%" sx={overflowEllipsis} className="no-action">
            {row.label}
          </Box>
          {row.tooltip && (
            <>
              <Box
                display={{
                  xs: "none",
                  sm: "flex",
                }}
                alignItems="center"
              >
                {renderTooltip(row.tooltip, "right")}
              </Box>
              <Box
                display={{
                  xs: "flex",
                  sm: "none",
                }}
                alignItems="center"
              >
                {renderTooltip(row.tooltip, "bottom")}
              </Box>
            </>
          )}
        </Stack>
        <Stack
          display={{ xs: "flex", sm: "none" }}
          direction="row"
          alignItems="flex-start"
          gap={2}
          onClick={(e) => openFolder(e, row.type, row.folderName)}
          width="max-content"
          maxWidth="100%"
        >
          <i
            className={row.icon}
            style={{
              width: "2.125rem",
              minWidth: "2.125rem",
              height: "2.125rem",
              color: gray[500],
            }}
          />
          <Stack
            direction="column"
            justifyContent="flex-start"
            gap={2}
            maxWidth="100%"
            sx={overflowEllipsis}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Box width="100%" sx={overflowEllipsis}>
                {row.label}
              </Box>
              {row.tooltip && (
                <>
                  <Box
                    display={{
                      xs: "none",
                      sm: "flex",
                    }}
                    alignItems="center"
                  >
                    {renderTooltip(row.tooltip, "right")}
                  </Box>
                  <Box
                    display={{
                      xs: "flex",
                      sm: "none",
                    }}
                    alignItems="center"
                  >
                    {renderTooltip(row.tooltip, "bottom")}
                  </Box>
                </>
              )}
            </Stack>
            <Stack gap={2} color={gray[600]}>
              {getStageChip(row.stage, true)}
              {row.type !== "folder" && (
                <Stack direction="row" alignItems="center" gap={2}>
                  {moment.utc(row.created_at).local().format("MM/DD/YY")} by{" "}
                  {row.by}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          ...bodyCellStyle,
          display: {
            xs: "none",
            sm: "table-cell",
          },
        }}
      >
        {getStageChip(row.stage)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          ...bodyCellStyle,
          color: gray[600],
          display: {
            xs: "none",
            sm: "table-cell",
          },
        }}
      >
        {row.type !== "folder" && (
          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            {moment.utc(row.created_at).local().format("MM/DD/YY")} by {row.by}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};
