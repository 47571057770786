import React, { useState, useEffect } from "react";
import _, { forEach } from "lodash";
import { isBrowser } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TableTopFilter } from "./tableTopFilter";
import { EnhancedTableHead } from "./enhancedTableHead";
import { EnhancedTableRow } from "./enhancedTableRow";
import { EmptyRow } from "./emptyRow";

import { documentFolderLabels } from "../../components/constant/document";
import * as api from "../../api";
import { downloadFiles } from "../../services/download";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setPendingDownloadFile } from "../../store/modules/meSlice";

export const EnhancedTable = ({ headers, data }: any) => {
  const { portalId } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pendingDownloadFiles } = useAppSelector(
    (state) => state.me || ({} as any)
  );

  const [search, setSearch] = useState<any>("");
  const [selected, setSelected] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [filteredDocs, setFilteredDocs] = useState<any>(data);
  const [waitToDownload, setWaitToDownload] = useState(null);

  const handleClick = (event: any, id: string) => {
    const el = event.target;
    const elNodename = el.nodeName;
    const elClassname = ["svg", "path"].includes(elNodename)
      ? el.className.baseVal
      : el.className;

    const selectedIndex = selected.indexOf(id);
    const newSelected = _.cloneDeep(selected);
    const noAction =
      ["BUTTON", "path"].includes(elNodename) ||
      elClassname.includes("no-action");

    if (noAction) {
      return;
    }

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(filteredDocs.map((x: any) => x.id));
      return;
    }
    setSelected([]);
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const folders = Object.keys(documentFolderLabels);
      const downloadDocs: any = [];
      let hasFolder = false;

      _.each(selected, (id) => {
        if (folders.includes(id)) {
          hasFolder = true;
          downloadDocs.push({ folderName: id });
        } else {
          downloadDocs.push({ fileId: id });
        }
      });
      console.log(downloadDocs);
      const toZip = isBrowser && (hasFolder || downloadDocs.length > 1); // Zip if selected folder or more than 1 files selected

      const res = await api.documents.download(
        portalId as string,
        toZip,
        downloadDocs
      );
      const { immediate, files, zipFile } = _.get(res, "data", {});
      if (!_.isEmpty(files)) {
        if (immediate) {
          // Download the files immediately - For mobile users
          await downloadFiles(files);
          setSelected([]);
          setIsDownloading(false);
        } else {
          // Save file to async download
          const newPendingDownloads = [
            ...pendingDownloadFiles,
            zipFile.fileName,
          ];
          dispatch(setPendingDownloadFile(newPendingDownloads));
          setWaitToDownload(zipFile.fileName);
        }
      } else {
        enqueueSnackbar("No files downloaded.", {
          variant: "default",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
        setSelected([]);
        setIsDownloading(false);
      }
    } catch (error) {
      setIsDownloading(false);
      enqueueSnackbar("Failed to download files. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  useEffect(() => {
    const handleSearch = (query: string) => {
      const searchRegex = new RegExp(query.toLowerCase(), "i");
      let updatedList = _.cloneDeep(data);

      // Include all elements which includes the search query
      updatedList = updatedList.filter((doc: any) => {
        return doc.label.toLowerCase().match(searchRegex);
      });

      // Trigger render with updated values
      setFilteredDocs(updatedList);
    };
    handleSearch(search);
  }, [search]);

  useEffect(() => {
    if (_.isEmpty(pendingDownloadFiles) && waitToDownload) {
      setIsDownloading(false);
      setWaitToDownload(null);
      setSelected([]);
    }
  }, [pendingDownloadFiles]);

  return (
    <>
      <TableTopFilter
        docCount={filteredDocs.length}
        selectedCount={selected.length}
        search={search}
        setSearch={setSearch}
        downloadIsVisible={selected.length > 0}
        isDownloading={isDownloading}
        handleDownload={handleDownload}
      />
      <Table
        sx={{
          tableLayout: "fixed",
        }}
      >
        <EnhancedTableHead
          headers={headers}
          numSelected={selected.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={filteredDocs.length}
        />
        <TableBody>
          {filteredDocs.map((row: any, index: number) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <EnhancedTableRow
                row={row}
                isItemSelected={isItemSelected}
                labelId={labelId}
                handleClick={handleClick}
                key={row.id}
              />
            );
          })}
          {filteredDocs.length === 0 && <EmptyRow />}
        </TableBody>
      </Table>
      {selected.length > 0 && (
        <Button
          variant="contained"
          size="large"
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
            position: "fixed",
            bottom: "3.2rem",
            right: "1rem",
          }}
          onClick={handleDownload}
          disabled={isDownloading}
        >
          {isDownloading ? (
            <>
              <CircularProgress color="inherit" size={16} thickness={4.5} />
              Downloading
            </>
          ) : (
            "Download"
          )}
        </Button>
      )}
    </>
  );
};
