import _ from "lodash";
import { Auth } from "aws-amplify";
import { useAppSelector, useAppDispatch } from "../../store/hook";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import FullLogo from "../../assets/images/full_logo_white.svg";
import { MainNavData } from "../constant/navigation";
import { CustomNavLink } from "../shared/customNavLink";
import { primary, gray } from "../../theme/colors";
import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";
import { logout } from "../../store/modules/meSlice";
import { removeImpersonate } from "../../services/auth";
import { PortalsMenu } from "./portalsMenu";

const listStyle = {
  display: "flex",
  flexDirection: "column",
  flex: "auto",
  padding: 0,
  gap: 1,
}

const navStyle = {
    padding: "15px 16px",
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      minWidth: "unset",
      marginRight: "1rem",
      color: gray[500],
    },
    "& .MuiListItemIcon-root svg": {
      width: "20px",
      height: "20px",
    },
    "&:hover": {
      backgroundColor: gray[700],
    },
    "&.active": {
      backgroundColor: gray[900],
    },
    "&.active .MuiListItemIcon-root": {
      color: primary[400],
    },
};

const NavListDetails = ({icon, title}:any) => {
  return <>
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={title}
      sx={{
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        margin: 0,
      }}
      disableTypography
    />
  </>
}

export const DrawerContent = () => {
  const portal = useAppSelector((state) => state.portal || ({} as any));
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    await Auth.signOut();
    removeImpersonate();
    TagManager.dataLayer({dataLayer: GenerateGTMArgs("loggedOut")});
    dispatch(logout());
    window.location.reload();
  };
    
  return <>
    <Box marginX={"auto"}>
      <img src={FullLogo} alt="full logo" loading="lazy" />
    </Box>

    <PortalsMenu />

    <List sx={listStyle}>
      {MainNavData(portal).map((linkData:any, index:any) => (
        <ListItem key={linkData.name} disablePadding>
          <ListItemButton
            component={CustomNavLink}
            sx={navStyle}
            to={linkData.to}
            activeClassName={"active"}
          >
            <NavListDetails icon={linkData.icon} title={linkData.name} />
          </ListItemButton>
        </ListItem>
      ))}

      <ListItem disablePadding sx={{ marginTop: "auto" }}>
        <ListItemButton sx={navStyle} onClick={() => handleLogout()}>
          <NavListDetails icon={<LogoutIcon />} title={"Log out"} />
        </ListItemButton>
      </ListItem>
    </List>
  </>
}