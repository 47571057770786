import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import LinkElment from "@mui/material/Link";
import { StageTopContent } from "../../components/project/stageTopContent";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { ListLayout } from "../../components/project/listLayout";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { useAppSelector } from "../../store/hook";
import { ListIcon } from "../../components/project/listIcon";
import { SubListIcon } from "../../components/project/subListIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const ApplicationsPermits = () => {
  const { id, tracker, velocity, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [hoaDocument, setHoaDocument] = useState(null) as any;
  const [permitDocument, setPermitDocument] = useState(null) as any;
  const [utilityDocument, setUtilityDocument] = useState(null) as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("applicationsPermits");

  useEffect(() => {
    if (tracker && tracker.applicationsAndPermits) {
      const stageSteps: any = _.sortBy(
        _.get(tracker.applicationsAndPermits, "steps", []),
        "stepId"
      );

      const hoa = _.find(stageSteps, (s) => s.textKey === "hoa");
      const hoaApproval = _.find(
        hoa.steps,
        (s) => s.textKey === "hoa approval"
      );
      if (
        hoaApproval &&
        hoaApproval.valid &&
        hoaApproval.completed &&
        !_.isEmpty(hoaApproval.files)
      ) {
        setHoaDocument(hoaApproval.files[0]);
      }

      const permit = _.find(stageSteps, (s) => s.textKey === "permit");
      const permitApproval = _.find(
        permit.steps,
        (s) => s.textKey === "permit approval"
      );
      if (
        permitApproval &&
        permitApproval.valid &&
        permitApproval.completed &&
        !_.isEmpty(permitApproval.files)
      ) {
        setPermitDocument(permitApproval.files[0]);
      }

      const utility = _.find(stageSteps, (s) => s.textKey === "utility");
      const utilityApproval = _.find(
        utility.steps,
        (s) => s.textKey === "utility interconnection approval"
      );
      if (
        utilityApproval &&
        utilityApproval.valid &&
        utilityApproval.completed &&
        !_.isEmpty(utilityApproval.files)
      ) {
        setUtilityDocument(utilityApproval.files[0]);
      }
    }
  }, []);

  const complete = _.get(tracker.applicationsAndPermits, "complete", false);
  const steps: any = _.sortBy(
    _.get(tracker.applicationsAndPermits, "steps", []),
    "stepId"
  );
  const hoaStage = _.find(steps, (s) => s.textKey === "hoa");
  const permitStage = _.find(steps, (s) => s.textKey === "permit");
  const utilityStage = _.find(steps, (s) => s.textKey === "utility");

  const hoaAppSubmitted = _.get(hoaStage, "steps.0.completed", false);
  const permitAppSubmitted = _.get(permitStage, "steps.0.completed", false);
  const utilityAppSubmitted = _.get(utilityStage, "steps.0.completed", false);

  const applicationSubmitted =
    hoaAppSubmitted || permitAppSubmitted || utilityAppSubmitted;

  const stageCopy: any = {
    ...extendedProjectsCopy,
    hoa: "HOA",
    "hoa submittal": "HOA submittal",
    "hoa approval": _.get(hoaStage, "steps.1.completed", false) ? (
      hoaDocument ? (
        <LinkElment
          component={Link}
          target="_blank"
          to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${hoaDocument._id}/${hoaDocument.name}`}
        >
          HOA approval
        </LinkElment>
      ) : (
        "HOA approval"
      )
    ) : (
      "HOA approval"
    ),
    permit: "Permit",
    "permit submittal": "Permit submittal",
    "permit approval": _.get(permitStage, "steps.1.completed", false) ? (
      permitDocument ? (
        <LinkElment
          component={Link}
          target="_blank"
          to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${permitDocument._id}/${permitDocument.name}`}
        >
          Permit approval
        </LinkElment>
      ) : (
        "Permit approval"
      )
    ) : (
      "Permit approval"
    ),
    utility: "Utility",
    "utility interconnection submittal": "Utility interconnection submittal",
    "utility interconnection approval": _.get(
      utilityStage,
      "steps.1.completed",
      false
    ) ? (
      utilityDocument ? (
        <LinkElment
          component={Link}
          target="_blank"
          to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${utilityDocument._id}/${utilityDocument.name}`}
        >
          Utility interconnection approval
        </LinkElment>
      ) : (
        "Utility interconnection approval"
      )
    ) : (
      "Utility interconnection approval"
    ),
    "installation preparation": "Installation preparation",
  };

  const stageSubtextCopy: any = {
    "hoa submittal": _.get(hoaStage, "steps.0.completed", false)
      ? moment
          .utc(_.get(hoaStage, "steps.0.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
    "hoa approval": _.get(hoaStage, "steps.1.completed", false)
      ? moment
          .utc(_.get(hoaStage, "steps.1.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
    "permit submittal": _.get(permitStage, "steps.0.completed", false)
      ? moment
          .utc(_.get(permitStage, "steps.0.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
    "permit approval": _.get(permitStage, "steps.1.completed", false)
      ? moment
          .utc(_.get(permitStage, "steps.1.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
    "utility interconnection submittal": _.get(
      utilityStage,
      "steps.0.completed",
      false
    )
      ? moment
          .utc(_.get(utilityStage, "steps.0.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
    "utility interconnection approval": _.get(
      utilityStage,
      "steps.1.completed",
      false
    )
      ? moment
          .utc(_.get(utilityStage, "steps.1.task.completed_at"))
          .local()
          .format("MMMM D")
      : "",
  };

  const keySteps = _.map(steps, (step) => ({
    valid: _.get(step, "valid", false),
    collapse: _.get(step, "completed", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    title: _.get(stageCopy, [step.textKey], "--"),
    timeline: _.isEmpty(step.steps)
      ? null
      : _.map(step.steps, (subStep) => ({
          valid: _.get(subStep, "valid", false),
          icon: <SubListIcon checked={_.get(subStep, "completed", false)} />,
          text: _.get(stageCopy, [subStep.textKey], "--"),
          subText: _.get(stageSubtextCopy, [subStep.textKey], null),
        })),
  }));

  const displayVelocity = velocity?.applicationsAndPermitsVelocity
    ? `${velocity?.applicationsAndPermitsVelocity?.min}-${velocity?.applicationsAndPermitsVelocity?.max}`
    : "3-9";

  return (
    <>
      <StageTopContent
        stage={tracker.applicationsAndPermits}
        headerText="Applications & Permits"
        info={
          complete
            ? `Completed: ${moment
                .utc(
                  tracker.applicationsAndPermits?.task?.completed_at ||
                    tracker.applicationsAndPermits?.task?.ready_at
                )
                .local()
                .format("MM/DD/YYYY")}`
            : `Usually takes ${displayVelocity} weeks`
        }
        subHeaderText={
          isBatteryOnly
            ? "We submit applications to your HOA, utility, and local government to confirm that your battery will be fully compliant, safe, and reliable."
            : "We submit applications to your HOA, utility, and local government to confirm that your solar system will be fully compliant, safe, and reliable."
        }
      >
        {!complete && !applicationSubmitted && (
          <Requirements>
            <Requirement
              headText="Your applications are pending submission"
              subText="We will update this step as your applications are submitted"
            />
          </Requirements>
        )}

        {!complete && applicationSubmitted && (
          <Requirements>
            <Requirement
              headText="Your applications are submitted"
              subText="We will update this step as your applications are approved"
            />
          </Requirements>
        )}

        {complete && (
          <Requirements isComplete>
            <Requirement headText="Your applications were approved" />
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="accordionIcon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={[
            "Freedom Solar Internal Operations",
            "Utility",
            "HOA",
            "City or County Government",
          ]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
