import { MuiTelInput } from "mui-tel-input";

export const PhoneInput = ({
  value,
  setValue,
  setPhoneData,
  error,
  setError,
  disabled,
  readOnly,
}: any) => {
  const onChange = (newValue: any, info: any) => {
    const { nationalNumber, countryCode, numberValue } = info;

    if (
      !numberValue ||
      nationalNumber?.length > 10 ||
      nationalNumber === value ||
      (numberValue?.length > 13 && countryCode !== "US")
    ) {
      return;
    }

    if (setPhoneData) {
      setPhoneData(info);
    }
    setError(false);
    setValue(nationalNumber);
  };

  return (
    <MuiTelInput
      label="Phone"
      defaultCountry="US"
      onlyCountries={["US", "PH"]}
      disableDropdown={
        process.env.REACT_APP_HOST_ENV !== "development" || readOnly
      }
      value={`+1${value}`}
      onChange={onChange}
      error={error}
      helperText={error ? "Invalid phone number" : ""}
      disabled={disabled}
      forceCallingCode
      className={readOnly ? "MuiInputBase-readOnly" : ""}
      inputProps={{
        readOnly: readOnly,
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
    />
  );
};
