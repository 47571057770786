import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";

import LightModeIcon from "@mui/icons-material/LightMode";
import { primary, gray } from "../../theme/colors";

import CustomAlertBG from "../../assets/images/alert-exciting-bg.svg";

export const Alerts = () => {
  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Box marginBottom={3}>
        <Alert severity="info" onClose={() => {}}>
          <AlertTitle>Info</AlertTitle>
          This is an info alert — <strong>check it out!</strong>
        </Alert>
      </Box>
      <Box marginBottom={3}>
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small">
              UNDO
            </Button>
          }
        >
          This is an info alert — <strong>check it out!</strong>
        </Alert>
      </Box>
      <Box marginBottom={3}>
        <Alert severity="warning" onClose={() => {}}>
          This is an warning alert — check it out!
        </Alert>
      </Box>
      <Box marginBottom={3}>
        <Alert severity="error" onClose={() => {}}>
          This is an error alert — check it out!
        </Alert>
      </Box>
      <Box marginBottom={3}>
        <Alert severity="success" onClose={() => {}}>
          This is an success alert — check it out!
        </Alert>
      </Box>
      <Box marginBottom={3}>
        <Alert
          icon={<LightModeIcon />}
          onClose={() => {}}
          sx={{
            background: `url(${CustomAlertBG}) no-repeat center right`,
            backgroundSize: "contain",
            backgroundColor: primary[400],
            borderColor: primary[400],
            "& .MuiAlert-message": { color: gray[0] },
            "& .MuiAlert-icon": { color: primary[200] },
            "& > div:last-child button": {
              color: gray[900],
            },
          }}
        >
          <AlertTitle
            sx={{
              color: gray[0],
            }}
          >
            Your site assessment is today at 10:00am–12:30pm!
          </AlertTitle>
          This is a custom alert — check it out!
        </Alert>
      </Box>
    </Box>
  );
};
