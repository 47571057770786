export const utilityCompanies: any = [
  {
    "hidden" : false,
    "displayOrder" : 1,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "-",
    "value" : ""
  },
  {
    "hidden" : false,
    "displayOrder" : 2,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "A&N EC",
    "value" : "A&N EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 3,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Abermarle EMC",
    "value" : "Abermarle EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 4,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "AEP Texas",
    "value" : "AEP Texas"
  },
  {
    "hidden" : false,
    "displayOrder" : 5,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "APEX Utilities",
    "value" : "APEX Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 6,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Austin Energy",
    "value" : "Austin Energy"
  },
  {
    "hidden" : false,
    "displayOrder" : 7,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Bandera EC",
    "value" : "Bandera EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 8,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Bartlett EC",
    "value" : "Bartlett EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 9,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Bell County EC",
    "value" : "Bell County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 10,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Big Country EC",
    "value" : "Big Country EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 11,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Black Hills Energy",
    "value" : "Black Hills Energy"
  },
  {
    "hidden" : false,
    "displayOrder" : 12,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Blue Bonnet Electric Co-Op",
    "value" : "Bluebonnet"
  },
  {
    "hidden" : false,
    "displayOrder" : 13,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Bowie Cass EC",
    "value" : "Bowie Cass EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 14,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Brenham Utilities",
    "value" : "Brenham Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 15,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Brunswick EMC",
    "value" : "Brunswick EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 16,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Bryan Texas Utilities",
    "value" : "Bryan Texas Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 17,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Burnet Utilities",
    "value" : "Burnet Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 18,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Carteret-Craven EMC",
    "value" : "Carteret-Craven EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 19,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Castroville Utilities",
    "value" : "Castroville Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 20,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Centerpoint",
    "value" : "Centerpoint"
  },
  {
    "hidden" : false,
    "displayOrder" : 21,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Central Electric",
    "value" : "Central Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 22,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Central EMC",
    "value" : "Central EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 23,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Cherokee County EC",
    "value" : "Cherokee County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 24,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Castroville",
    "value" : "City of Castroville"
  },
  {
    "hidden" : false,
    "displayOrder" : 25,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Farmersville",
    "value" : "City of Farmersville"
  },
  {
    "hidden" : false,
    "displayOrder" : 26,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Fountain",
    "value" : "City of Fountain"
  },
  {
    "hidden" : false,
    "displayOrder" : 27,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Lakeland",
    "value" : "City of Lakeland"
  },
  {
    "hidden" : false,
    "displayOrder" : 28,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Leesburg",
    "value" : "City of Leesburg"
  },
  {
    "hidden" : false,
    "displayOrder" : 29,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Lockhart",
    "value" : "City of Lockhart"
  },
  {
    "hidden" : false,
    "displayOrder" : 30,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Loveland Utilities",
    "value" : "City of Loveland Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 31,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Seguin",
    "value" : "City of Seguin"
  },
  {
    "hidden" : false,
    "displayOrder" : 32,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "City of Winter Park",
    "value" : "City of Winter Park"
  },
  {
    "hidden" : false,
    "displayOrder" : 33,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Coleman County EC",
    "value" : "Coleman County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 34,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "College Station Utilities",
    "value" : "College Station Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 35,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Colorado Springs Utilities",
    "value" : "Colorado Springs Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 36,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Comanche EC",
    "value" : "Comanche EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 37,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Community EC",
    "value" : "Community EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 38,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Concho Valley EC",
    "value" : "Concho Valley EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 39,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Core EC",
    "value" : "Core EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 40,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "CoServ",
    "value" : "CoServ"
  },
  {
    "hidden" : false,
    "displayOrder" : 41,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "CPS",
    "value" : "CPS"
  },
  {
    "hidden" : false,
    "displayOrder" : 42,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "CTEC",
    "value" : "CTEC"
  },
  {
    "hidden" : false,
    "displayOrder" : 43,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Deep East Texas EC",
    "value" : "Deep East Texas EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 44,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Denton",
    "value" : "Denton"
  },
  {
    "hidden" : false,
    "displayOrder" : 45,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Dominion Energy",
    "value" : "Dominion Energy"
  },
  {
    "hidden" : false,
    "displayOrder" : 46,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Duke Energy",
    "value" : "Duke Energy"
  },
  {
    "hidden" : false,
    "displayOrder" : 47,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Duke Energy - Florida",
    "value" : "Duke Energy - Florida"
  },
  {
    "hidden" : false,
    "displayOrder" : 48,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Duke Energy - North Carolina",
    "value" : "Duke Energy - North Carolina"
  },
  {
    "hidden" : false,
    "displayOrder" : 49,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "El Paso Electric",
    "value" : "El Paso Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 50,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Energy United",
    "value" : "Energy United"
  },
  {
    "hidden" : false,
    "displayOrder" : 51,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Entergy",
    "value" : "Entergy"
  },
  {
    "hidden" : false,
    "displayOrder" : 52,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Farmers Electric",
    "value" : "Farmers Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 53,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Floresville Electric",
    "value" : "Floresville Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 54,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Florida P&L",
    "value" : "Florida P&L"
  },
  {
    "hidden" : false,
    "displayOrder" : 55,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Fort Belknap EC",
    "value" : "Fort Belknap EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 56,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Fort Collins Utilities",
    "value" : "Fort Collins Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 57,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Fountain Utilities",
    "value" : "Fountain Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 58,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Four County EMC",
    "value" : "Four County EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 59,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "French Broad",
    "value" : "French Broad"
  },
  {
    "hidden" : false,
    "displayOrder" : 60,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Gainesville Regional Utilities",
    "value" : "Gainesville Regional Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 61,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Garland Power & Light",
    "value" : "Garland Power & Light"
  },
  {
    "hidden" : false,
    "displayOrder" : 62,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Gastonia Utilities",
    "value" : "Gastonia Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 63,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "GCEC",
    "value" : "GCEC"
  },
  {
    "hidden" : false,
    "displayOrder" : 64,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Georgetown Utilities",
    "value" : "Georgetown Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 65,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "GVEC",
    "value" : "GVEC"
  },
  {
    "hidden" : false,
    "displayOrder" : 66,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Halifax EMC",
    "value" : "Halifax EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 67,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Hamilton County EC",
    "value" : "Hamilton County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 68,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Heart of Texas EC",
    "value" : "Heart of Texas EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 69,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "HILCO Coop",
    "value" : "HILCO Coop"
  },
  {
    "hidden" : false,
    "displayOrder" : 70,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "IREA",
    "value" : "IREA"
  },
  {
    "hidden" : false,
    "displayOrder" : 71,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Jasper Newton EC",
    "value" : "Jasper Newton EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 72,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Kames EC",
    "value" : "Kames EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 73,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Kerrville Public Utilities",
    "value" : "Kerrville Public Utilities"
  },
  {
    "hidden" : false,
    "displayOrder" : 74,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Kissimmee Utility Authority",
    "value" : "Kissimmee Utility Authority"
  },
  {
    "hidden" : false,
    "displayOrder" : 75,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Lakeland Electric",
    "value" : "Lakeland Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 76,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Lee County EC",
    "value" : "Lee County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 77,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Longmont EC",
    "value" : "Longmont EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 78,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Loveland Water & Power",
    "value" : "Loveland Water & Power"
  },
  {
    "hidden" : false,
    "displayOrder" : 79,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Lubbock P&L",
    "value" : "Lubbock P&L"
  },
  {
    "hidden" : false,
    "displayOrder" : 80,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Lumbee River EC",
    "value" : "Lumbee River EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 81,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Lyons Municipal",
    "value" : "Lyons Municipal"
  },
  {
    "hidden" : false,
    "displayOrder" : 82,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Magic Valley EC",
    "value" : "Magic Valley EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 83,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Medina EC",
    "value" : "Medina EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 84,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Mid South Synergy",
    "value" : "Mid South Synergy"
  },
  {
    "hidden" : false,
    "displayOrder" : 85,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "MidSouth EC",
    "value" : "MidSouth EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 86,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Monroe NC",
    "value" : "Monroe NC"
  },
  {
    "hidden" : false,
    "displayOrder" : 87,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Mountain Parks Electric",
    "value" : "Mountain Parks Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 88,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Mountain View Electric Association",
    "value" : "Mountain View Electric Association"
  },
  {
    "hidden" : false,
    "displayOrder" : 89,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Navarro County EC",
    "value" : "Navarro County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 90,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Navasota Valley EC",
    "value" : "Navasota Valley EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 91,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "NBU",
    "value" : "NBU"
  },
  {
    "hidden" : false,
    "displayOrder" : 92,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "New Smyrna Beach Utility Commission",
    "value" : "New Smyrna Beach Utility Commission"
  },
  {
    "hidden" : false,
    "displayOrder" : 93,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Northern Neck Electric Cooperative",
    "value" : "Northern Neck Electric Cooperative"
  },
  {
    "hidden" : false,
    "displayOrder" : 94,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Nueces EC",
    "value" : "Nueces EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 95,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Ocala Electric Utility",
    "value" : "Ocala Electric Utility"
  },
  {
    "hidden" : false,
    "displayOrder" : 96,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "ONCOR",
    "value" : "ONCOR"
  },
  {
    "hidden" : false,
    "displayOrder" : 97,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Orlando Utilities Commission",
    "value" : "Orlando Utilities Commission"
  },
  {
    "hidden" : false,
    "displayOrder" : 98,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Other",
    "value" : "Other"
  },
  {
    "hidden" : false,
    "displayOrder" : 99,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Peace River EC",
    "value" : "Peace River EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 100,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "PEC",
    "value" : "PEC"
  },
  {
    "hidden" : false,
    "displayOrder" : 101,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Piedmont Electric",
    "value" : "Piedmont Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 102,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Poudre Valley Electric Cooperative",
    "value" : "Poudre Valley Electric Cooperative"
  },
  {
    "hidden" : false,
    "displayOrder" : 103,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Prince George Electric",
    "value" : "Prince George Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 104,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Randolph EMC",
    "value" : "Randolph EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 105,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Rappahannock Electric",
    "value" : "Rappahannock Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 106,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Rio Grande EC",
    "value" : "Rio Grande EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 107,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Roanoke EC",
    "value" : "Roanoke EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 108,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Rusk County EC",
    "value" : "Rusk County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 109,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Rutherford EMC",
    "value" : "Rutherford EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 110,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Sam Houston EC",
    "value" : "Sam Houston EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 111,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "San Bernard EC",
    "value" : "San Bernard EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 112,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "San Isabel EA",
    "value" : "San Isabel Electric Association"
  },
  {
    "hidden" : false,
    "displayOrder" : 113,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "San Isabel Electric",
    "value" : "San Isabel Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 114,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "San Marcos",
    "value" : "San Marcos"
  },
  {
    "hidden" : false,
    "displayOrder" : 115,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "San Patricio EC",
    "value" : "San Patricio EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 116,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Sangre de Cristo EA",
    "value" : "Sangre de Cristo EA"
  },
  {
    "hidden" : false,
    "displayOrder" : 117,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "SECO Energy",
    "value" : "SECO Energy"
  },
  {
    "hidden" : false,
    "displayOrder" : 118,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "South River EMC",
    "value" : "South River EMC"
  },
  {
    "hidden" : false,
    "displayOrder" : 119,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Southside EC",
    "value" : "Southside EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 120,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Sumter EC",
    "value" : "Sumter EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 121,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Surry-Yadkin",
    "value" : "Surry-Yadkin"
  },
  {
    "hidden" : false,
    "displayOrder" : 122,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Tampa EC",
    "value" : "Tampa EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 123,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Tideland Electric",
    "value" : "Tideland Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 124,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "TNMP",
    "value" : "TNMP"
  },
  {
    "hidden" : false,
    "displayOrder" : 125,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Town of Apex",
    "value" : "Town of Apex"
  },
  {
    "hidden" : false,
    "displayOrder" : 126,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Town of Clayton",
    "value" : "Town of Clayton"
  },
  {
    "hidden" : false,
    "displayOrder" : 127,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Town of Estes Park",
    "value" : "Town of Estes Park"
  },
  {
    "hidden" : false,
    "displayOrder" : 128,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Town of Smithfield",
    "value" : "Town of Smithfield"
  },
  {
    "hidden" : false,
    "displayOrder" : 129,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Tri-County EC",
    "value" : "Tri-County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 130,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Trinity Valley EC",
    "value" : "Trinity Valley EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 131,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Union Power Corp",
    "value" : "Union Power Corp"
  },
  {
    "hidden" : false,
    "displayOrder" : 132,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "United Cooperative Services",
    "value" : "United Cooperative Services"
  },
  {
    "hidden" : false,
    "displayOrder" : 133,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "United Power",
    "value" : "United Power"
  },
  {
    "hidden" : false,
    "displayOrder" : 134,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Upshur Rural EC",
    "value" : "Upshur Rural EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 135,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Wake Electric",
    "value" : "Wake Electric"
  },
  {
    "hidden" : false,
    "displayOrder" : 136,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Wharton County EC",
    "value" : "Wharton County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 137,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Wise EC",
    "value" : "Wise EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 138,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Withlacoochee EC",
    "value" : "Withlacoochee EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 139,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Wood County EC",
    "value" : "Wood County EC"
  },
  {
    "hidden" : false,
    "displayOrder" : 140,
    "doubleData" : null,
    "description" : null,
    "readOnly" : false,
    "label" : "Xcel Energy",
    "value" : "Xcel Energy"
  }
];