import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSnackbar } from "notistack";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { matchIsValidTel } from "mui-tel-input";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";

import { ChangePromptDialog } from "../../components/shared/dialogs/changePrompt";
import { PhoneInput } from "../../components/shared/phoneInput";
import { ContainerLayout } from "../../components/account/containerLayout";
import { CustomDialog } from "../../components/shared/customDialog";
import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

const defaultValue = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const errorDefault = {
  email: "",
  phoneNumber: "",
};

export const AccountSecondContact = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, secondaryContactId, secondaryContact } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const [formData, setFormData] = useState(defaultValue);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(errorDefault);
  const [hasChanges, setHasChanges] = useState(false);
  const [toastShowing, setToastShowing] = useState(false);
  const [showUpdateContactVerifyDialog, setShowUpdateContactVerifyDialog] = useState(false);

  useEffect(() => {
    if (secondaryContactId) {
      setDefault();
    } else {
      setIsEditing(true);
    }
  }, []);

  useEffect(() => {
    const { firstName, lastName, email, phoneNumber } = formData;

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phone = `+1${phoneNumber}`;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPhone = "",
        withError = false;

      if (
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        phoneNumber === ""
      ) {
        withError = true;
      }

      if (email !== "" && !emailPattern.test(email)) {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (phoneNumber !== "" &&  !matchIsValidTel(phone)) {
        errPhone = "Invalid phone number";
        withError = true;
      }

      if (matchIsValidTel(phone) && phoneNumber !== "") {
        errPhone = "";
        withError = false;
      }

      setErrors({
        email: errEmail,
        phoneNumber: errPhone,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  useEffect(() => {
    const oldData = {
      firstName: _.get(secondaryContact, "firstName", "") || "",
      lastName: _.get(secondaryContact, "lastName", "") || "",
      email: _.get(secondaryContact, "email", "") || "",
      phoneNumber: _.get(secondaryContact, "phoneNumber", "") || "",
    };
    setHasChanges(!_.isEqual(oldData, formData));
  }, [formData]);

  const setDefault = () => {
    setFormData({
      firstName: _.get(secondaryContact, "firstName", "") || "",
      lastName: _.get(secondaryContact, "lastName", "") || "",
      email: _.get(secondaryContact, "email", "") || "",
      phoneNumber: _.get(secondaryContact, "phoneNumber", "") || "",
    });
  }

  const toggleEditing = () => {
    const _isEditing = !isEditing;
    setIsEditing(_isEditing);
    setDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (force: boolean = false) => {
    setIsLoading(true);
    try {

      // If not force, check if needs to show the verify prompt
      if (!force) {
        if (formData.email !== secondaryContact?.email) {
          setShowUpdateContactVerifyDialog(true);
          return;
        }
      }

      const payload: any = { secondaryContact: formData };
      const res = await api.portal.update(id, payload);

      if (!secondaryContactId) {
        payload.secondaryContactId = res.data.secondaryContactId;
      }

      dispatch(setPortalProps(payload));
      setIsEditing(false);
      setHasChanges(false);
      setToastShowing(true);
      setShowUpdateContactVerifyDialog(false);
      enqueueSnackbar("Changes saved successfully", {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error: any) {
      let msg = "Failed to update contact. Please try again!";
      const errorDetails = _.get(error.response, 'data.details');

      if (errorDetails && errorDetails === 'PRIMARY_CONTACT_EMAIL_USED') {
        msg = "Email provided is already a primary contact"
      }


      setShowUpdateContactVerifyDialog(false);
      setToastShowing(true);
      enqueueSnackbar(msg, {
        variant: "error",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelContactUpdate = () => {
    setFormData({ ...formData, email: _.get(secondaryContact, "email", "") || "" });
    setShowUpdateContactVerifyDialog(false);
  }

  const ctaBtn = (
    <>
      {secondaryContactId && !isEditing && (
        <Button
          variant="outlined"
          size="small"
          onClick={toggleEditing}
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
          disabled={toastShowing}
        >
          Edit
        </Button>
      )}

      {secondaryContactId && isEditing && (
        <>
          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={toggleEditing}
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            disabled={isLoading}
          >
            <Typography variant="button" color="secondary.light">
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleUpdate()}
            sx={{
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            disabled={
              isLoading ||
              isDisabled ||
              !hasChanges ||
              !formData.email ||
              !formData.phoneNumber ||  
              !!errors.email ||
              !!errors.phoneNumber
            }
          >
            {isLoading && (
              <CircularProgress color="inherit" size={16} thickness={4.5} />
            )}
            Update
          </Button>
        </>
      )}

      {!secondaryContactId && isEditing && (
        <Button
          variant="contained"
          size="small"
          onClick={() => handleUpdate()}
          sx={{
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
          disabled={
            isLoading ||
            isDisabled ||
            !hasChanges ||
            !formData.email ||
            !formData.phoneNumber ||
            !!errors.email ||
            !!errors.phoneNumber
          }
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Save
        </Button>
      )}
    </>
  );

  return (
    <ContainerLayout
      headerText="Secondary Contact"
      subHeaderText="Add a secondary adult to your profile. They can confirm appointment times and site visits if you are unavailable."
      cta={ctaBtn}
      withMobileSubHeaderText
    >
      <Stack gap={9} direction="row" flexWrap="wrap">
        <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="First Name"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              InputProps={{
                readOnly: !isEditing,
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
        </Box>
        <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Last Name"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              InputProps={{
                readOnly: !isEditing,
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
        </Box>
        <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              InputProps={{
                readOnly: !isEditing,
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
              error={!!errors.email ? true : false}
              helperText={errors.email}
            />
          </FormControl>
        </Box>
        <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
          <FormControl variant="outlined" fullWidth>
            <PhoneInput
              value={formData.phoneNumber}
              setValue={(v: any) =>
                setFormData({ ...formData, phoneNumber: v })
              }
              error={!!errors.phoneNumber}
              setError={(e: any) => {
                setErrors({
                  ...errors,
                  phoneNumber: e ? "Invalid phone number" : "",
                });
              }}
              readOnly={!isEditing}
              disabled={isLoading}
            />
          </FormControl>
        </Box>
      </Stack>
      <ChangePromptDialog
        block={hasChanges}
        isLoading={isLoading}
        onSave={handleUpdate}
      />
      <CustomDialog
        open={showUpdateContactVerifyDialog}
        handleClose={() => setShowUpdateContactVerifyDialog(false)}
        title="Updating your secondary contact?"
        content={
          <>
            <Typography
              variant="subtitle2"
              textAlign="center"
              color="textSecondary"
            >
              Editing the secondary contact's email will immediately disable their project tracker access and send an invitation to your new contact. 
            </Typography>
          </>
        }
        lCta={
          <Button
            variant="text"
            size="small"
            color="secondary"
            disabled={isLoading}
            onClick={handleCancelContactUpdate}
          >
            <Typography color="textPrimary">Cancel</Typography>
          </Button>
        }
        rCta={
          <Button
            variant="contained"
            size="small"
            disabled={isLoading}
            onClick={() => handleUpdate(true)}
          >
            {isLoading && (
              <CircularProgress color="inherit" size={16} thickness={4.5} />
            )}
            Update
          </Button>
        }
      />
    </ContainerLayout>
  );
};
