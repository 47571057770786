import { useState, useEffect } from "react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Link from "@mui/material/Link";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { DashboardLayout } from "../../components/dashboard/dashboardLayout";

import AppleStore from "../../assets/images/apple.svg";
import GoogleStore from "../../assets/images/google.svg";
import SupportTopCardBG from "../../assets/images/supportTopCard.svg";
import SupportBottomCardBG from "../../assets/images/supportBottomCard.svg";
import { monitoringDeviceData } from "../../constants/monitoringDevice";

const topCardStyle = {
  padding: "2.5rem 1.5rem",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  boxShadow: "none",
  background: `url(${SupportTopCardBG}) no-repeat top right`,
  backgroundSize: { xs: "70% 90%", sm: "auto" },
  backgroundPosition: { xs: "160% top", sm: "right top" },
  backgroundColor: "#ffffff",
  borderTop: { xs: "1px solid #E8EBED", sm: "none" },
};

const middleCardSTyle = {
  display: "flex",
  flexDirection: "column",
  paddingX: 6,
  paddingY: { xs: "2.5rem", sm: 6 },
  gap: 8,
  width: { xs: "100%", sm: "calc(50% - 12px)" },
  overflow: "hidden",
  boxShadow: "none",
  borderTop: { xs: "1px solid #E8EBED", sm: "none" },
};

const bottomCardSTyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingX: 6,
  paddingY: { xs: "2.5rem", sm: 6 },
  gap: 6,
  width: { xs: "100%", sm: "calc(100% / 3 - 12px)" },
  overflow: "hidden",
  boxShadow: "none",
  borderTop: { xs: "1px solid #E8EBED", sm: "none" },
};

const overflowEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const CardHeading = ({ title, subtitle, main }: any) => {
  return (
    <Stack gap={2}>
      <Typography
        variant={main ? "h6" : "subtitle1"}
        fontWeight={600}
        color="textPrimary"
        textAlign={{ xs: "left", sm: main ? "center" : "left" }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        textAlign={{ xs: "left", sm: main ? "center" : "left" }}
      >
        {subtitle}
      </Typography>
    </Stack>
  );
};

export const Support = () => {
  const { id, systemDetails } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const navigate = useNavigate();
  const [monitoringDevice, setMonitoringDevice] = useState(null) as any;

  useEffect(() => {
    const device = _.get(systemDetails, "monitoringDevice", null);
    if (device) {
      setMonitoringDevice(monitoringDeviceData[device]);
    }
  }, []);

  return (
    <DashboardLayout>
      <Stack gap={{ xs: 0, sm: 6 }}>
        <Paper sx={topCardStyle}>
          <CardHeading
            title="Contact Customer Care"
            subtitle="For questions about your solar installation project"
            main
          />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            gap={{ xs: 4, sm: 12 }}
          >
            <Stack direction="row" gap={4}>
              <LocalPhoneIcon color="primary" />
              <Link color="textPrimary" href="tel:(833) 500-0014">
                (833) 500-0014
              </Link>
            </Stack>

            <Stack direction="row" gap={4}>
              <EmailIcon color="primary" />
              <Link
                color="textPrimary"
                href="mailto:customerservice@freedomsolarpower.com"
                sx={overflowEllipsis}
              >
                customerservice@freedomsolarpower.com
              </Link>
            </Stack>
          </Stack>
        </Paper>
        <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 0, sm: 6 }}>
          <Paper sx={middleCardSTyle}>
            <CardHeading
              title="Billing & Invoicing"
              subtitle="For questions about payments"
            />
            <Stack gap={4}>
              <Stack direction="row" gap={3}>
                <LocalPhoneIcon color="primary" />
                <span>
                  <Link color="textPrimary" href="tel:(800) 504-2337">
                    (800) 504-2337
                  </Link>{" "}
                  + Option 5
                </span>
              </Stack>
              <Stack direction="row" gap={3}>
                <EmailIcon color="primary" />
                <Link
                  color="textPrimary"
                  href="mailto:ar@freedomsolarpower.com"
                >
                  ar@freedomsolarpower.com
                </Link>
              </Stack>
            </Stack>
          </Paper>
          <Paper sx={middleCardSTyle}>
            <CardHeading
              title="Monitoring & Service"
              subtitle="For service requests and questions about your launched system"
            />
            <Stack component={"p"} direction="row" gap={3} margin={0}>
              <Link
                color="primary"
                href="https://freedomsolarpower.com/service-department#request-service"
              >
                Submit a service request
              </Link>
              <ArrowForwardIosRoundedIcon sx={{ color: "#2660df" }} />
            </Stack>
          </Paper>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 0, sm: 6 }}>
          <Paper sx={bottomCardSTyle}>
            <CardHeading
              title="FAQs"
              subtitle="Get answers to our most frequently asked questions"
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{
                width: "max-content",
              }}
              onClick={() => navigate(`/${id}/support/faqs`)}
            >
              Read FAQs
            </Button>
          </Paper>
          {monitoringDevice && (
            <Paper sx={bottomCardSTyle}>
              <CardHeading
                title={`Download ${monitoringDevice.appName}`}
                subtitle="Monitor your system from your phone"
              />
              <Stack direction="row" gap={4}>
                <Link href={monitoringDevice.appAppleUrl} height="40px">
                  <img src={AppleStore} alt="apple" loading="lazy" />
                </Link>
                <Link href={monitoringDevice.appGoogleUrl} height="40px">
                  <img src={GoogleStore} alt="apple" loading="lazy" />
                </Link>
              </Stack>
            </Paper>
          )}
          <Paper
            sx={{
              ...bottomCardSTyle,
              background: `url(${SupportBottomCardBG}) no-repeat bottom right`,
              backgroundColor: "#FFEDE0",
            }}
          >
            <CardHeading
              title="Get Paid for Referrals"
              subtitle="Earn $500 for every friend you refer"
            />
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{
                width: "max-content",
              }}
              LinkComponent="a"
              href="https://freedomsolarpower.com/refer-us"
            >
              Learn More
            </Button>
          </Paper>
        </Stack>
      </Stack>
    </DashboardLayout>
  );
};
