import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FullLogo from "../assets/images/full_logo.svg";
import { ResetPasswordContent } from "../components/signin/resetPasswordContent";

import { isSessionAuthenticated } from "../services/auth";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [data, setData] = useState({ email: null, code: null } as any);
  const location = useLocation();

  const params = new URLSearchParams(location?.search);

  useEffect(() => {
    const initialize = async () => {
      const email = params.get("e");
      const code = params.get("c");
      const isLoggedIn = await isSessionAuthenticated();
      if (isLoggedIn) {
        navigate("/");
        return;
      }
      if (!email || !code) {
        enqueueSnackbar("Invalid reset password url!", {
          variant: "error",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
        navigate("/signin");
        return;
      }
      setData({
        email,
        code,
      });
      setIsLoading(false);
    };
    initialize();
  }, []);

  if (isLoading) {
    return <CircularProgress size={16} />;
  }

  return (
    <>
      <Box
        component="header"
        width="100%"
        position="fixed"
        top={0}
        padding={10}
        display={{ xs: "none", lg: "block" }}
      >
        <img src={FullLogo} alt="full logo" loading="lazy" />
      </Box>
      <Stack
        width="100%"
        minHeight="100dvh"
        direction="column"
        alignItems="center"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        gap={{
          xs: 6,
          sm: 12,
        }}
        padding={{
          xs: 6,
          sm: 0,
        }}
        paddingTop={{
          xs: 6,
          sm: 29.625,
        }}
        paddingBottom={{
          xs: 6,
          sm: 16,
        }}
      >
        <ResetPasswordContent email={data.email} code={data.code} />
      </Stack>
    </>
  );
};
