import { Typos } from "../components/theme/typos";
import { Buttons } from "../components/theme/buttons";
import { Elevations } from "../components/theme/elevations";
import { Inputs } from "../components/theme/inputs";
import { Icons } from "../components/theme/icons";
import { Alerts } from "../components/theme/alerts";
import { Dialogs } from "../components/theme/dialogs";
import { Snackbars } from "../components/theme/snackbars";
import { Steppers } from "../components/theme/steppers";
import { Chips } from "../components/theme/chips";
import { Tooltips } from "../components/theme/tooltips";
import { Stages } from "../components/theme/stages";

export const Theme = () => {
  return (
    <div className="theme">
      <Typos />
      <Buttons />
      <Inputs />
      <Icons />
      <Elevations />
      <Alerts />
      <Dialogs />
      <Snackbars />
      <Steppers />
      <Chips />
      <Tooltips />
      <Stages />
    </div>
  );
};
