import { Auth } from 'aws-amplify';

export const isSessionAuthenticated = async () => {
  try {
    const session = await Auth.currentSession();
    return session.isValid();
  } catch (error) {
    return false;
  }
};

export const getSessionUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const data = user.attributes;
  if (data['custom:created_at']) {
    data.createdAt = parseInt(data['custom:created_at']);
  } else if (data.identities) {
    const identity = JSON.parse(data.identities).pop();

    data.createdAt = identity.dateCreated;
  }

  return data;
};

export const updateUserAttribute = async (formData: any) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, formData)
}

/**
 * comma separated impersonate request data
 * @param data 
 */
export const setImpersonate = (portalId: string, data = '') => {
  const [,ttl, impersonator] = data.split(',');
  if (ttl) localStorage.setItem("impersonatettl", ttl);
  if (impersonator) localStorage.setItem("impersonator", impersonator);

  localStorage.setItem("impersonateportal", portalId);
}

export const removeImpersonate = () => {
  localStorage.removeItem("impersonateportal");
  localStorage.removeItem("impersonatettl");
  localStorage.removeItem("impersonator");
}