import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { isSessionAuthenticated } from "../services/auth";
import CircularProgress from "@mui/material/CircularProgress";

export const OauthRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      const isLoggedIn = await isSessionAuthenticated();

      if (isLoggedIn) {
        navigate("/");
      } else {
        navigate("/signin?err=UserNotMatchException");
      }
    };

    initialize();
  }, []);

  return <CircularProgress color="inherit" size={16} thickness={4.5} />;
};
