import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import * as api from "../../api";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { VerificationCode } from "../../components/signup/verificationCode";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

export const EmailVerifyOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id, email, emailVerified, secondaryContactId, secondaryContact } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { customerId } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const isSecondaryContact = secondaryContactId === customerId;
  const currentStep = isSecondaryContact ? 3 : 1;
  
  const [isLoading, setIsLoading] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState('');

  const [error, setError] = useState(false);

  useEffect(() => {
    if (emailVerified) {
      setTimeout(() => {
        navigate("onboarding/phone");
      }, 1000);
      return
    }

    let validEmail = null;
    if (isSecondaryContact && secondaryContact.email) {
      validEmail = secondaryContact.email;
    } else if (email) {
      validEmail = email;
    }

    if (!!validEmail) {
      setVerifyEmail(validEmail);
    } else {
      navigate("/onboarding/email");
    }
  }, []);

  const handleSubmit = async (finalCode = []) => {
    setIsLoading(true);

    try {
      await api.onboarding.verifyEmail(id, {
        to: verifyEmail,
        code: finalCode.join("") as string,
      });

      const nextPage = "/onboarding/phone";
      const payload: any = { emailVerified: true, onboardingStep: nextPage };
      await api.portal.update(id, payload);

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("emailVerification", {
          email: email
        }),
      });

      dispatch(setPortalProps(payload));
      setTimeout(() => {
        navigate(nextPage);
      }, 1000);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const handleResend = () => {
    if (resendIsLoading) return;

    setResendIsLoading(true)
    api.onboarding
      .sendEmailVerification(id, verifyEmail)
      .then(() => {
        setResendIsLoading(false)
        enqueueSnackbar("Email verification sent!", {
          variant: "default",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
      })
      .catch(() => {
        setResendIsLoading(false)
        enqueueSnackbar("Failed to resend code. Please try again!", {
          variant: "error",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
      });
  };

  return (
    <VerificationLayout
      currentStep={currentStep}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/email")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/email")}
        headerText="Verify your email"
        headerVariant="h5"
        subHeaderText={
          emailVerified ? (
            "Thank you for verifying your email!"
          ) : (
            <>
              We’ve sent a verification code to {verifyEmail}.<br /> Please enter it
              below.
            </>
          )
        }
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={currentStep}
      />

      <VerificationCode
        formLabel="Email verification"
        errorText="Incorrect code"
        inputLength={6}
        isLoading={isLoading}
        resendIsLoading={resendIsLoading}
        isVerified={emailVerified}
        error={error}
        setError={setError}
        handleSubmit={handleSubmit}
        resendNote="Didn’t get a code? Try checking your junk folder or"
        resendText="Resend email verification"
        handleResend={handleResend}
      />
    </VerificationLayout>
  );
};
