import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

export const Chips = () => {
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Stack direction="row" gap={3} flexWrap={"wrap"}>
        <Chip label="No icon chip" />
        <Chip label="Clickable" onClick={handleClick} />
        <Chip
          label="Clickable Deletable"
          onClick={handleClick}
          onDelete={handleDelete}
        />
        <Chip
          icon={<i className="i-project-kickoff" />}
          label="Project Kickoff"
        />
        <Chip
          icon={<i className="i-site-assessment" />}
          label="Site Assessment"
        />
        <Chip icon={<i className="i-system-design" />} label="System Design" />
        <Chip
          icon={<i className="i-applications-permits" />}
          label="Applications & Permits"
        />
        <Chip
          icon={<i className="i-onsite-installation" />}
          label="On-Site Installation"
        />
        <Chip
          icon={<i className="i-thirdparty-inspections" />}
          label="Third-Party Inspections"
        />
        <Chip icon={<i className="i-system-launch" />} label="System Launch" />
      </Stack>
    </Box>
  );
};
