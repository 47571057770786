import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ContainerLayout } from "../../components/account/containerLayout";

import { AccountSidebar } from "../../components/account/accountSidebar";
import { useAppSelector } from "../../store/hook";

export const AccountDetails = () => {
  const { firstName, lastName, address, secondaryContactId, secondaryContact } = useAppSelector((state) => state.portal || {} as any);
  const { customerId } = useAppSelector((state) => state.me || ({} as any));

  const [addressPreviewMapUrl, setAddressPreviewMapUrl]: any = useState(null);
  const [fullName, setFullName]: any = useState("");


  useEffect(() => {
    if (address && address.address) {
      const addressUriEncoded = encodeURIComponent(address.address);
      const map = {
        maptype: 'roadmap',
        zoom: 19,
        center: addressUriEncoded,
        size: '400x400',
        markers: `icon:https://sunrise-files.outliant.com/assets/mapsmarker.png%7C${addressUriEncoded}`,
        key: process.env.REACT_APP_GMAP_API_KEY
      }
      const url = `${process.env.REACT_APP_GMAP_STATIC_URL}?maptype=${map.maptype}&zoom=${map.zoom}&center=${map.center}&size=${map.size}&markers=${map.markers}&key=${map.key}`;
      setAddressPreviewMapUrl(url)
    }

    if (secondaryContactId === customerId) {
      setFullName(`${secondaryContact.firstName || ''} ${secondaryContact.lastName || ''}`);
    } else {
      setFullName(`${firstName || ''} ${lastName || ''}`);
    }
  }, []);


  return (
    <>
      <ContainerLayout headerText="Account details">
        <Stack
          gap={9}
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
        >
          <Box
            component="img"
            minWidth={"96px"}
            height={"96px"}
            borderRadius={"100%"}
            border={"1px solid gray"}
            src={addressPreviewMapUrl || ""}
          ></Box>
          <Stack gap={2}>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              textAlign={{
                xs: "center",
                sm: "left",
              }}
            >
              {fullName}
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign={{
                xs: "center",
                sm: "left",
              }}
            >
              {`${address.address}, ${address.city}, ${address.state}, ${address.zip}`}
            </Typography>
          </Stack>
        </Stack>
      </ContainerLayout>
      <Box
        display={{
          xs: "block",
          sm: "none",
        }}
      >
        <AccountSidebar forMobile />
      </Box>
    </>
  );
};
