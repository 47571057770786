import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { VerificationLayout } from "../../components/signup/verificationLayout";

import SignupSuccessImage from "../../assets/images/onboard-success.svg";
import SignupSuccessMobileImage from "../../assets/images/onboard-success-mobile.svg";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setMyProfileProps } from "../../store/modules/meSlice";

import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

export const SignupSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id, firstName, lastName, email, phoneNumber } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { id: userId, auth, isPromotionDisplayed, isImpersonating } = useAppSelector(
    (state) => state.me || ({} as any)
  );

  const handleContinue = () => {
    if (!isImpersonating && !isPromotionDisplayed) {
      const update = { isPromotionDisplayed: true };
      api.me.updateMyProfile(update).catch((err) => console.error(err));
      dispatch(setMyProfileProps({ ...update, showOffer: true }));
    }
    
    navigate(`/${id}/project`)
  }

  TagManager.dataLayer({
    dataLayer: GenerateGTMArgs("signedUpSuccess", {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
      auth_method: auth,
    }),
  });

  return (
    <VerificationLayout currentStep={7}>
      <HeaderContent
        handleBack={() => {}}
        headerText="Sign up complete!"
        headerVariant="h5"
        subHeaderText="You’re ready to start tracking your Freedom Solar project."
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={false}
        currentStep={7}
      />
      <MiddleContainer>
        <Box
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          <img
            src={SignupSuccessImage}
            alt="signup success"
            style={{ display: "block", height: "135px", margin: "0 auto" }}
          />
        </Box>
        <Box
          display={{
            xs: "block",
            sm: "none",
          }}
        >
          <img
            src={SignupSuccessMobileImage}
            alt="signup success"
            style={{ display: "block", height: "220px", margin: "0 auto" }}
          />
        </Box>
      </MiddleContainer>
      <BottomCtaContainer>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleContinue}
        >
          Continue
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
