import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import EmailIcon from "@mui/icons-material/Email";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const Icons = () => {
  return (
    <Box component={"section"} paddingY={10} paddingX={10}>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 1fr" },
          gap: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Small
          </Typography>
          <EmailIcon fontSize="small" color="primary" />
          <AccessTimeFilledIcon fontSize="small" />
          <VisibilityIcon fontSize="small" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Medium
          </Typography>
          <EmailIcon fontSize="medium" color="primary" />
          <AccessTimeFilledIcon fontSize="medium" />
          <VisibilityIcon fontSize="medium" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Large
          </Typography>
          <EmailIcon fontSize="large" color="primary" />
          <AccessTimeFilledIcon fontSize="large" />
          <VisibilityIcon fontSize="large" />
        </Box>
      </Box>
    </Box>
  );
};
