import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

export const CustomNavLink = forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive || props.forceActive ? props.activeClassName : ""}`
    }
    end={props.exact}
  >
    {props.children}
  </NavLink>
));
