import { API } from "aws-amplify";

export const get = (id: string, filter = {}) =>
  API.get("SECURED_API", `/portal/${id}/documents`, {
    queryStringParameters: filter
  });
export const download = (id: string, zip: boolean, files: any) =>
  API.post("SECURED_API", `/portal/${id}/documents/download`, {
    body: { docs: files },
    queryStringParameters: { zip }
  });
