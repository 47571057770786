import Stack from "@mui/material/Stack";

export const MiddleContainer = ({ children, mobileNoGap }: any) => {
  return (
    <Stack
      width="450px"
      maxWidth="100%"
      gap={6}
      flex={{
        xs: "auto",
        sm: "inherit",
      }}
      paddingX={{
        xs: 6,
        sm: 0,
      }}
      marginY={{
        xs: mobileNoGap ? "1.5rem" : 0,
        sm: 0,
      }}
      position="relative"
    >
      {children}
    </Stack>
  );
};
