import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { VerificationProgress } from "./verificationProgress";

export const HeaderContent = ({
  handleBack,
  headerText,
  headerVariant,
  subHeaderText,
  withMobileHeaderText,
  withMobileSubText,
  withMobileBack,
  currentStep,
}: any) => {
  return (
    <>
      <Stack
        direction="row"
        width={headerVariant === "h4" ? "431px" : "100%"}
        maxWidth="100%"
        alignItems="center"
        justifyContent={withMobileBack ? "space-between" : "center"}
        sx={{
          backgroundColor: "text.white",
        }}
        display={{
          xs: "flex",
          sm: "none",
        }}
        zIndex={2}
        position="sticky"
        top={0}
        padding={3}
        borderBottom={withMobileHeaderText ? "1px solid #E8EBED" : "none"}
      >
        <Stack
          direction="row"
          alignItems="center"
          visibility={withMobileBack ? "visible" : "hidden"}
        >
          <IconButton size="large" onClick={() => handleBack()}>
            <KeyboardArrowLeftIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        {withMobileHeaderText && (
          <Typography
            variant="subtitle1"
            color="textPrimary"
            fontWeight={600}
            display={{
              xs: "block",
              sm: "none",
            }}
          >
            {headerText}
          </Typography>
        )}

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            visibility: "hidden",
          }}
        >
          <IconButton size="large">
            <KeyboardArrowRightIcon fontSize="inherit" />
          </IconButton>
        </Stack>

        <Box
          sx={{
            position: "absolute",
            bottom: "-1px",
            left: 0,
            right: 0,
          }}
        >
          <VerificationProgress currentStep={currentStep} />
        </Box>
      </Stack>
      <Stack
        className="header"
        gap={3}
        width="515px"
        maxWidth="100%"
        alignItems="center"
        display={{
          xs: subHeaderText && withMobileSubText ? "flex" : "none",
          sm: "flex",
        }}
        paddingX={{
          xs: 6,
          sm: 0,
        }}
        flex={{ xs: withMobileHeaderText ? "inherit" : "auto", sm: "inherit" }}
        justifyContent={withMobileHeaderText ? "inherit" : "center"}
      >
        <Stack
          direction="row"
          width={headerVariant === "h4" ? "431px" : "100%"}
          maxWidth="100%"
          alignItems="center"
          justifyContent="center"
          display={{
            xs: "none",
            sm: "flex",
          }}
        >
          <Typography
            variant={headerVariant}
            color="textPrimary"
            fontWeight={600}
            display={{
              xs: "none",
              sm: "block",
            }}
          >
            {headerText}
          </Typography>
        </Stack>
        {subHeaderText && (
          <>
            <Typography
              variant={withMobileHeaderText ? "subtitle2" : "subtitle1"}
              color="textSecondary"
              textAlign="center"
              fontWeight={withMobileHeaderText ? 400 : 600}
              display={{
                xs: "block",
                sm: "none",
              }}
            >
              {subHeaderText}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              textAlign="center"
              display={{
                xs: "none",
                sm: "block",
              }}
            >
              {subHeaderText}
            </Typography>
          </>
        )}
      </Stack>
    </>
  );
};
