import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { gray, primary } from "../../theme/colors";
import { Stack } from "@mui/material";

import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

const stagesList = [
  {
    icon: "i-project-kickoff",
    heading: "Project Kickoff",
    estimatedDate: "",
    description:
      "We agree on the estimated cost and total production of your new solar energy system, and you upload key docs.",
    active: false,
    completed: false,
    completedDate: "",
  },
  {
    icon: "i-site-assessment",
    heading: "Site Assessment",
    estimatedDate: "",
    description:
      "We use our handy drone to analyze your property's solar energy production potential.",
    active: true,
    completed: false,
    completedDate: "",
  },
  {
    icon: "i-system-design",
    heading: "System Design",
    estimatedDate: "",
    description:
      "We use our handy drone to analyze your property's solar energy production potential.",
    active: false,
    completed: true,
    completedDate: "MM/DD/YYYY",
  },
  {
    icon: "i-applications-permits",
    heading: "Applications & Permits",
    estimatedDate: "",
    description:
      "We submit applications to your HOA, utility, and local government to confirm that your solar system will be fully compliant, safe, and reliable.",
    active: false,
    completed: false,
    completedDate: "",
  },
  {
    icon: "i-onsite-installation",
    heading: "On-Site Installation",
    estimatedDate: "",
    description:
      "We schedule a visit to your property to install your new system. This might take a few trips, depending on the design of your unique system.",
    active: false,
    completed: false,
    completedDate: "",
  },
  {
    icon: "i-thirdparty-inspections",
    heading: "Third-Party Inspections",
    estimatedDate: "",
    description:
      "We schedule any on-site inspections required by your local government or utility provider to confirm that your newly installed solar system meets all regulations for safety and reliability.",
    active: false,
    completed: false,
    completedDate: "",
  },
  {
    icon: "i-system-launch",
    heading: "System Launch",
    estimatedDate: "Month YYYY",
    description:
      "After your third-party inspections are complete, we confirm that you're ready to energize your new system so that you can start generating clean, low-cost power for your property.",
    active: false,
    completed: false,
    completedDate: "",
  },
];

export const Stages = () => {
  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {stagesList.map((stage, key) => (
          <Paper
            elevation={2}
            sx={{
              padding: "32px 16px",
              width: "305px",
              maxWidth: "100%",
              boxSizing: "border-box",
            }}
            key={`stage${key}`}
          >
            <Stack
              direction={"row"}
              alignItems={{ xs: "flex-start", md: "center" }}
              gap={4}
            >
              {stage.completed ? (
                <TaskAltRoundedIcon fontSize="medium" color="primary" />
              ) : (
                <i
                  className={stage.icon}
                  style={{
                    width: "1.5rem",
                    minWidth: "1.5rem",
                    height: "1.5rem",
                    color: stage.active ? primary[400] : gray[500],
                  }}
                />
              )}
              <Stack
                gap={{
                  xs: 2,
                  md: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: gray[800],
                    fontWeight: stage.active ? "600" : "400",
                  }}
                >
                  {stage.heading}
                </Typography>
                {stage.estimatedDate && !stage.completed && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: gray[500],
                    }}
                  >
                    Estimated: {stage.estimatedDate}
                  </Typography>
                )}

                {stage.completed && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: gray[500],
                    }}
                  >
                    Completed: {stage.completedDate}
                  </Typography>
                )}

                {!stage.completed && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: gray[700],
                    }}
                    display={{
                      sm: "block",
                      md: "none",
                    }}
                  >
                    {stage.description}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};
