import _ from "lodash";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { CustomDialog } from "../customDialog";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";

export const ChangePromptDialog = ({
  block,
  isLoading,
  onCLose,
  onConfirm,
  onSave
}: any) => {
  const [showPrompt, confirmNavigation, cancelNavigation]: any =
  useCallbackPrompt(block);

  const handleClose = async () => {
    if (isLoading) return;
    if (_.isFunction(onCLose)) {
      await onCLose()
    }

    cancelNavigation();    
  }

  const handleConfirm = async () => {
    if (_.isFunction(onConfirm)) {
      await onConfirm()
    }

    confirmNavigation();    
  }

  const handleSave = async () => {
    if (_.isFunction(onSave)) {
      await onSave()
    }

    confirmNavigation();    
  }

  return (
    <CustomDialog
        open={showPrompt}
        handleClose={handleClose}
        title="You have unsaved changes"
        content={
          <Typography variant="subtitle2" textAlign={"center"}>
            Are you sure you want to leave without saving?
          </Typography>
        }
        lCta={
          <Button
            variant="text"
            size="small"
            color="error"
            autoFocus
            disabled={isLoading}
            onClick={handleConfirm}
          >
            Leave without saving
          </Button>
        }
        rCta={
          <Button
            variant="contained"
            size="small"
            autoFocus
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save changes"}
          </Button>
        }
      />
  );
};
