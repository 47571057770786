import _ from "lodash";

import ConstructionIcon from "@mui/icons-material/Construction";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";

import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ForumIcon from "@mui/icons-material/Forum";
import HomeIcon from "@mui/icons-material/Home";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";

export const getCurrentProjectStage = (portal: any) => {
  const portalStages = ProjectNavData(portal);
  let currentStage: any = null;

  for (const stage of portalStages) {
    currentStage = stage;
    if (!_.get(portal, ["tracker", stage.key, "complete"], false)) break;
  }

  return currentStage;
};

export const MainNavData = (portal: any) => {
  const { id } = portal;

  return [
    {
      name: "Project",
      mobileTitle: "Project",
      to: `/${id}/project`,
      icon: <ConstructionIcon />,
      exact: true,
    },
    {
      name: "Documents",
      mobileTitle: "Documents",
      to: `/${id}/documents`,
      icon: <DescriptionIcon />,
      exact: true,
    },
    {
      name: "Account",
      mobileTitle: "Account Details",
      to: `/${id}/account`,
      icon: <SettingsIcon />,
      exact: true,
    },
    {
      name: "Support",
      mobileTitle: "Support",
      to: `/${id}/support`,
      icon: <HelpIcon />,
      exact: true,
    },
  ];
};

export const AccountNavData = (portal: any) => {
  const { id } = portal;

  return [
    {
      name: "Account Details",
      to: `/${id}/account`,
      icon: <PersonIcon />,
      exact: true,
    },
    {
      name: "Secondary Contact",
      to: `/${id}/account/secondary-contact`,
      icon: <GroupIcon />,
      exact: false,
    },
    {
      name: "Property Details",
      to: `/${id}/account/property-details`,
      icon: <HomeIcon />,
      exact: false,
    },
    {
      name: "HOA Details",
      to: `/${id}/account/hoa-details`,
      icon: <HolidayVillageIcon />,
      exact: false,
    },
    {
      name: "System Details",
      to: `/${id}/account/system-details`,
      icon: (
        <i
          className="i-project-kickoff"
          style={{
            width: "1.5rem",
            minWidth: "1.5rem",
            height: "1.5rem",
          }}
        />
      ),
      exact: false,
    },
  ];
};

export const MobileAccountNav = (portal: any) => {
  const { id } = portal;

  return [
    {
      name: "Personal Information",
      to: `/${id}/account/personal-information`,
      icon: <PersonIcon />,
      exact: false,
    },
    {
      name: "Change Password",
      to: `/${id}/account/change-password`,
      icon: <PersonIcon />,
      exact: false,
    },
  ];
};

export const ProjectNavData = (portal: any) => {
  const { id } = portal;

  return [
    {
      name: "Project Kickoff",
      to: `/${id}/project/kickoff`,
      exact: false,
      key: "projectKickoff",
    },
    {
      name: "Site Assessment",
      to: `/${id}/project/site-assessment`,
      exact: false,
      key: "siteAssessment",
    },
    {
      name: "System Design",
      to: `/${id}/project/system-design`,
      exact: false,
      key: "systemDesign",
    },
    {
      name: "Applications & Permits",
      to: `/${id}/project/applications-permits`,
      exact: false,
      key: "applicationsAndPermits",
    },
    {
      name: "On-Site Installation",
      to: `/${id}/project/on-site-installation`,
      exact: false,
      key: "onSiteInstallation",
    },
    {
      name: "Third-Party Inspections",
      to: `/${id}/project/third-party-inspections`,
      exact: false,
      key: "thirdPartyInspections",
    },
    {
      name: "System Launch",
      to: `/${id}/project/system-launch`,
      exact: false,
      key: "systemLaunch",
    },
  ];
};
