import { useState, useEffect } from "react";

import _ from "lodash";

import { Link } from "react-router-dom";
import LinkElment from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import LightModeIcon from "@mui/icons-material/LightMode";
import { primary, gray } from "../../theme/colors";
import CustomAlertBG from "../../assets/images/alert-exciting-bg.svg";
import { UnhappyBanner } from "../../components/shared/unhappyBanner";
import { getRedesignCopy } from "../../utils/redesignTypeCopy";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

export const Banners = () => {
  const dispatch = useAppDispatch();
  const { banners, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const { isImpersonating } = useAppSelector(
    (state) => state.me || ({} as any)
  );

  const [permanentBanner, setPermanentBanner] = useState(null) as any;
  const [dismissableBanner, setDismissableBanner] = useState(null) as any;

  useEffect(() => {
    const documentationNeededBanner = _.find(
      banners,
      (b) => b.type === "documentationNeeded"
    );
    const extendedProjectBanner = _.find(
      banners,
      (b) => b.type === "extendedProject"
    );
    const punchListBanner = _.find(banners, (b) => b.type === "punchList");

    setPermanentBanner(
      documentationNeededBanner ||
        extendedProjectBanner ||
        punchListBanner ||
        null
    );

    if (!extendedProjectBanner) {
      const dismissableBanner = _.find(banners, (b) => b.dismissable);
      setDismissableBanner(dismissableBanner);
    }
  }, [banners]);

  const resubmittalAlerts = {
    hoaResubmittal:
      "Your HOA application required a resubmittal. We’ve resubmitted your documents.",
    permitResubmittal:
      "Your Permit application required a resubmittal. We’ve resubmitted your documents.",
    utilityResubmittal:
      "Your Utility application required a resubmittal. We’ve resubmitted your documents.",
  };

  const extendedProjectBannersCopy = {
    "extended project new construction": {
      header: "New Construction",
      text: (
        <>
          You’ve let us know construction is in progress for at least 30
          calendar days. Please contact{" "}
          <LinkElment
            component={Link}
            to="mailto:customerservice@freedomsolarpower.com"
          >
            customerservice@freedomsolarpower.com
          </LinkElment>{" "}
          when you’re ready to resume your project.
        </>
      ),
    },
    "extended project remodel": {
      header: "Remodel",
      text: (
        <>
          Your installation is paused due to a remodel or renovation project.
          Please contact{" "}
          <LinkElment
            component={Link}
            to="mailto:customerservice@freedomsolarpower.com"
          >
            customerservice@freedomsolarpower.com
          </LinkElment>{" "}
          when you’re ready to resume your project.
        </>
      ),
    },
    "extended project re-roof": {
      header: "Roof Repair",
      text: (
        <>
          You let us know your roof needs to be repaired or replaced, so your
          installation is paused. Please contact{" "}
          <LinkElment
            component={Link}
            to="mailto:customerservice@freedomsolarpower.com"
          >
            customerservice@freedomsolarpower.com
          </LinkElment>{" "}
          when you’re ready to resume your project.
        </>
      ),
    },
    "extended project resume project": {
      header: "Project On Hold",
      text: (
        <>
          You let us know you need to pause your installation. Please contact{" "}
          <LinkElment
            component={Link}
            to="mailto:customerservice@freedomsolarpower.com"
          >
            customerservice@freedomsolarpower.com
          </LinkElment>{" "}
          when you’re ready to resume your project.
        </>
      ),
    },
    "extended project operations assistance": {
      header: "Project On Hold",
      text: (
        <>
          Your solar project is expected to be extended for 30+ days, due to an
          unusually complex permitting or utility process. It has been assigned
          to our extended project management team, who will reach out to you via
          email to coordinate the project.
        </>
      ),
    },
  };

  const closeAlert = (bannerKey: string) => {
    const newBanners = _.filter(banners, (x) => x.bannerKey !== bannerKey);

    dispatch(setPortalProps({ banners: newBanners }));

    if (!isImpersonating) {
      api.me
        .updateMyProfile({ dismissBanner: bannerKey })
        .catch((err) => console.error(err));
    }
  };

  if (_.isEmpty(banners)) return <></>;

  return (
    <Stack direction="column" gap={2}>
      {permanentBanner && permanentBanner.type === "extendedProject" && (
        <UnhappyBanner
          variant="danger"
          key={permanentBanner.bannerKey}
          title="Extended Project"
          subtitle={_.get(
            extendedProjectBannersCopy,
            [_.get(permanentBanner, "textKey", ""), "header"],
            "--"
          )}
          desc={_.get(
            extendedProjectBannersCopy,
            [_.get(permanentBanner, "textKey", ""), "text"],
            "--"
          )}
        />
      )}

      {permanentBanner && permanentBanner.type === "punchList" && (
        <UnhappyBanner
          key={permanentBanner.bannerKey}
          variant="info"
          title="Return visit required to complete your installation"
          desc="While performing the quality assurance check for your installation, our team identified the need for a return visit in order to complete your installation. We will reach out to schedule the return visit."
        />
      )}

      {permanentBanner && permanentBanner.type === "documentationNeeded" && (
        <UnhappyBanner
          key={permanentBanner.bannerKey}
          variant="info"
          title="Documentation Needed"
          desc={
            <>
              Your project is currently pending. Please reach out to{" "}
              {`${permanentBanner?.first_name} ${permanentBanner?.last_name}`}{" "}
              at{" "}
              <LinkElment
                component={Link}
                to={`mailto:${permanentBanner?.email}`}
              >
                {`${permanentBanner?.email}`}
              </LinkElment>{" "}
              to provide missing or incomplete documentation.
            </>
          }
        />
      )}

      {dismissableBanner && dismissableBanner.type === "reDesign" && (
        <UnhappyBanner
          key={dismissableBanner.bannerKey}
          variant="info"
          title={getRedesignCopy(dismissableBanner)?.title}
          desc={getRedesignCopy(dismissableBanner)?.description}
          cta={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                closeAlert(dismissableBanner.bannerKey);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}

      {dismissableBanner && dismissableBanner.type === "reDesign" && (
        <UnhappyBanner
          key={dismissableBanner.bannerKey}
          variant="info"
          title={getRedesignCopy(dismissableBanner)?.title}
          desc={getRedesignCopy(dismissableBanner)?.description}
          cta={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                closeAlert(dismissableBanner.bannerKey);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}

      {dismissableBanner &&
        dismissableBanner.type === "failedCityInspections" && (
          <UnhappyBanner
            key={dismissableBanner.bannerKey}
            variant="dangerT"
            title="City reinspection needed"
            desc="The city inspector has identified an issue that will require a reinspection. We will coordinate with your city permitting office to schedule a new inspection."
            cta={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => {
                  closeAlert(dismissableBanner.bannerKey);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        )}

      {dismissableBanner &&
        dismissableBanner.type === "failedUtilityInspections" && (
          <UnhappyBanner
            key={dismissableBanner.bannerKey}
            variant="dangerT"
            title="Utility reinspection needed"
            desc="The utility inspector has identified an issue that will require a reinspection. We will coordinate with your utility to schedule a new inspection."
            cta={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => {
                  closeAlert(dismissableBanner.bannerKey);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        )}

      {dismissableBanner &&
        dismissableBanner.type === "siteAssessmentAlert" && (
          <Alert
            key={dismissableBanner.bannerKey}
            icon={<LightModeIcon />}
            onClose={() => {
              closeAlert(dismissableBanner.bannerKey);
            }}
            sx={{
              width: "100%",
              background: {
                sm: `url(${CustomAlertBG}) no-repeat center right`,
              },
              backgroundSize: "contain",
              backgroundColor: { xs: primary[400], sm: primary[400] },
              borderColor: primary[400],
              "& .MuiAlert-message": { color: gray[0] },
              "& .MuiAlert-icon": { color: primary[200] },
              "& > div:last-child button": {
                color: gray[900],
              },
              borderRadius: {
                xs: 0,
                sm: "8px",
              },
            }}
          >
            <AlertTitle
              sx={{
                color: gray[0],
              }}
            >
              Your site assessment is today at{" "}
              {_.get(dismissableBanner, "time", "--")}!
            </AlertTitle>
            Please ensure that you are on site and remember to read through the
            on-site visit reminders.
          </Alert>
        )}

      {dismissableBanner &&
        dismissableBanner.type === "systemLaunchMeetingAlert" && (
          <Alert
            key={dismissableBanner.bannerKey}
            icon={<LightModeIcon />}
            onClose={() => {
              closeAlert(dismissableBanner.bannerKey);
            }}
            sx={{
              width: "100%",
              background: {
                sm: `url(${CustomAlertBG}) no-repeat center right`,
              },
              backgroundSize: "contain",
              backgroundColor: { xs: primary[400], sm: primary[400] },
              borderColor: primary[400],
              "& .MuiAlert-message": { color: gray[0] },
              "& .MuiAlert-icon": { color: primary[200] },
              "& > div:last-child button": {
                color: gray[900],
              },
              borderRadius: {
                xs: 0,
                sm: "8px",
              },
            }}
          >
            <AlertTitle
              sx={{
                color: gray[0],
              }}
            >
              Your launch meeting is today at{" "}
              {_.get(dismissableBanner, "time", "--")}!
            </AlertTitle>
            {isBatteryOnly
              ? "We’re looking forward to discussing your new battery with you!"
              : "We’re looking forward to discussing your solar system with you!"}
          </Alert>
        )}

      {dismissableBanner &&
        ["hoaResubmittal", "permitResubmittal", "utilityResubmittal"].includes(
          dismissableBanner.type
        ) && (
          <Alert
            key={dismissableBanner.bannerKey}
            severity="info"
            onClose={() => {
              closeAlert(dismissableBanner.bannerKey);
            }}
            sx={{
              borderRadius: {
                xs: 0,
                sm: "8px",
              },
            }}
          >
            <AlertTitle>Application resubmitted</AlertTitle>
            {_.get(resubmittalAlerts, [dismissableBanner.type], "--")}
          </Alert>
        )}
    </Stack>
  );
};
