export const primary = {
  25: "#FFFCFA",
  50: "#FFF6F0",
  100: "#FFEDE0",
  200: "#FFD5B8",
  300: "#FDA568",
  400: "#F98A3C",
  500: "#D96412",
  600: "#AE4E0A",
  700: "#873C08",
  800: "#602B06",
  900: "#3A1A03",
};

export const gray = {
  0: "#FFFFFF",
  25: "#F9F9FB",
  50: "#F1F2F4",
  100: "#E8EBED",
  200: "#DCE0E4",
  300: "#D4D9DE",
  400: "#B4BDC5",
  500: "#8C9AA6",
  600: "#5E6E7D",
  700: "#3D4D5C",
  800: "#252F38",
  900: "#0E1215",
};

export const error = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#F04438",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A",
};

export const warning = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#B54708",
  800: "#93370D",
  900: "#7A2E0E",
};

export const success = {
  25: "#F6FEF9",
  50: "#ECFDF3",
  100: "#D1FADF",
  200: "#A6F4C5",
  300: "#6CE9A6",
  400: "#32D583",
  500: "#12B76A",
  600: "#039855",
  700: "#027A48",
  800: "#05603A",
  900: "#054F31",
};
