import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface FAQ {
  groupId: string;
  groupName: string;
  question: string;
  answer: string;
  link: boolean;
  isProjectStageVisible: boolean;
}

interface GroupedFAQ {
  id: string;
  title: string;
  faqs: FAQ[];
}

const initialState: GroupedFAQ[] = [];

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFaq: (state, action: PayloadAction<GroupedFAQ[]>) => {
      return action.payload;
    },
  },
});

export const { setFaq } = faqSlice.actions;

export default faqSlice.reducer;
