import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const Buttons = () => {
  return (
    <Box component={"section"} paddingY={10} paddingX={10}>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr 1fr" },
          gap: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Small
          </Typography>
          <Button variant="outlined" size="small">
            Label
          </Button>
          <Button variant="contained" size="small">
            Label
          </Button>
          <Button variant="text" size="small">
            Label
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Medium
          </Typography>
          <Button variant="outlined">Label</Button>
          <Button variant="contained">Label</Button>
          <Button variant="text">Label</Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Large
          </Typography>
          <Button variant="outlined" size="large">
            Label
          </Button>
          <Button variant="contained" size="large">
            Label
          </Button>
          <Button variant="text" size="large">
            Label
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Disabled
          </Typography>
          <Button variant="outlined" disabled>
            Label
          </Button>
          <Button variant="contained" disabled>
            Label
          </Button>
          <Button variant="text" disabled>
            Label
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            position: "relative",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            position={"absolute"}
            bottom={"100%"}
            fontWeight={700}
          >
            Loading
          </Typography>
          <Button variant="outlined" disabled>
            <CircularProgress color="inherit" size={16} thickness={4.5} />
            Label
          </Button>
          <Button variant="contained" disabled>
            <CircularProgress color="inherit" size={16} thickness={4.5} />
            Label
          </Button>
          <Button variant="text" disabled>
            <CircularProgress color="inherit" size={16} thickness={4.5} />
            Label
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
