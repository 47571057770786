import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import _ from "lodash";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import { ContainerLayout } from "../../components/account/containerLayout";
import { ChangePromptDialog } from "../../components/shared/dialogs/changePrompt";
import * as api from "../../api";
import { PasswordRequirements } from "../../components/shared/passwordRequirements";

const defaultData = {
  old_password: "",
  password: "",
  confirm_password: "",
};

const errorDefault = {
  password: {
    characters: true,
    upperCases: true,
    numbers: true,
    specialCharacters: true,
  },
  confirm_password: "",
};

export const AccountPassword = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(defaultData);
  const [errors, setErrors] = useState(errorDefault);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [toastShowing, setToastShowing] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, formData.old_password, formData.password);

      api.me
        .updateMyProfile({ passwordChanged: true })
        .catch((err) => console.error(err));

      setToastShowing(true);
      enqueueSnackbar("Password updated successfully", {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error: any) {
      const msg = error.message
        ? "Incorrect password."
        : "Failed to update password. Please try again!";
      setToastShowing(true);
      enqueueSnackbar(msg, {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
      setEdit(false);
      setFormData(defaultData);
    }
  };

  useEffect(() => {
    const { password, confirm_password } = formData;

    const characterPattern = /^.{8,}$/;
    const upperCasePattern = /^(?=.*[A-Z]).+$/;
    const numberPattern = /\d/;
    const specialCharacterPattern = /[^a-zA-Z0-9]/;

    const debounce = setTimeout(() => {
      let errPass = {
          characters: true,
          upperCases: true,
          numbers: true,
          specialCharacters: true,
        },
        errConfirmPass = "",
        withError = false;

      if (password === "" || confirm_password === "") {
        withError = true;
      }

      if (password !== "") {
        let passCount = 0;

        if (characterPattern.test(password)) {
          errPass["characters"] = false;
          passCount++;
        }

        if (upperCasePattern.test(password)) {
          errPass["upperCases"] = false;
          passCount++;
        }

        if (numberPattern.test(password)) {
          errPass["numbers"] = false;
          passCount++;
        }

        if (specialCharacterPattern.test(password)) {
          errPass["specialCharacters"] = false;
          passCount++;
        }

        if (passCount !== 4) {
          withError = true;
        }
      }

      if (password !== confirm_password && confirm_password !== "") {
        errConfirmPass = "Passwords do not match";
        withError = true;
      }

      setErrors({
        password: { ...errors.password, ...errPass },
        confirm_password: errConfirmPass,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  useEffect(() => {
    const { old_password, password, confirm_password } = formData;
    setHasChanges(
      !_.isEmpty(old_password) ||
        !_.isEmpty(password) ||
        !_.isEmpty(confirm_password)
    );
  }, [formData]);

  return (
    <ContainerLayout
      headerText="Password"
      cta={
        edit ? (
          <>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={() => setEdit(false)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={isLoading}
            >
              <Typography variant="button" color="secondary.light">
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleUpdate()}
              disabled={isLoading || isDisabled}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              {isLoading && (
                <CircularProgress color="inherit" size={16} thickness={4.5} />
              )}
              Update
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setEdit(true)}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={toastShowing}
            >
              Change password
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setEdit(true)}
              sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                },
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={toastShowing}
            >
              Edit
            </Button>
          </>
        )
      }
      withMobileBack
    >
      <Stack
        gap={9}
        direction="row"
        flexWrap="wrap"
        sx={{
          visibility: {
            xs: "visible",
            sm: edit ? "visible" : "hidden",
          },
          display: {
            xs: "flex",
            sm: edit ? "flex" : "none",
          },
        }}
      >
        <Box width={{ xs: "100%" }}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: "100%", sm: "calc(50% - 1.25rem)" } }}
          >
            <TextField
              label="Current password"
              type={showOldPassword ? "text" : "password"}
              name="old_password"
              value={formData.old_password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
        </Box>
        <Stack width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }} gap={2}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              error={errors.confirm_password !== "" ? true : false}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
          <PasswordRequirements errors={errors} />
        </Stack>
        <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Confirm password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              error={errors.confirm_password !== "" ? true : false}
              helperText={errors.confirm_password}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
        </Box>
      </Stack>
      <ChangePromptDialog
        block={hasChanges}
        isLoading={isLoading}
        onSave={handleUpdate}
      />
    </ContainerLayout>
  );
};
