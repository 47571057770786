import { API } from "aws-amplify";

interface IUpdateUserPayload {
  lastVisitedPortal?: string;
  passwordChanged?: boolean;
  dismissBanner?: string;
  isPromotionDisplayed?: boolean;
}

export const myProfile = () => API.get("SECURED_API", "/myprofile", {});
export const updateMyProfile = (body: IUpdateUserPayload) =>
  API.patch("SECURED_API", "/myprofile", { body });
