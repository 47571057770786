import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LinkElment from "@mui/material/Link";

export const ErrorState = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" gap={1}>
      <Typography variant="h5" fontWeight={600} textAlign="center" paddingX="16px">
        Look’s like the power’s out.
      </Typography>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        sx={{
          width: "100%",
          maxWidth: "696px",
          paddingX: "16px"
        }}
      >
        We’re currently trying to fix the problem. In the meantime you can{" "}
        <LinkElment href="mailto:customerservice@freedomsolarpower.com">
          contact support
        </LinkElment>{" "}
        , wait a few minutes, or refresh the page.
      </Typography>
    </Stack>
  );
};
