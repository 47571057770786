import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";
import { DashboardLayout } from "../../components/dashboard/dashboardLayout";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";
import useFaq from "../../hooks/useFAQ";

const accordionStyle = {
  background: "transparent",
  "&::before": {
    display: "none",
  },
  "& .MuiButtonBase-root": {
    padding: 0,
    minHeight: "unset",
    width: "100%",
    justifyContent: "flex-start",
    gap: 4,
  },
  "& .MuiAccordionSummary-content": {
    flex: "unset",
    margin: 0,
    gap: 4,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#B4BDC5",
  },
};

export const FAQs = () => {
  const [selected, setSelected] = useState("");
  const [activeID, setActiveID] = useState("general");
  const navigate = useNavigate();
  const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
  const mobileNav = useRef<HTMLDivElement | null>(null);
  const { faqsData } = useFaq();

  const onPress = (id: string, mobile: boolean = false) => {
    const target = window.document.getElementById(id);
    if (target) {
      var headerOffset = mobile ? 140 : 20;
      var elementPosition = target.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      setSelected(id);

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onScroll = (id: string) => {
    setActiveID(id);
  };

  const nav = (mobile: boolean = false) => {
    return (
      <Stack
        gap={4}
        sx={{
          background: { xs: "#ffffff", sm: "transparent" },
          padding: { xs: "1.5rem", sm: 0 },
        }}
      >
        {faqsData.map((faqData, key) => (
          <Box
            onClick={() => onPress(faqData.id, mobile)}
            key={`faqNav${key}`}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography
              color={activeID === faqData.id ? "primary" : "textPrimary"}
              sx={{
                fontWeight: activeID === faqData.id ? 600 : 400,
              }}
              data-to-scrollspy-id={faqData.id}
            >
              {faqData.title}
            </Typography>
          </Box>
        ))}
      </Stack>
    );
  };

  const closeMobileNav = () => {
    mobileNav.current?.click();
  };

  useEffect(() => {
    const active = setTimeout(() => {
      if (selected !== "" && selected !== activeID) {
        setActiveID(selected);
        setSelected("");
      }
    }, 200);

    return () => clearTimeout(active);
  }, [selected, activeID]);

  return (
    <DashboardLayout>
      <Stack direction={{ xs: "column", sm: "row" }} gap={{ xs: 0, sm: 6 }}>
        <Box display={{ xs: "none", sm: "block" }}>
          <Stack
            padding={6}
            gap={6}
            sx={{
              minWidth: "255px",
              height: "max-content",
              position: "sticky",
              top: "2.5rem",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => navigate(-1)}
            >
              <IconButton size="large">
                <KeyboardArrowLeftIcon fontSize="inherit" />
              </IconButton>
            </Stack>
            {nav()}
          </Stack>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            backgroundColor: "#F1F2F4",
            borderBottom: "1px solid #E8EBED",
            position: "sticky",
            top: "70px",
            zIndex: 2,
          }}
        >
          <Accordion
            disableGutters
            elevation={0}
            square
            sx={{
              background: "transparent",
              padding: "1rem 1.5rem",
              "&::before": {
                display: "none",
              },
              "& .MuiButtonBase-root": {
                padding: 0,
                minHeight: "unset",
                width: "100%",
                justifyContent: "flex-start",
                gap: 4,
              },
              "& .MuiAccordionSummary-content": {
                flex: "unset",
                margin: 0,
                gap: 4,
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: "#B4BDC5",
              },
              "& .MuiCollapse-root": {
                position: "absolute",
                background: "rgba(14, 18, 21, 0.5)",
                left: 0,
                right: 0,
                top: "calc(100% + 1px)",
              },
            }}
          >
            <AccordionSummary
              ref={mobileNav}
              sx={{
                alignItems: "flex-start",
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography
                variant="subtitle2"
                color="textPrimary"
                fontWeight={600}
              >
                Categories
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              onClick={() => closeMobileNav()}
              sx={{
                height: "100dvh",
                padding: 0,
              }}
            >
              {nav(true)}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Paper
          elevation={0}
          sx={{
            flex: "1",
            padding: { xs: "0.25rem 1.5rem", sm: "1.5rem 2rem" },
            borderRadius: { xs: 0, sm: "8px" },
          }}
          ref={parentScrollContainerRef}
        >
          <Typography
            variant="h6"
            color="textPrimary"
            fontWeight={600}
            marginY={6}
            display={{ xs: "none", sm: "block" }}
          >
            Frequently Asked Questions
          </Typography>
          <ScrollSpy
            scrollThrottle={80}
            useBoxMethod
            onUpdateCallback={(id) => onScroll(id)}
            updateHistoryStack={false}
          >
            {faqsData.map((faqData, key) => (
              <Stack gap={4} paddingY={5} key={`faqData${key}`} id={faqData.id}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  fontWeight={600}
                >
                  {faqData.title}
                </Typography>
                <Stack gap={2}>
                  {faqData.faqs.map((faq, key) =>
                    faq.link ? (
                      <Link
                        href={faq.answer}
                        target="_blank"
                        key={`faq${key}`}
                        color="textSecondary"
                        sx={{
                          textDecoration: "none",
                        }}
                      >
                        {faq.question}
                      </Link>
                    ) : (
                      <Accordion
                        disableGutters
                        elevation={0}
                        square
                        sx={accordionStyle}
                        key={`faq${key}`}
                      >
                        <AccordionSummary
                          sx={{
                            alignItems: "flex-start",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography variant="body1" color="textSecondary">
                            {faq.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0.5rem 0.5rem 0 1.5rem" }}
                        >
                          <Typography
                            color="textSecondary"
                            sx={{ ul: { margin: 0 } }}
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </Stack>
              </Stack>
            ))}
          </ScrollSpy>
        </Paper>
      </Stack>
    </DashboardLayout>
  );
};
