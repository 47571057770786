import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

export const LinkList = ({ listData }: any) => {

  return (
    <Stack gap={2}>
        {
            listData.map((item: any, key: any) => 
                <Link
                    key={`item${key}`}
                    href={item.link}
                    target="_blank"
                    color="textSecondary"
                    sx={{textDecoration: "none"}}
                >
                    {item.title}
                </Link> 
            )
        }
    </Stack>
  );
};