import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Buffer } from "buffer";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { isSessionAuthenticated, setImpersonate, removeImpersonate } from "../services/auth";
import { useAppDispatch } from "../store/hook";
import { logout } from "../store/modules/meSlice";

export const ImpersonateLanding = () => {
  const dispatch = useAppDispatch();
  const { code } = useParams();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const init = async () => {
      const isLoggedIn = await isSessionAuthenticated();

      if (isLoggedIn) {
        await Auth.signOut();
        removeImpersonate();
        dispatch(logout());
      }

      try {
        let redirect = '/';
        if (code) {
          const codestr = Buffer.from(code, 'base64').toString('utf-8');
          const [username, authCode, portalId] = codestr.split(';');
          const tempUser = await Auth.signIn({ username } as any);
          await Auth.sendCustomChallengeAnswer(tempUser, authCode);
          await Auth.currentSession();
          const user = await Auth.currentAuthenticatedUser();
          const impersonateChallenge = user.attributes['custom:impersonateChallenge'];
          setImpersonate(portalId, impersonateChallenge);

          redirect = `/${portalId}/project`;
        }
        
        
        window.location.replace(redirect);
      } catch (error: any) {
        console.error(error)
        setIsError(true)
      }
    }
    init();
  }, []);

  if (isError) {
    return <Stack sx={{
      minHeight: "100dvh",
    }} alignItems="center" justifyContent="center" gap={1}>
    <Typography variant="h5" fontWeight={600} textAlign="center" paddingX="16px">
      Invalid impersonate request.
    </Typography>
  </Stack>;
  }

  return (
    <Stack
    sx={{
      minHeight: "100dvh",
    }}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={60} />
  </Stack>
  );
};
