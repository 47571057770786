import Stack from "@mui/material/Stack";
import { ErrorState } from "../components/shared/errorState";

export const NotFound = () => {
  return (
    <Stack
      sx={{
        minHeight: "100dvh",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <ErrorState/>
    </Stack>
  );
};
