import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

export const CustomTooltip = ({
  title,
  placement = "bottom",
  customClass = "",
  maxWidth,
  isDark,
  children,
}: any) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Tooltip
      title={title}
      placement={placement}
      enterTouchDelay={0}
      leaveDelay={isMobile ? 2500 : 0}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth,
            whiteSpace: "pre-wrap",
          },
        },
      }}
      classes={{
        tooltip: isDark ? "dark" : "",
        arrow: isDark ? "dark" : "",
      }}
      className={customClass}
    >
      {children}
    </Tooltip>
  );
};
