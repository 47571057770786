import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import _ from "lodash";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import DialpadIcon from "@mui/icons-material/Dialpad";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { VerificationLayout } from "../../components/signup/verificationLayout";
import { HeaderContent } from "../../components/signup/headerContent";
import { MiddleContainer } from "../../components/signup/middleContainer";
import { BottomCtaContainer } from "../../components/signup/bottomCtaContainer";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setMyProfileProps } from "../../store/modules/meSlice";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultValue = {
  gateCode: "",
};

export const PropertyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, propertyDetails } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (propertyDetails) {
      setFormData({
        gateCode: _.get(propertyDetails, "gateCode", "") || "",
      });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const nextPage = "/onboarding/wifi-details";

    if (_.isEqual(formData.gateCode, propertyDetails.gateCode)) {
      handleSkip(false);
      return;
    }

    setIsLoading(true);
    try {
      const payload: any = {
        propertyDetails: {
          ...propertyDetails,
          ...formData,
        },
        onboardingStep: nextPage,
      };
      await api.portal.update(id, payload);

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("accCreationPage6", {
          gate_code_added: formData.gateCode,
          page6_continue_clicked: true,
          page6_no_gate_code_clicked: false,
          page6_skip_clicked: false,
        }),
      });

      dispatch(setPortalProps(payload));
      navigate(nextPage);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Failed to update property details. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  const handleSkip = (noGateCode: boolean) => {
    const onboardingUpdate = { onboardingStep: "/onboarding/wifi-details" };
    api.portal.update(id, onboardingUpdate).catch((err) => console.error(err));

    TagManager.dataLayer({
      dataLayer: GenerateGTMArgs("accCreationPage6", {
        gate_code_added: formData.gateCode,
        page6_continue_clicked: false,
        page6_no_gate_code_clicked: noGateCode,
        page6_skip_clicked: noGateCode ? false : true,
      }),
    });

    dispatch(setMyProfileProps(onboardingUpdate));
    navigate("/onboarding/wifi-details");
  };

  return (
    <VerificationLayout
      currentStep={4}
      withBackButton={true}
      handleBack={() => navigate("/onboarding/second-contact")}
    >
      <HeaderContent
        handleBack={() => navigate("/onboarding/second-contact")}
        headerText="Enter your property details"
        headerVariant="h5"
        withMobileHeaderText={true}
        withMobileSubText={true}
        withMobileBack={true}
        currentStep={4}
      />
      <MiddleContainer>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Gate code"
            type="text"
            id="gate-code-start-adornment"
            name="gateCode"
            value={formData.gateCode}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DialpadIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <Box
          sx={{
            padding: "1.5rem",
            width: "295px",
            border: "1px solid #FFEDE0",
            background: "#FFF6F0",
            top: 0,
            left: "calc(100% + 3rem)",
            borderRadius: "8px",
          }}
          position={{
            xs: "static",
            lg: "absolute",
          }}
          marginX="auto"
        >
          <Stack gap={4}>
            <Typography
              variant="body2"
              textAlign="center"
              color={"primary"}
              sx={{
                fontWeight: `700 !important`,
              }}
            >
              Why are we asking for your property details?
            </Typography>
            <Typography
              variant="body2"
              textAlign="center"
              color={"textPrimary"}
            >
              Entering your gate code enables our technicians to get started on
              your project right away.
            </Typography>
          </Stack>
        </Box>
      </MiddleContainer>
      <BottomCtaContainer>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => handleSkip(true)}
        >
          I don’t have a gate code
        </Button>
        <Button variant="text" onClick={() => handleSkip(false)}>
          I’ll do this later
        </Button>
      </BottomCtaContainer>
    </VerificationLayout>
  );
};
