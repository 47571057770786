import _ from "lodash";
import moment from "moment";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import ForestIcon from "@mui/icons-material/Forest";
import RecyclingIcon from "@mui/icons-material/Recycling";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { ContainerLayout } from "../../components/account/containerLayout";
import { CustomTooltip } from "../../components/shared/customTooltip";
import { useAppSelector } from "../../store/hook";

export const SystemDetail = ({ label, labelInfo, value, subValue }: any) => {
  return (
    <Stack width={{ xs: "100%", sm: "calc(50% - 1.5rem)" }} gap={2}>
      <Typography
        variant="subtitle2"
        color="text.light"
        display="flex"
        alignItems="center"
        gap={2}
      >
        {label}
        {labelInfo && (
          <CustomTooltip title={labelInfo} maxWidth={{ xs: "158px" }} isDark>
            <InfoOutlinedIcon fontSize="small" />
          </CustomTooltip>
        )}
      </Typography>
      <Stack>
        {value}
        {subValue && (
          <Typography variant="body2" color="text.light">
            {subValue}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const SystemImpack = ({ icon, label, value }: any) => {
  return (
    <Stack
      direction="column"
      alignItems={{ sm: "center" }}
      justifyContent="center"
      gap={4}
      width={{ xs: "100%", sm: "calc(33.33% - 1rem)" }}
    >
      <Typography color="primary">{icon}</Typography>
      <Stack textAlign={{ sm: "center" }}>
        <Typography variant="subtitle2" color="text.dark" fontWeight={600}>
          {value}
        </Typography>
        <Typography variant="subtitle2" color="text.light">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const AccountSystemDetails = () => {
  const { id, systemDetails, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  /**
   * carbon = Math.round(production * 1.5588)
   * miles = Math.round(production * 1.7544)
   * trees = Math.round(production * 0.0177)
   * coal = Math.round(production * 0.7791)
   * water = Math.round(production * 0.3)
   */

  const formatNumber = (n: any) => {
    const val = Math.round(Number(n) * 100) / 100;
    const parts = val.toString().split(".");
    const num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  const displayAmount = () => {
    const amount = _.get(systemDetails, "amount", null);
    if (!amount) return "-";
    const num = formatNumber(amount);
    return `$${num}`;
  };

  const displayYearlyProduction = () => {
    const production = _.get(systemDetails, "yearlyProduction", null);
    if (!production) return "-";
    const num = formatNumber(production);

    return `${num} kWh`;
  };

  const displayTreesImpact = () => {
    let production = _.get(systemDetails, "yearlyProduction", null);
    if (!production) return "-";
    production = parseFloat(production);

    const n = Math.round(production * 0.0177);
    const num = formatNumber(n);
    return num;
  };

  const displayCoalImpact = () => {
    let production = _.get(systemDetails, "yearlyProduction", null);
    if (!production) return "-";
    production = parseFloat(production);

    const n = Math.round(production * 0.7791);
    const num = formatNumber(n);
    return `${num} lbs`;
  };

  const displayCarbonImpact = () => {
    let production = _.get(systemDetails, "yearlyProduction", null);
    if (!production) return "-";
    production = parseFloat(production);

    const n = Math.round(production * 0.000245);
    const num = formatNumber(n);
    return `${num} tons`;
  };

  const displayLoanExpirationDate = () => {
    const expirationDate = _.get(systemDetails, "loanExpirationDate", null);
    if (!expirationDate) return "";
    const num = parseInt(expirationDate);
    const date = moment(num);
    return date.isValid() ? `Expires ${date.format("MM/DD/YYYY")}` : "";
  };

  return (
    <>
      <ContainerLayout headerText="System Details">
        <Stack gap={6} direction="row" flexWrap="wrap">
          {!isBatteryOnly && (
            <>
              <SystemDetail
                label="Panel type"
                value={
                  <Typography variant="subtitle2" color="text.primary">
                    {_.get(systemDetails, "panelType", "-")}
                  </Typography>
                }
              />
              <SystemDetail
                label="Number of panels"
                value={
                  <Typography variant="subtitle2" color="text.primary">
                    {_.get(systemDetails, "panelQuantity", "-")}
                  </Typography>
                }
              />
            </>
          )}

          <SystemDetail
            label="Monitoring system"
            value={
              <Typography variant="subtitle2" color="text.primary">
                {_.get(systemDetails, "monitoringDevice", "-")}
              </Typography>
            }
          />
          {_.get(systemDetails, "monitoringDevice", null) !==
            "Enphase IQ Gateway" &&
            !isBatteryOnly && (
              <SystemDetail
                label="Warranty number"
                value={
                  <Typography variant="subtitle2" color="text.primary">
                    {_.get(systemDetails, "warrantyNumber", "-")}
                  </Typography>
                }
              />
            )}

          {!isBatteryOnly && (
            <SystemDetail
              label="System size DC"
              labelInfo="Panels convert sunlight into DC electricity, which is then inverted into usable AC power"
              value={
                <Typography variant="subtitle2" color="text.primary">
                  {_.get(systemDetails, "systemSize", "-")} kW
                </Typography>
              }
            />
          )}
          <SystemDetail
            label="Tesla powerwalls"
            value={
              <Typography variant="subtitle2" color="text.primary">
                {_.get(systemDetails, "teslaPowerwallsQuantity", "-")}
              </Typography>
            }
          />
        </Stack>
      </ContainerLayout>
      <Divider />
      <ContainerLayout headerText="System Cost" withMobileHeaderText>
        <Stack gap={6} direction="row" flexWrap="wrap">
          <SystemDetail
            label="Total cost"
            value={
              <Typography variant="subtitle2" color="text.primary">
                {displayAmount()}
              </Typography>
            }
          />
          <SystemDetail
            label="Lender"
            value={
              <Typography variant="subtitle2" color="text.primary">
                {_.get(systemDetails, "lender", "-")}
              </Typography>
            }
            subValue={displayLoanExpirationDate()}
          />
        </Stack>
      </ContainerLayout>
      {!isBatteryOnly && (
        <>
          <Divider />
          <ContainerLayout headerText="Positive Impact" withMobileHeaderText>
            <Stack gap={6} direction="row" flexWrap="wrap">
              <SystemDetail
                label="Estimated annual generation"
                value={
                  <Typography variant="subtitle2" color="text.primary">
                    {displayYearlyProduction()}
                  </Typography>
                }
              />
              <Typography
                variant="subtitle2"
                color="text.light"
                display="flex"
                alignItems="center"
                gap={2}
              >
                The impact your solar system will have every year is equivalent
                to:
              </Typography>
            </Stack>
            <Stack width="100%" gap={6} direction="row" flexWrap="wrap">
              <SystemImpack
                icon={<ForestIcon color="primary" fontSize="large" />}
                label="trees planted"
                value={displayTreesImpact()}
              />
              <SystemImpack
                icon={
                  <i
                    className="i-project-kickoff"
                    style={{
                      width: "2.5rem",
                      minWidth: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                }
                label="of coal not burned"
                value={displayCoalImpact()}
              />
              <SystemImpack
                icon={<RecyclingIcon color="primary" fontSize="large" />}
                label="of waste recycled"
                value={displayCarbonImpact()}
              />
            </Stack>
          </ContainerLayout>
        </>
      )}
    </>
  );
};
