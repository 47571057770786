import { Outlet } from "react-router-dom";

import { DashboardLayout } from "../../components/dashboard/dashboardLayout";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { AccountSidebar } from "../../components/account/accountSidebar";

export const Account = () => {
  return (
    <DashboardLayout>
      <Paper
        sx={{
          display: "flex",
          flex: "auto",
          borderRadius: {
            xs: 0,
            sm: "8px",
          },
          boxShadow: {
            xs: 0,
            sm: 3,
          },
        }}
      >
        <Stack
          p={6}
          minWidth={"290px"}
          display={{
            xs: "none",
            sm: "flex",
          }}
        >
          <AccountSidebar />
        </Stack>
        <Divider
          orientation="vertical"
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
          flexItem
        />
        <Stack
          width={"100%"}
          flex="auto"
          sx={{
            maxHeight: { xs: "unset", sm: "calc(100dvh - 5rem)" },
            overflow: { xs: "unset", sm: "auto" },
          }}
        >
          <Outlet />
        </Stack>
      </Paper>
    </DashboardLayout>
  );
};
