import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";
import { useAppSelector } from "../../store/hook";

export const EventWrapper = ({ children }: any) => {
  const { id } = useAppSelector((state) => state.me || ({} as any));
  const location = useLocation();
  const { portalId } = useParams();

  useEffect(() => {
    const start: Date = new Date();
    const path = portalId
      ? location.pathname.replace(`/${portalId}`, "")
      : location.pathname;

    var buttons: any = document.getElementsByTagName("button");
    var links: any = document.getElementsByTagName("a");

    const clickEvent = (e: any) => {
      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("buttonClick", {
          user_id: id,
          button_name: e.target.innerText,
        }),
      });
    };

    for (let link of links) {
      link.addEventListener("click", clickEvent);
    }

    for (let button of buttons) {
      button.addEventListener("click", clickEvent);
    }

    return () => {
      for (let link of links) {
        link.removeEventListener("click", clickEvent);
      }
      for (let button of buttons) {
        button.removeEventListener("click", clickEvent);
      }

      const end: Date = new Date();
      const duration = Math.round(
        Math.abs(end.getTime() - start.getTime()) / 1000
      );

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("pageView", {
          user_id: id,
          page_session_length: duration,
          page_name: path,
        }),
      });
    };
  }, [location]);

  return children;
};
