import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { AccountDetails } from "./accountDetails";
import { PersonalInfo } from "./personalInfo";
import { AccountPassword } from "./accountPassword";

import { useAppSelector } from "../../store/hook";

export const AllAccountDetails = () => {
  const { auth } = useAppSelector((state) => state.me || ({} as any));

  return (
    <>
      <AccountDetails />
      <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <Box display={{ xs: "none", sm: "block" }}>
        <PersonalInfo />
      </Box>
      { auth === 'EMAIL' && (
        <>
          <Divider sx={{ display: { xs: "none", sm: "block" } }}  />
          <Box display={{ xs: "none", sm: "block" }}>
            <AccountPassword />
          </Box>
        </>
      )}
    </>
  );
};
