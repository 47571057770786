import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import FullLogo from "../assets/images/full_logo.svg";
import { ForgotPasswordContent } from "../components/signin/forgotPasswordContent";

import { isSessionAuthenticated } from "../services/auth";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      const isLoggedIn = await isSessionAuthenticated();

      if (isLoggedIn) {
        navigate("/");
        return;
      }

      setIsLoading(false);
    };

    initialize();
  }, []);

  if (isLoading) {
    return <CircularProgress size={16} />;
  }

  return (
    <>
      <Box
        component="header"
        width="100%"
        position="fixed"
        top={0}
        padding={10}
        display={{ xs: "none", lg: "block" }}
      >
        <img src={FullLogo} alt="full logo" loading="lazy" />
      </Box>
      <Stack
        width="100%"
        minHeight="100dvh"
        direction="column"
        alignItems="center"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        gap={{
          xs: 6,
          sm: 12,
        }}
        padding={{
          xs: 6,
          sm: 0,
        }}
        paddingTop={{
          xs: 6,
          sm: 29.625,
        }}
        paddingBottom={{
          xs: 6,
          sm: 16,
        }}
      >
        <ForgotPasswordContent />
      </Stack>
    </>
  );
};
