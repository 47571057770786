import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import PowerIcon from "@mui/icons-material/Power";
import DialpadIcon from "@mui/icons-material/Dialpad";
import WifiIcon from "@mui/icons-material/Wifi";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { ContainerLayout } from "../../components/account/containerLayout";
import { ChangePromptDialog } from "../../components/shared/dialogs/changePrompt";
import { utilityCompanies } from "../../constants";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

const defaultValue = {
  utilityCompany: "",
  gateCode: "",
  wifiNetwork: "",
  wifiPassword: "",
};

export const AccountPropertyDetails = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAP_API_KEY as string,
  });

  const { id, propertyDetails, address } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fullAddress, setFullAddress]: any = useState(null);
  const [mapCenter, setMapCenter]: any = useState(null);
  const [toastShowing, setToastShowing] = useState(false);

  useEffect(() => {
    if (propertyDetails) {
      setFormData({
        utilityCompany: _.get(propertyDetails, "utilityCompany", "") || "",
        gateCode: _.get(propertyDetails, "gateCode", "") || "",
        wifiNetwork: _.get(propertyDetails, "wifiNetwork", "") || "",
        wifiPassword: _.get(propertyDetails, "wifiPassword", "") || "",
      });
    }

    if (address) {
      const _fullAddress = `${address.address}, ${address.city}, ${address.state}, ${address.zip}`;
      setFullAddress(_fullAddress);
    }
  }, []);

  useEffect(() => {
    const getGeoCode = async (address: any) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: address },
        function handleResults(res, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            const geoCode: any = _.get(res, "[0].geometry.location", null);
            if (geoCode) {
              setMapCenter(geoCode);
            }
          }
        }
      );
    };
    if (isLoaded && address) {
      getGeoCode(fullAddress);
    }
  }, [isLoaded]);

  const toggleEditing = () => {
    const _isEditing = !isEditing;
    setIsEditing(_isEditing);
    setFormData({
      utilityCompany: _.get(propertyDetails, "utilityCompany", "") || "",
      gateCode: _.get(propertyDetails, "gateCode", "") || "",
      wifiNetwork: _.get(propertyDetails, "wifiNetwork", "") || "",
      wifiPassword: _.get(propertyDetails, "wifiPassword", "") || "",
    });
  };

  useEffect(() => {
    const oldData = {
      utilityCompany: _.get(propertyDetails, "utilityCompany", "") || "",
      gateCode: _.get(propertyDetails, "gateCode", "") || "",
      wifiNetwork: _.get(propertyDetails, "wifiNetwork", "") || "",
      wifiPassword: _.get(propertyDetails, "wifiPassword", "") || "",
    };
    setHasChanges(!_.isEqual(oldData, formData));
  }, [formData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const payload: any = {
        propertyDetails: formData,
      };
      await api.portal.update(id, payload);

      setIsEditing(false);
      setHasChanges(false);
      dispatch(setPortalProps(payload));
      setToastShowing(true);
      enqueueSnackbar("Changes saved successfully", {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error) {
      setIsLoading(false);
      setToastShowing(true);
      enqueueSnackbar("Failed to update property details. Please try again!", {
        variant: "error",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const mapAddressPreview = (
    <>
      {isLoaded && mapCenter && (
        <GoogleMap
          zoom={19}
          center={mapCenter}
          options={{
            disableDefaultUI: true,
            draggable: false,
            zoomControl: false,
            keyboardShortcuts: false,
            clickableIcons: false,
            mapTypeId: google.maps.MapTypeId.HYBRID,
          }}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
          }}
        >
          <Marker position={mapCenter} />
        </GoogleMap>
      )}
    </>
  );

  return (
    <>
      <Box
        width="100%"
        height="245px"
        border="1px solid gray"
        display={{
          xs: "block",
          sm: "none",
        }}
      >
        {mapAddressPreview}
      </Box>
      <ContainerLayout
        headerText="Property Details"
        subHeaderText={fullAddress}
      >
        <Box
          width="100%"
          height="155px"
          border="1px solid gray"
          borderRadius="8px"
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          {mapAddressPreview}
        </Box>
        <Stack gap={3}>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            textAlign={{
              xs: "center",
              sm: "left",
            }}
            display={{
              xs: "block",
              sm: "none",
            }}
          >
            {fullAddress}
          </Typography>
          <Typography
            variant="subtitle2"
            color={{ xs: "text.light", sm: "text.secondary" }}
            textAlign={{
              xs: "center",
              sm: "left",
            }}
          >
            Please contact{" "}
            <Link
              href="mailto:customerservice@freedomsolarpower.com"
              color="primary"
            >
              Customer Care
            </Link>{" "}
            if you need to update your address.
          </Typography>
        </Stack>
      </ContainerLayout>
      <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <ContainerLayout
        headerText="Property Details"
        cta={
          isEditing ? (
            <>
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={toggleEditing}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                disabled={isLoading}
              >
                <Typography variant="button" color="secondary.light">
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                disabled={isLoading || !hasChanges}
              >
                {isLoading && (
                  <CircularProgress color="inherit" size={16} thickness={4.5} />
                )}
                Update
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              onClick={toggleEditing}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={toastShowing}
            >
              Edit
            </Button>
          )
        }
      >
        <Stack gap={9} direction="row" flexWrap="wrap">
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Gate code"
                type="text"
                name="gateCode"
                helperText="If applicable, so we may access your property"
                value={formData.gateCode}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <DialpadIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
                sx={{
                  paddingBottom: { xs: 0, md: "32px" },
                  "& .MuiFormHelperText-root": {
                    position: { xs: "static", md: "absolute" },
                    bottom: 0,
                    left: 0,
                    width: { xs: "100%", md: "max-content" },
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                select
                label="Utility company"
                value={formData.utilityCompany}
                name="utilityCompany"
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PowerIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
              >
                {utilityCompanies.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Wi-Fi network"
                type="text"
                name="wifiNetwork"
                value={formData.wifiNetwork}
                onChange={handleChange}
                helperText="We need your Wi-Fi to set up your solar monitoring system"
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <WifiIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
                autoComplete="off"
                sx={{
                  paddingBottom: { xs: 0, md: "32px" },
                  "& .MuiFormHelperText-root": {
                    position: { xs: "static", md: "absolute" },
                    bottom: 0,
                    left: 0,
                    width: { xs: "100%", md: "max-content" },
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Wi-Fi password"
                type={showPassword ? "text" : "password"}
                name="wifiPassword"
                value={formData.wifiPassword}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
                autoComplete="off"
              />
            </FormControl>
          </Box>
        </Stack>
        <ChangePromptDialog
          block={hasChanges}
          isLoading={isLoading}
          onSave={handleSubmit}
        />
      </ContainerLayout>
    </>
  );
};
