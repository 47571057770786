import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";

export const MobileFooter = ({ cta, forOnboarding }: any) => {
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      var limit =
        Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        ) - window.innerHeight;
      setShowShadow(
        document.documentElement.clientHeight !==
          document.documentElement.scrollHeight &&
          Math.round(window.scrollY) !== limit
      );
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <Stack
      width="100%"
      maxWidth="450px"
      alignItems="center"
      justifyContent="center"
      gap={6}
      display={{
        xs: "flex",
        sm: forOnboarding ? "flex" : "none",
      }}
      direction="column"
      position={{
        xs: "sticky",
        sm: forOnboarding ? "static" : "sticky",
      }}
      bottom={0}
      zIndex={2}
      sx={{
        backgroundColor: "text.white",
        boxShadow: {
          xs: showShadow ? "0px -4px 16px 0px #00000014" : "none",
          sm: "none",
        },
      }}
      paddingX={{
        xs: 6,
        sm: 0,
      }}
      paddingTop={{
        xs: 3,
        sm: 0,
      }}
      paddingBottom={{
        xs: 13,
        sm: 0,
      }}
      marginTop={forOnboarding ? 0 : "auto"}
    >
      {cta}
    </Stack>
  );
};
