import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import LinkElment from "@mui/material/Link";
import { StageTopContent } from "../../components/project/stageTopContent";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { ListLayout } from "../../components/project/listLayout";
import { useAppSelector } from "../../store/hook";
import { ListIcon } from "../../components/project/listIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const ProjectKickoff = () => {
  const { tracker, systemDetails, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [todo, setTodo] = useState(null) as any;
  const [complete, setComplete] = useState(false) as any;
  const [confirmPaymentPending, setConfirmPaymentPending] = useState(
    false
  ) as any;
  const [steps, setSteps] = useState([]) as any;
  const lender = _.get(systemDetails, "lender", null);
  const downPayment = _.get(systemDetails, "downPayment", 0) || 0;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("projectKickoff");

  useEffect(() => {
    if (tracker && tracker.projectKickoff) {
      const todo: any = {};
      const stageSteps: any = _.sortBy(
        _.get(tracker.projectKickoff, "steps", []),
        "stepId"
      );
      const signedContract = _.find(
        stageSteps,
        (s) => s.textKey === "sign contract"
      );
      const uploadUtility = _.find(
        stageSteps,
        (s) => s.textKey === "send utility bill"
      );
      const payDeposit = _.find(
        stageSteps,
        (s) => s.textKey === "confirm payment"
      );

      if (signedContract && signedContract.valid && !signedContract.completed)
        todo.signedContract = true;
      if (uploadUtility && uploadUtility.valid && !uploadUtility.completed)
        todo.uploadUtility = true;
      if (payDeposit && payDeposit.valid && !payDeposit.completed)
        todo.payDeposit = true;

      if (todo.payDeposit && lender === "Cash") {
        setTodo("Pay Deposit");
      } else if (todo.signedContract || todo.uploadUtility || todo.payDeposit) {
        setTodo(
          "We're uploading key documents, and will contact you for any action needed"
        );
      }

      if (_.keys(todo).length === 1 && todo.payDeposit) {
        setConfirmPaymentPending(true);
      }

      setSteps(stageSteps);

      // Show complete if all steps are completed
      setComplete(_.every(stageSteps, (s) => s.completed));
    }
  }, []);

  const stageCopy: any = {
    ...extendedProjectsCopy,
    "sign contract": "Sign contract",
    "send utility bill": "Send utility bill",
    "confirm payment":
      lender === "Cash" || downPayment > 0
        ? "Pay deposit"
        : "Loan approval received",
    "verify documents": "Verify internal documents",
  };

  const stateTooltipCopy: any = {
    "confirm payment":
      lender === "Cash" ? (
        <div>
          Invoice emailed to address on file. Questions?
          <br />
          Email <br />
          <LinkElment
            component={Link}
            to="mailto:customerservice@freedomsolarpower.com"
          >
            Customer Care
          </LinkElment>
        </div>
      ) : (
        ""
      ),
  };

  const keySteps = _.map(steps, (step, i) => ({
    valid: _.get(step, "valid", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    text: _.get(stageCopy, [step.textKey], "--"),
    tooltip: _.get(stateTooltipCopy, [step.textKey], ""),
  }));

  return (
    <>
      <StageTopContent
        stage={tracker.projectKickoff}
        headerText="Project Kickoff"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.projectKickoff?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : ""
        }
        subHeaderText={
          isBatteryOnly
            ? "You complete any outstanding tasks, and we get ready to install your new battery."
            : "You complete any outstanding tasks, and we get ready to design your new solar system."
        }
      >
        {todo && (
          <Requirements>
            <Requirement
              headText={
                confirmPaymentPending && lender !== "Cash"
                  ? "Pending Approval"
                  : todo
              }
            />
          </Requirements>
        )}

        {complete && (
          <Requirements isComplete>
            <Requirement headText="All tasks have been completed" />
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="icon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={["You", "Freedom Solar Energy Consultant"]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
