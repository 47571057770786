import TimelineDot from "@mui/lab/TimelineDot";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';

export const SubListIcon = ({checked, needFileUpload, errorInfo, errorDanger}:any) => {
    if(errorInfo) {
        return <ErrorIcon fontSize="small" sx={{color: "#2660DF"}} />
    }
 
    if(errorDanger) {
        return  <ReportProblemIcon fontSize="small" sx={{color: "#D92D20"}} />
    }

    if(needFileUpload) {
        return <FileUploadRoundedIcon color="primary" />
    }

    return <TimelineDot sx={{padding: "2px"}} variant={checked ? "filled" : "outlined"} />
}