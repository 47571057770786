import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { MainNavData, AccountNavData, MobileAccountNav, ProjectNavData } from "../constant/navigation";
import { useAppSelector } from "../../store/hook";
import { DrawerContent } from "./drawerContent";

const overflowEllipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const appBarStyle = {
  display: {
    xs: "flex",
    lg: "none",
  },
  flexDirection: "row",
  width: "100%",
  maxWidth: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "text.white",
  zIndex: 2,
  top: 0,
  padding: 3,
  boxShadow: "none"
}

export const DashboardNav = ({ customPathData }: any) => {
  const portal = useAppSelector((state) => state.portal || ({} as any));
  const [routeData, setRouteData]: any = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateMainPage = () => {
    const path = location.pathname;
    const mainRoute: any = path.slice(0, path.lastIndexOf("/"));
    navigate(mainRoute);
  };

  useEffect(() => {
    const path = location.pathname;
    const pathData: any = [
      ...MainNavData(portal),
      ...AccountNavData(portal),
      ...MobileAccountNav(portal),
      ...ProjectNavData(portal),
      {
        name: "FAQs",
        to: `/${portal.id}/support/faqs`,
        exact: false,
      },
    ].find((x) => x.to === path);
    customPathData ? setRouteData(customPathData) : setRouteData(pathData);
  }, [location]);

  return (
    <>
      <AppBar
        sx={{...appBarStyle, borderBottom: routeData && !routeData?.exact ? "1px solid #E8EBED" : "none"}}
        position="sticky"
      >
        <Stack
          direction="row"
          alignItems="center"
          visibility={routeData && !routeData?.exact ? "visible" : "hidden"}
          onClick={navigateMainPage}
        >
          <IconButton size="large">
            <KeyboardArrowLeftIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          fontWeight={600}
          sx={overflowEllipsis}
        >
          {routeData?.mobileTitle || routeData?.name}
        </Typography>
        <Stack direction="row" alignItems="center">
          <IconButton
            size="large"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(!mobileMenuOpen)}
        ModalProps={{ keepMounted: true }}  // Better open performance on mobile.
        sx={{ display: { xs: "block", lg: "none" }, "& .MuiDrawer-paper": { boxSizing: "border-box", width: "80%" } }}
      >
        <DrawerContent />
      </Drawer>
      <Drawer variant="permanent" sx={{display: { xs: "none", lg: "block" }}}>
        <DrawerContent />
      </Drawer>
    </>
  );
};
