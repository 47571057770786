import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

import { gray } from "../../theme/colors";

interface EnhancedTableProps {
  headers: any;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    headers,
    onSelectAllClick,
    numSelected,
    rowCount,
  } = props;

  const getColumnWidth = (columnNum: number) => {
    switch (columnNum) {
      case 1:
        return "270px";
      case 2:
        return "320px";
      default:
        return "100%";
    }
  };

  return (
    <TableHead
      sx={{
        backgroundColor: gray[50],
        borderTop: `1px solid ${gray[100]}`,
      }}
    >
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            borderColor: gray[100],
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {headers.map((headCell: any, index: any) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{
              borderColor: gray[100],
              display: {
                xs: index !== 0 ? "none" : "table-cell",
                sm: "table-cell",
              },
              paddingLeft: index === 0 ? 0 : "normal",
              width: getColumnWidth(index),
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
