import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useWebSocket, { ReadyState } from "react-use-websocket";
import _ from "lodash";
import { Auth } from "aws-amplify";

import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAppDispatch, useAppSelector } from "./store/hook";
import { setPendingDownloadFile, logout } from "./store/modules/meSlice";

import { downloadFiles } from "./services/download";
import { isSessionAuthenticated, removeImpersonate } from "./services/auth";

import { AppRoutes } from "./routes";

function Main() {
  const { id, pendingDownloadFiles, isImpersonating, impersonateTtl } =
    useAppSelector((state) => state.me || ({} as any));
  const { lastJsonMessage, readyState, sendMessage } = useWebSocket(
    process.env.REACT_APP_WEBSOCKET_URL as string,
    { share: true }
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [currentSnackbar, setCurrentSnackbar] = useState<any>(null);
  const [showImpersonateBanner, setShowImpersonateBanner] =
    useState<any>(false);

  useEffect(() => {
    const checkImpersonate = async () => {
      const isLoggedIn = await isSessionAuthenticated();
      if (
        isImpersonating &&
        isLoggedIn &&
        impersonateTtl &&
        Number(impersonateTtl) > new Date().valueOf()
      ) {
        setShowImpersonateBanner(true);
      }
    };
    checkImpersonate();
  }, []);

  useEffect(() => {
    if (id && readyState === ReadyState.OPEN) {
      const data = {
        action: "registerConnection",
        requestId: id, // user id
      };
      sendMessage(JSON.stringify(data));
    }
  }, [readyState, id]);

  useEffect(() => {
    if (lastJsonMessage) {
      const { event, data } = lastJsonMessage as any;
      if (
        event.includes("FILES_DOWNLOAD") &&
        _.includes(pendingDownloadFiles, data.fileName)
      ) {
        handleFileDownloads(lastJsonMessage);
      }
    }
  }, [lastJsonMessage]);

  const handleFileDownloads = async (lastJsonMessage: any) => {
    const { event, data } = lastJsonMessage;

    if (event === "FILES_DOWNLOAD_START") {
      const snackId = enqueueSnackbar(`Zipping ${data.total} files`, {
        variant: "default",
        onClose: () => {},
        action: (key) => (
          <Stack direction="row" alignItems="center">
            <Typography
              variant="body1"
              color="primary"
              id={`progress-${data.fileName}`}
            >
              0%
            </Typography>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
        persist: true,
      });
      setCurrentSnackbar(snackId);
    }

    if (event === "FILES_DOWNLOAD_INPROGRESS") {
      const ref = document.getElementById(`progress-${data.fileName}`);
      if (ref) {
        const progress = Math.ceil((data.progress / data.total) * 100);
        ref.innerText = `${progress}%`;
      }
    }

    if (event === "FILES_DOWNLOAD_ERROR") {
      if (currentSnackbar) {
        closeSnackbar(currentSnackbar);
      }

      enqueueSnackbar(
        "Something went wrong downloading files. Please try again.",
        {
          variant: "error",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        }
      );

      const newPendingDownloads = pendingDownloadFiles.filter(
        (x) => x !== data.fileName
      );
      dispatch(setPendingDownloadFile(newPendingDownloads));
    }

    if (event === "FILES_DOWNLOAD_READY") {
      if (currentSnackbar) {
        closeSnackbar(currentSnackbar);
      }

      const snackId = enqueueSnackbar("Download in progress", {
        variant: "default",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        persist: true,
      });

      await downloadFiles([{ key: data.fileKey, name: data.fileName }]).catch(
        (err) => {
          enqueueSnackbar("Failed to download files. Please try again!", {
            variant: "error",
            onClose: () => {},
            action: (key) => (
              <IconButton
                color="inherit"
                size="small"
                onClick={() => closeSnackbar(key)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          });
        }
      );

      const newPendingDownloads = pendingDownloadFiles.filter(
        (x) => x !== data.fileName
      );
      dispatch(setPendingDownloadFile(newPendingDownloads));

      closeSnackbar(snackId);
    }
  };

  const handleLogout = async () => {
    await Auth.signOut();
    removeImpersonate();
    dispatch(logout());
    window.location.reload();
  };

  return (
    <>
      {showImpersonateBanner && (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{
            background: "red",
            color: "#FFFFFF",
            padding: { lg: "10px 10px 10px 290px", xs: "10px" },
            position: "sticky",
            top: 0,
            zIndex: 1200,
          }}
        >
          <Typography>
            You are impersonating the user. Your actions are being logged right
            now.
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => handleLogout()}
          >
            <LogoutIcon />
            Stop
          </Button>
        </Stack>
      )}
      <AppRoutes />
    </>
  );
}

export default Main;
