import _ from "lodash";

export const redesignBannerCopy: any = {
  customer: {
    title: "Redesign Requested",
    description:
      "You’ve let us know you’d like an alternate design. Our team will be in touch to discuss new layout options."
  },
  anyone: {
    title: "Redesign Requested",
    description: (by: string[]) => {
      const requestorArr = _.uniq(by);
      let requestedBy = "";
      if (requestorArr.length === 1) {
        requestedBy = requestorArr[0];
      } else if (requestorArr.length === 2) {
        requestedBy = requestorArr.join(" and ");
      } else if (requestorArr.length > 2) {
        const last = requestorArr.splice(requestorArr.length - 1, 1);
        requestedBy = requestorArr.join(", ") + ` and ${last}`;
      } else {
        requestedBy = "our quality assurance team"; // By default
      }

      return `A revised solar panel layout has been requested by ${requestedBy}. We will contact you shortly to review the new design.`;
    }
  },
  default: {
    title: "Redesign Required",
    description:
      "A revised solar panel is required. We will contact you shortly to review the new design."
  }
};

export const redesignTypeRequestedBy: any = {
  "AHJ & Utility Compliance Review Kickback": "our quality assurance team",
  ahj_and_utility_compliance_review_kickback: "our quality assurance team",
  "AHJ Kickback": "your AHJ",
  ahj_kickback: "your AHJ",
  "Change Order": "Customer",
  change_order: "Customer",
  "Customer Kickback": "Customer",
  customer_kickback: "Customer",
  "Field Ops Pre Install Kickback": "our quality assurance team",
  field_ops_pre_install_kickback: "our quality assurance team",
  "HOA Kickback": "your HOA",
  hoa_kickback: "your HOA",
  "Internal Ops Team Pre-Submittal Kickback": "our quality assurance team",
  internal_ops_team_presubmittal_kickback: "our quality assurance team",
  "QCT Pre Install Kickback": "our quality assurance team",
  qct_pre_install_kickback: "our quality assurance team",
  "Utility Kickback": "your Utility",
  utility_kickback: "your Utility"
};

export const getRedesignCopy = (task: any) => {
  const redesignType = _.get(task, ["fields", "Redesign Type"], "");
  if (redesignType.length) {
    const texts = _.split(redesignType, ";")
      .map((x) => _.get(redesignTypeRequestedBy, [x], null))
      .filter((x) => !!x);
    if (texts.includes("Customer")) {
      return {
        title: redesignBannerCopy.customer.title,
        description: redesignBannerCopy.customer.description
      };
    } else {
      return {
        title: redesignBannerCopy.anyone.title,
        description: redesignBannerCopy.anyone.description(texts)
      };
    }
  }

  return {
    title: redesignBannerCopy.default.title,
    description: redesignBannerCopy.default.description
  };
};
