import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const Feature = ({ icon, header, description }: any) => {
  return (
    <Stack direction="row" alignItems="flex-start" width="500px" gap={10}>
      <Box color="primary.main">{icon}</Box>
      <Stack gap={2}>
        <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
          {header}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};
