import { useState } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import LinearProgress from "@mui/material/LinearProgress";

// import { primary, gray } from "../../theme/colors";

import PDFIcon from "../../assets/icons/pdf.svg";

export const CustomDialog = ({
  open,
  handleClose,
  title,
  content,
  fileUpload = false,
  value,
  setValue,
  lCta,
  rCta,
}: any) => {
  const [state, setState] = useState("default"); //drop | default | error

  const borderColor: any = {
    default: "#B4BDC5",
    drop: "#F98A3C",
    error: "#F04438",
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth={fileUpload ? "md" : "sm"}
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            width: {
              xs: "100%",
              sm: "calc(100% - 64px)",
            },
            height: {
              xs: "100dvh",
              sm: "auto",
            },
            maxHeight: {
              xs: "100dvh",
              sm: "calc(100% - 64px)",
            },
            margin: {
              xs: 0,
              sm: "32px",
            },
            borderRadius: {
              xs: 0,
              sm: "8px",
            },
          },
        }}
      >
        <DialogTitle
          component="div"
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flexDirection: "column",
            gap: 4,
            paddingTop: 6,
            paddingBottom: 3,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Box marginLeft="auto">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            textAlign="center"
            color="textDark"
            fontWeight={600}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogTitle
          component="div"
          sx={{
            display: {
              xs: "flex",
              sm: "none",
            },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 3,
            borderBottom: title && fileUpload ? "1px solid #E8EBED" : "none",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            visibility={title ? "visible" : "hidden"}
            onClick={handleClose}
          >
            <IconButton size="large">
              <KeyboardArrowLeftIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          {title && fileUpload && (
            <Typography
              variant="subtitle1"
              color="textPrimary"
              fontWeight={600}
            >
              {title}
            </Typography>
          )}
          <Stack direction="row" alignItems="center" visibility="hidden">
            <IconButton size="large">
              <KeyboardArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <Stack
            sx={{
              paddingTop: {
                xs: "1.5rem",
                sm: "0px",
              },
              paddingBottom: 5,
              paddingLeft: 6,
              paddingRight: 6,
              height: "100%",
              justifyContent: {
                xs: "center",
                sm: "flex-start",
              },
            }}
            gap={5}
          >
            {title && !fileUpload && (
              <Typography
                variant="subtitle1"
                color="textPrimary"
                fontWeight={600}
                sx={{
                  textAlign: "center",
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              >
                {title}
              </Typography>
            )}
            {content}
            {/* TODO: Remove FileUpload to separate componet in ./dialogs */}
            {fileUpload && (
              <>
                <Stack
                  component="label"
                  sx={{
                    backgroundColor: state === "drop" ? "#FFFCFA" : "#F9F9FB",
                    border: "1px dashed",
                    borderColor: borderColor[state],
                    padding: "40px 0",
                    borderRadius: "8px",
                    gap: 8,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {state === "error" ? (
                    <ErrorIcon
                      fontSize="large"
                      sx={{ color: "#F04438", width: "48px", height: "48px" }}
                    />
                  ) : (
                    <FileUploadRoundedIcon
                      fontSize="large"
                      sx={{
                        color: {
                          xs: "#F98A3C",
                          sm: state === "drop" ? "#F98A3C" : "#8C9AA6",
                        },
                        width: "48px",
                        height: "48px",
                      }}
                    />
                  )}
                  <Stack gap={6}>
                    {state === "error" ? (
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        color="error.dark"
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Error message here
                      </Typography> /* File exceeds maximum size | Incorrect file type */
                    ) : (
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        color="textSecondary"
                      >
                        <Typography
                          component="span"
                          color="primary"
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          Click to upload
                        </Typography>{" "}
                        or drag and drop
                      </Typography>
                    )}
                    <Stack gap={2}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="textSecondary"
                      >
                        Supported formats: .pdf .doc or .docx.
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="textSecondary"
                      >
                        Maximum file size 50 MB.
                      </Typography>
                    </Stack>
                  </Stack>
                  <input type="file" hidden />
                </Stack>
                <Stack
                  sx={{
                    padding: "10px 13px 10px 21px",
                    border: "1px solid",
                    borderColor: state === "error" ? "#F04438" : "#D1D9E2",
                    borderRadius: "8px",
                    background: state === "error" ? "#FFFBFA" : "transparent",
                  }}
                  direction="row"
                  alignItems="center"
                  gap={6.5}
                >
                  <img
                    src={PDFIcon}
                    alt="pdf icon"
                    width="64px"
                    height="64px"
                  />
                  <Box flex="auto">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2" color="textSecondary">
                        Utility Bill
                      </Typography>
                      <IconButton aria-label="close">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Typography
                      variant="body1"
                      color={{
                        xs:
                          state === "error" ? "error.dark" : "secondary.light",
                        sm: "secondary.light",
                      }} // In Mobile color red if error is file size
                    >
                      12 MB
                    </Typography>
                    {state === "error" ? (
                      <Typography
                        variant="body1"
                        color="error.dark"
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                          },
                        }}
                      >
                        Error message
                      </Typography>
                    ) : (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={10}
                        paddingRight="8px"
                      >
                        <LinearProgress
                          variant="determinate"
                          value={48}
                          sx={{
                            width: "100%",
                            height: "12px",
                            borderRadius: "8px",
                            backgroundColor: "#E8EBED",
                          }}
                        />
                        <Typography variant="body1" color="primary">
                          48%
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    padding: "10px 13px 10px 21px",
                    border: "1px solid #F04438",
                    borderRadius: "8px",
                    background: "#FFFBFA",
                  }}
                  direction="row"
                  alignItems="center"
                  gap={6.5}
                >
                  <ErrorIcon
                    fontSize="large"
                    sx={{ color: "#F04438", width: "64px", height: "64px" }}
                  />
                  <Box flex="auto">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle2" color="error.dark">
                        Something went wrong
                      </Typography>
                      <IconButton aria-label="close">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Typography variant="body1" color="text.secondary">
                      Please contact{" "}
                      <Link
                        color="primary"
                        href="mailto:customerservice@freedomsolarpower.com"
                      >
                        Customer Care
                      </Link>{" "}
                      if the issue persists.
                    </Typography>
                  </Box>
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 6,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: 4,
            }}
            justifyContent="space-between"
          >
            <Box>{lCta}</Box>
            <Box>{rCta}</Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
