export const monitoringDeviceData: any = {
  "SunPower PVS6": {
    name: "SunPower",
    url: "https://us.sunpower.com/products/software/mysunpower",
    appName: "mySunPower",
    appGoogleUrl:
      "https://play.google.com/store/apps/details?id=com.mysunpower&hl=en_US&gl=US&pli=1",
    appAppleUrl: "https://apps.apple.com/us/app/mysunpower/id1504718150",
  },
  "Enphase IQ Gateway": {
    name: "Enphase",
    url: "https://enphase.com/homeowners/enphase-app",
    appName: "Enphase Enlighten",
    appGoogleUrl:
      "https://play.google.com/store/apps/details?id=com.enphaseenergy.myenlighten",
    appAppleUrl: "https://apps.apple.com/us/app/enphase-enlighten/id787415770",
  },
  "Tesla Integrated": {
    name: "Tesla",
    url: "https://www.tesla.com/support/tesla-app",
    appName: "the Tesla app",
    appGoogleUrl:
      "https://play.google.com/store/apps/details?id=com.teslamotors.tesla&amp;hl=en&pli=1",
    appAppleUrl: "https://apps.apple.com/us/app/tesla/id582007913",
  },
};
