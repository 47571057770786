import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import LinkElment from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { StageTopContent } from "../../components/project/stageTopContent";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { ListLayout } from "../../components/project/listLayout";
import { useAppSelector } from "../../store/hook";
import { monitoringDeviceData } from "../../constants/monitoringDevice";
import { ListIcon } from "../../components/project/listIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const SystemLaunch = () => {
  const { id, tracker, fields, email, firstName, lastName, isBatteryOnly } =
    useAppSelector((state) => state.portal || ({} as any));
  const [complete, setComplete] = useState(false) as any;
  const [steps, setSteps] = useState([]) as any;
  const [launchMeeting, setLaunchMeeting] = useState(null) as any;
  const [launchMeetingComplete, setLaunchMeetingComplete] = useState(
    false
  ) as any;
  const [allowReschedule, setAllowReschedule] = useState(false) as any;
  const [meetingIsToday, setMeetingIsToday] = useState(false) as any;
  const [monitoringDevice, setMonitoringDevice] = useState(null) as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("systemLaunch");

  useEffect(() => {
    if (tracker && tracker.systemLaunch) {
      setComplete(_.get(tracker.systemLaunch, "complete", false));
      setCustomData();
      const stageSteps: any = _.sortBy(
        _.get(tracker.systemLaunch, "steps", []),
        "stepId"
      );

      setSteps(stageSteps);
    }
  }, []);

  const setCustomData = () => {
    setLaunchMeetingComplete(
      _.get(tracker.systemLaunch, "steps.2.completed", false)
    );

    let meetingIsToday = false;
    const launchMeetingVal = _.get(
      tracker.systemLaunch,
      "hsData.launchMeeting",
      null
    );

    if (launchMeetingVal) {
      const now = moment().local();
      const startDate = moment.utc(launchMeetingVal.startTime).local();
      const endDate = moment.utc(launchMeetingVal.endTime).local();
      meetingIsToday = startDate.isValid() && startDate.isSame(now, "day");

      if (startDate.isValid()) {
        setLaunchMeeting({
          text: startDate.clone().format("dddd, MMMM D"),
          time: `(${startDate.clone().format("LT")}-${endDate
            .clone()
            .format("LT")})`,
        });
        setMeetingIsToday(meetingIsToday);
        setAllowReschedule(!meetingIsToday && now.isBefore(startDate));
      }
    }

    const device = _.get(fields, "monitoring_device", null);

    if (device && monitoringDeviceData[device]) {
      setMonitoringDevice(monitoringDeviceData[device]);
    }
  };

  const handleMeetingRequest = () => {
    let meetingUrl = `${
      process.env.REACT_APP_HS_MEETING_URL
    }?email=${encodeURIComponent(email)}`;

    if (!_.isEmpty(firstName))
      meetingUrl += `&firstName=${encodeURIComponent(firstName)}`;
    if (!_.isEmpty(lastName))
      meetingUrl += `&lastName=${encodeURIComponent(lastName)}`;

    window.open(meetingUrl, "_blank");
  };

  const handleMeetingRescheduleRequest = () => {
    if (!launchMeeting) return;
    const launchMeetingVal = _.get(
      tracker.systemLaunch,
      "hsData.launchMeeting",
      null
    );
    let meetingUrl = `${process.env.REACT_APP_HS_MEETING_URL}?rescheduleId=${
      launchMeetingVal.meetingChangeId
    }&ms=1&email=${encodeURIComponent(email)}`;

    if (!_.isEmpty(firstName))
      meetingUrl += `&firstName=${encodeURIComponent(firstName)}`;
    if (!_.isEmpty(lastName))
      meetingUrl += `&lastName=${encodeURIComponent(lastName)}`;

    window.open(meetingUrl, "_blank");
  };

  const stageCopy: any = {
    ...extendedProjectsCopy,
    "register warranty": "Register warranty",
    "complete system monitoring setup": "Complete system monitoring setup",
    "complete launch meeting": "Complete launch meeting",
    "launch system": "Launch system",
  };

  const keySteps = _.map(steps, (step, i) => ({
    valid: _.get(step, "valid", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    text: _.get(stageCopy, [step.textKey], "--"),
  }));

  return (
    <>
      <StageTopContent
        stage={tracker.systemLaunch}
        headerText="System Launch"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.systemLaunch?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : "Usually takes 2-3 weeks"
        }
        subHeaderText={
          isBatteryOnly
            ? "After your third-party inspections are complete, you schedule a meeting with us and we confirm that you're ready to activate your new battery, and understand how to use your battery’s monitoring app."
            : "After your third-party inspections are complete, you schedule a meeting with us and we confirm that you're ready to energize your new system so that you can start generating clean, low-cost power for your home."
        }
      >
        {_.get(steps, "0.completed", false) &&
          _.get(steps, "1.completed", false) &&
          !launchMeetingComplete &&
          !launchMeeting && (
            <Requirements>
              <Requirement
                headText="Schedule launch meeting"
                cta={
                  <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleMeetingRequest}
                  >
                    Schedule Meeting
                  </Button>
                }
              />
            </Requirements>
          )}
        {_.get(steps, "0.completed", false) &&
          _.get(steps, "1.completed", false) &&
          !launchMeetingComplete &&
          launchMeeting && (
            <Requirements>
              <Requirement
                headText={
                  <>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      fontWeight={600}
                    >
                      {"Launch meeting"}{" "}
                      {meetingIsToday && (
                        <Typography
                          component="span"
                          sx={{
                            color: "#F98A3C",
                            marginLeft: "10px",
                          }}
                        >
                          Happening Today!
                        </Typography>
                      )}
                    </Typography>
                  </>
                }
                subText={
                  launchMeeting ? (
                    <Typography variant="body1" color="textSecondary">
                      {launchMeeting.text}{" "}
                      <Typography component="span" color="secondary.light">
                        {launchMeeting.time}
                      </Typography>
                    </Typography>
                  ) : (
                    ""
                  )
                }
                cta={
                  <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleMeetingRescheduleRequest}
                    disabled={!allowReschedule}
                  >
                    Request reschedule
                  </Button>
                }
                note={
                  !allowReschedule ? (
                    <>
                      Window to reschedule is past. Contact{" "}
                      <LinkElment
                        component={Link}
                        to="mailto:customerservice@freedomsolarpower.com"
                      >
                        Customer Care
                      </LinkElment>{" "}
                      if you need to reschedule.
                    </>
                  ) : (
                    ""
                  )
                }
              />
            </Requirements>
          )}

        {launchMeetingComplete && (
          <Requirements
            isComplete
            note={
              monitoringDevice ? (
                <>
                  {isBatteryOnly
                    ? "Monitor your battery with "
                    : "Monitor your solar system with "}
                  <LinkElment href={monitoringDevice.url} target="_blank">
                    {monitoringDevice.name}
                  </LinkElment>
                  .
                </>
              ) : (
                <></>
              )
            }
          >
            <Requirement
              headText="Launch meeting"
              subText={
                <Typography variant="body1" color="textSecondary">
                  {`Completed ${moment
                    .utc(steps[2]?.task?.completed_at)
                    .local()
                    .format("dddd, MMMM D")}`}
                </Typography>
              }
            />
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="icon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={["You", "Freedom Solar Monitoring Team"]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
