export const fileTypesIcon: any = {
  "application/pdf": "i-pdf",
  "image/jpeg": "i-jpg",
  "image/png": "i-jpg",
  folder: "i-folder",
};

export const stages: any = {
  projectKickoff: {
    icon: "i-project-kickoff",
    name: "Project Kickoff",
  },
  siteAssessment: {
    icon: "i-site-assessment",
    name: "Site Assessment",
  },
  systemDesign: {
    icon: "i-system-design",
    name: "System Design",
  },
  applicationsAndPermits: {
    icon: "i-applications-permits",
    name: "Applications & Permits",
  },
  onSiteInstallation: {
    icon: "i-onsite-installation",
    name: "On-Site Installation",
  },
  thirdPartyInspections: {
    icon: "i-thirdparty-inspections",
    name: "Third-Party Inspections",
  },
  systemLaunch: {
    icon: "i-system-launch",
    name: "System Launch",
  },
};

export const documentLabels: any = {
  finalProposal: "Final Proposal",
  signedContract: "Signed Contract",
  utilityBill: "Utility Bill",
  siteAssessmentPhotos: "Site Assessment Photos",
  finalSystemDesign: "Final System Design",
  hoaApproval: "HOA approval",
  permitApproval: "Permit approval",
  interconnectionApproval: "Interconnection approval",
  electricalInstallationPhotos: "Electrical Installation Photos",
  solarInstallationPhotos: "Solar Installation Photos",
  permissionToOperateLetter: "Permission to Operate Letter",
  cityInspectionApproval: "City Inspection Approval",
  utilityInspectionApproval: "Utility Inspection Approval",
  registerWarranty: "Warranty Registration",
  launchPacket: "Launch Packet",
  paidInFullInvoice: "Paid In Full Invoice",
};

export const documentFolderLabels: any = {
  "site-assessment-photos": "Site Assessment Photos",
  "electrical-installation-photos": "Electrical Installation Photos",
  "solar-installation-photos": "Solar Installation Photos",
};

export const documentTooltips: any = {
  finalProposal:
    "Our final sales proposal, detailing the total cost and expected production of your system.",
  signedContract: "Your signed contract with Freedom Solar Power.",
  utilityBill:
    "A copy of your utility bill showing 12 months of consumption (or as much usage history as possible) so we may analyze your electric usage to properly size your new solar system.",
  siteAssessmentPhotos:
    "Photos we take during our on-site assessment of your property.",
  finalSystemDesign:
    "Our final design proposal for your new system, based on our on-site assessment of your property.",
  hoaApproval: "Written approval from your HOA to install your new system.",
  permitApproval:
    "Written confirmation from your city or county that your system abides by all local regulations for safety and reliability.",
  interconnectionApproval:
    "Written approval from your utility provider to connect your system to the grid.",
  electricalInstallationPhotos:
    "Photos we took of your equipment wall during the installation of your new system.",
  solarInstallationPhotos:
    "Photos we took of your panels during the installation of your new system.",
  permissionToOperateLetter:
    "Proof that you've received permission to operate (PTO) your system from your utility, the last step before you can send energy to the grid.",
  cityInspectionApproval:
    "Proof that your city has inspected and approved your system for energy production.",
  utilityInspectionApproval:
    "Proof that your utility has inspected and approved your system for energy production.",
  registerWarranty:
    "A document providing registration of your warranty, guaranteeing panel performance and full coverage for any defects.",
  launchPacket:
    "A final, comprehensive document we provide when your system is ready to energize.",
  paidInFullInvoice:
    "A one-page document you can use for tax purposes to show the total amount you've paid for your new solar energy system.",
};

export const formatDocument = (documents: any) => {
  return documents.map((doc: any) => {
    return {
      ...doc,
      id: doc.folderName || doc._id,
      icon: fileTypesIcon[doc.type],
      stage: stages[doc.trackerStage],
      label: documentLabels[doc.doc],
      tooltip: documentTooltips[doc.doc],
    };
  });
};
