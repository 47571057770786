import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { CustomNavLink } from "../shared/customNavLink";
import { AccountNavData, MobileAccountNav } from "../constant/navigation";

import { primary, gray } from "../../theme/colors";
import { useAppSelector } from "../../store/hook";

const navStyle = {
  padding: "16px",
  borderRadius: "8px",
  color: gray[800],
  "& .MuiListItemIcon-root": {
    minWidth: "unset",
    marginRight: "1rem",
    color: gray[500],
  },
  "&:hover, &.active": {
    backgroundColor: gray[50],
  },
  "&.active .MuiListItemIcon-root": {
    color: primary[400],
  },
};

const mobileNavStyle = {
  height: "64px",
  padding: "12px 24px",
  color: gray[800],
  borderBottom: `1px solid ${gray[100]}`,
  "& .MuiListItemIcon-root": {
    minWidth: "unset",
    marginRight: "1rem",
    color: gray[500],
  },
};

export const AccountSidebar = ({ forMobile }: any) => {
  const portal = useAppSelector((state) => state.portal || ({} as any));
  const { auth, customerId } = useAppSelector((state) => state.me || ({} as any));

  const getData = () => {
    let accountNav = AccountNavData(portal);
    let mobileAccountNavs = MobileAccountNav(portal);

    // Remove change password for OAuth users
    if (auth !== 'EMAIL') {
      mobileAccountNavs = mobileAccountNavs.filter(x => !x.to.includes('account/change-password'))
    }

    // Remove main account for mobile
    if (forMobile) {
      accountNav = accountNav.filter((x) => x.to !== `/${portal.id}/account`);
    }

    // Remove secondary account for secondary user
    if (portal.secondaryContactId === customerId) {
      accountNav = accountNav.filter((x) => x.to !== `/${portal.id}/account/secondary-contact`);
    }

    return forMobile
      ? [...mobileAccountNavs, ...accountNav]
      : accountNav;
  };

  return (
    <>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "auto",
          padding: 0,
          borderTop: forMobile ? `1px solid ${gray[100]}` : "",
          gap: 1,
        }}
      >
        {getData().map((linkData, index) => (
          <ListItem key={linkData.name} disablePadding>
            <ListItemButton
              component={CustomNavLink}
              sx={forMobile ? mobileNavStyle : navStyle}
              to={linkData.to}
              activeClassName={"active"}
              exact={linkData.exact}
            >
              <ListItemIcon>{linkData.icon}</ListItemIcon>
              <ListItemText primary={linkData.name} sx={{ margin: 0 }} />
              {forMobile && (
                <ChevronRightIcon
                  sx={{ marginLeft: "auto", color: gray[500] }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};
