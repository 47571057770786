import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useAppDispatch } from "../store/hook";
import { logout } from "../store/modules/meSlice";

import { isSessionAuthenticated, removeImpersonate } from "../services/auth";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../utils/gtmArgs";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const logoutSession = async () => {
      const isLoggedIn = await isSessionAuthenticated();

      if (isLoggedIn) {
        await Auth.signOut();
        removeImpersonate();

        TagManager.dataLayer({
          dataLayer: GenerateGTMArgs("loggedOut"),
        });

        dispatch(logout());
      }

      navigate("/signin");
    };

    logoutSession();
  }, []);

  return <></>;
};
