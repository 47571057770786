import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import LinkElment from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { StageTopContent } from "../../components/project/stageTopContent";
import { StageBottomContent } from "../../components/project/stageBottomContent";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";
import { ListLayout } from "../../components/project/listLayout";
import { useAppSelector } from "../../store/hook";
import { ListIcon } from "../../components/project/listIcon";
import { SubListIcon } from "../../components/project/subListIcon";
import extendedProjectsCopy from "../../constants/extendedProjectsCopy";
import useFaq from "../../hooks/useFAQ";

export const ThirdPartyInspections = () => {
  const { id, tracker, fields, velocity, isBatteryOnly } = useAppSelector(
    (state) => state.portal || ({} as any)
  );
  const [ptoDocument, setPtoDocument] = useState(null) as any;
  const [schedules, setSchedules] = useState([]) as any;
  const [completedSchedules, setCompletedSchedules] = useState([]) as any;
  const [complete, setComplete] = useState(false) as any;
  const [inspectionComplete, setInspectionComplete] = useState(false) as any;
  const [steps, setSteps] = useState([]) as any;

  const { groupFaqProjectVisible } = useFaq();
  const faqs = groupFaqProjectVisible("thirdPartyInspections");

  useEffect(() => {
    if (tracker && tracker.thirdPartyInspections) {
      setComplete(_.get(tracker.thirdPartyInspections, "complete", false));
      const stageSteps: any = _.sortBy(
        _.get(tracker.thirdPartyInspections, "steps", []),
        "stepId"
      );

      const utility = _.find(stageSteps, (s) => s.textKey === "utility");
      const permissionToOperate = _.find(
        utility.steps,
        (s) => s.textKey === "permission to operate"
      );
      if (
        permissionToOperate &&
        permissionToOperate.valid &&
        permissionToOperate.completed &&
        !_.isEmpty(permissionToOperate.files)
      ) {
        setPtoDocument(permissionToOperate.files[0]);
      }

      setSteps(stageSteps);
      populateSchedules(stageSteps);
    }
  }, []);

  const getDate = (val: string) => {
    if (!val) return null;

    // String should have 10/16/2023 at 01:30 PM format
    // const date = moment(val, "MM/DD/YYYY at LT").local();
    const dateStr = val.match(/(\d{2}\/\d{2}\/\d{4})/);
    const date = moment(dateStr?.[0]).local();
    return date.isValid() ? date : null;
  };

  const getDateUnix = (val: number) => {
    if (!val) return null;

    // String should have 10/16/2023 at 01:30 PM format
    const date = moment.utc(val).local();
    return date.isValid() ? date : null;
  };

  const populateSchedules = (steps: any) => {
    const cityInspectionTime = getDate(
      _.get(fields, "city_inspection_time", null)
    );
    const utilityInspectionTime = getDate(
      _.get(fields, "utility_inspection_time", null)
    );

    const cityInspection = _.find(steps, (s) => s.textKey === "city");
    const utilityInspection = _.find(steps, (s) => s.textKey === "utility");

    const cityInspectionComplete =
      !cityInspection.valid ||
      (cityInspection.valid && cityInspection.completed);
    const utilityInspectionComplete =
      !utilityInspection.valid ||
      (utilityInspection.valid && utilityInspection.completed);

    setInspectionComplete(cityInspectionComplete && utilityInspectionComplete);

    const scheduleCityInspectionComplete = _.every(
      cityInspection.steps,
      (s) => {
        // return true if step is not valid or complete inspection step
        if (
          !s.valid ||
          !["schedule city inspection", "reschedule city inspection"].includes(
            s.textKey
          )
        )
          return true;
        return s.completed;
      }
    );

    const scheduleUtilityInspectionComplete = _.every(
      utilityInspection.steps,
      (s) => {
        // return true if step is not valid or complete inspection step
        if (
          !s.valid ||
          ![
            "schedule utility inspection",
            "reschedule utility inspection",
          ].includes(s.textKey)
        )
          return true;
        return s.completed;
      }
    );

    const dates = [];

    if (
      !cityInspection.completed &&
      scheduleCityInspectionComplete &&
      cityInspectionTime
    ) {
      dates.push({
        title: "City inspection",
        text: cityInspectionTime.clone().format("dddd, MMMM D"),
        // time: cityInspectionTime.clone().format("(LT)"),
        timestamp: cityInspectionTime.clone().valueOf(),
      });
    }

    if (
      !utilityInspection.completed &&
      scheduleUtilityInspectionComplete &&
      utilityInspectionTime
    ) {
      dates.push({
        title: "Utility inspection",
        text: utilityInspectionTime.clone().format("dddd, MMMM D"),
        // time: utilityInspectionTime.clone().format("(LT)"),
        timestamp: utilityInspectionTime.clone().valueOf(),
      });
    }

    setSchedules(_.sortBy(dates, "timestamp"));

    const completeCityInspection = _.find(
      cityInspection.steps,
      (s) => s.textKey === "complete city inspection"
    );
    const completeUtilityInspection = _.find(
      utilityInspection.steps,
      (s) => s.textKey === "complete utility inspection"
    );

    const cityInspectionCompletedDate = getDateUnix(
      _.get(completeCityInspection, "task.completed_at", null)
    );
    const utilityInspectionCompletedDate = getDateUnix(
      _.get(completeUtilityInspection, "task.completed_at", null)
    );

    const completedDates = [];

    if (
      _.get(completeCityInspection, "completed", false) &&
      cityInspectionCompletedDate
    ) {
      completedDates.push({
        title: "City inspection",
        text: cityInspectionCompletedDate.clone().format("dddd, MMMM D"),
        timestamp: cityInspectionCompletedDate.clone().valueOf(),
      });
    }

    if (
      _.get(completeUtilityInspection, "completed", false) &&
      utilityInspectionCompletedDate
    ) {
      completedDates.push({
        title: "Utility inspection",
        text: utilityInspectionCompletedDate.clone().format("dddd, MMMM D"),
        timestamp: utilityInspectionCompletedDate.clone().valueOf(),
      });
    }
    setCompletedSchedules(_.sortBy(completedDates, "timestamp"));
  };

  const stageCopy: any = {
    ...extendedProjectsCopy,
    city: "City",
    "schedule city inspection": "Schedule city inspection",
    "reschedule city inspection": "Reschedule city inspection",
    "complete city inspection": "Complete city inspection",
    utility: "Utility",
    "schedule utility inspection": "Schedule utility inspection",
    "reschedule utility inspection": "Reschedule utility inspection",
    "complete utility inspection": "Complete utility inspection",
    "permission to operate": (
      <>
        Receive{" "}
        {ptoDocument ? (
          <LinkElment
            component={Link}
            target="_blank"
            to={`${process.env.REACT_APP_SUNRISE_PREVIEW_FILES_URL}/${ptoDocument._id}/${ptoDocument.name}`}
          >
            Permission to Operate
          </LinkElment>
        ) : (
          "Permission to Operate"
        )}
      </>
    ),
    "commission system": "Commission system",
  };
  const reschedulePendingStage = [
    "reschedule city inspection",
    "reschedule utility inspection",
  ];
  const stageTooltipCopy: any = {
    "commission system": isBatteryOnly
      ? "Commissioning ensures your battery is functioning optimally"
      : "Commissioning ensures your panels are functioning optimally before your system officially launches",
  };

  const keySteps = _.map(steps, (step) => ({
    valid: _.get(step, "valid", false),
    collapse: _.get(step, "completed", false),
    icon: (
      <ListIcon
        checked={_.get(step, "completed", false)}
        errorDanger={
          step.textKey.includes("extended project") &&
          !_.get(step, "completed", false)
        }
      />
    ),
    title: _.get(stageCopy, [step.textKey], "--"),
    tooltip: _.get(stageTooltipCopy, [step.textKey], null),
    timeline: _.isEmpty(step.steps)
      ? null
      : _.map(step.steps, (subStep) => ({
          valid: _.get(subStep, "valid", false),
          icon: (
            <SubListIcon
              checked={_.get(subStep, "completed", false)}
              errorDanger={
                !_.get(subStep, "completed", false) &&
                reschedulePendingStage.includes(subStep.textKey)
              }
            />
          ),
          text: _.get(stageCopy, [subStep.textKey], "--"),
        })),
  }));

  const displayVelocity = velocity?.thirdPartyInspections
    ? `${velocity?.thirdPartyInspections?.min}-${velocity?.thirdPartyInspections?.max}`
    : "4-6";

  return (
    <>
      <StageTopContent
        stage={tracker.thirdPartyInspections}
        headerText="Third-Party Inspections"
        info={
          complete
            ? `Completed: ${moment
                .utc(tracker.thirdPartyInspections?.task?.completed_at)
                .local()
                .format("MM/DD/YYYY")}`
            : `Usually takes ${displayVelocity} weeks`
        }
        subHeaderText={
          isBatteryOnly
            ? "We schedule any on-site inspections required by your local government or utility provider to confirm that your newly installed battery meets all regulations for safety and reliability."
            : "We schedule any on-site inspections required by your local government or utility provider to confirm that your newly installed solar system meets all regulations for safety and reliability."
        }
      >
        {!complete &&
          !inspectionComplete &&
          _.isEmpty(schedules) &&
          _.isEmpty(completedSchedules) && (
            <Requirements>
              <Requirement
                headText="Third-party inspections"
                subText="Inspections pending. We will update this step with your scheduled inspections."
              />
            </Requirements>
          )}
        {!complete && !_.isEmpty(schedules) && (
          <Requirements
            note={
              <>
                Please note, third-party inspections cannot be rescheduled.
                Contact{" "}
                <LinkElment
                  component={Link}
                  to="mailto:customerservice@freedomsolarpower.com"
                >
                  Customer Care
                </LinkElment>{" "}
                if you need assistance.
              </>
            }
          >
            {schedules.map((sched: any, i: number) => (
              <Requirement
                key={`schedules-${i}`}
                headText={sched.title}
                subText={
                  <Typography variant="body1" color="textSecondary">
                    {sched.text}
                    {/* <Typography component="span" color="secondary.light">
                      {sched.time}
                    </Typography> */}
                  </Typography>
                }
              />
            ))}
          </Requirements>
        )}
        {!_.isEmpty(completedSchedules) && completedSchedules.length === 2 && (
          <Requirements isComplete>
            {completedSchedules.map((sched: any, i: number) => (
              <Requirement
                key={`completedSchedules-${i}`}
                headText={sched.title}
                subText={
                  <Typography variant="body1" color="textSecondary">
                    {"Completed " + sched.text}
                  </Typography>
                }
              />
            ))}
          </Requirements>
        )}
      </StageTopContent>
      <StageBottomContent>
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key Steps"
          listType="accordionIcon"
          validate
          listData={keySteps}
        />
        <ListLayout
          width="calc(50% - 1.5rem)"
          title="Key People"
          listType="simple"
          listData={[
            "Freedom Solar Scheduling Team",
            "Local Government Inspector",
            "Utility Inspector",
          ]}
        />
        <ListLayout
          width="100%"
          title="On-Site Visit Reminders"
          listType="simple"
          listData={[
            "Average time on-site: 30 minutes-1 hour",
            <>
              If your property has a gate code, add it to your{" "}
              <LinkElment component={Link} to={`/${id}/account`}>
                Account
              </LinkElment>
            </>,
            "Clear your driveway to give our site assessor space to park",
            ...(isBatteryOnly
              ? []
              : [
                  "We'll need access to your garage, attic, and electrical panel. You can help speed things up by removing obstructions before we arrive",
                ]),
            "Put away any large pets—we love dogs but we want to keep your pets and our site assessor safe!",
          ]}
        />
        <ListLayout
          width="100%"
          title="FAQs"
          listType="accordionFaq"
          listData={faqs}
        />
      </StageBottomContent>
    </>
  );
};
