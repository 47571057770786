import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleLogo from "../../assets/icons/google.svg";
import FbLogo from "../../assets/icons/fb.svg";
import MyFreedom from "../../assets/images/myFreedom.png";
import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultData = {
  email: "",
  password: "",
};

const errMsgs: any = {
  UserNotFoundException: (
    <>
      We cannot find an account with that email address. Please verify
      your credentials and try again. If you believe you have reached this
      message in error, please connect with our Customer Care team at 1
      (833) 500-0014.
      <br />
      <br />
      Not a customer and ready to go solar for as little as $99/month?{" "}
      <a
        href="https://freedomsolarpower.com/lp/glp/solar-estimate"
        className="link-orange"
      >
        Click here
      </a>{" "}
      to get started.
    </>
  ),
  NotAuthorizedException: "The password you entered is incorrect.",
  FieldEmpty: "Email and Password cannot be empty!",
  UserNotMatchException: (
    <>
      This email doesn’t match our records. The security of your
      information is important to us, so only a verified email can create
      an account in myFreedom. If you're unsure of the email or need to
      change it, please contact our{" "}
      <a
        href="mailto:customerservice@freedomsolarpower.come"
        className="link-orange"
      >
        Customer Care
      </a>{" "}
      team.
      <br />
      <br />
      Not a customer and ready to go solar for as little as $99/month?{" "}
      <a
        href="https://freedomsolarpower.com/lp/glp/solar-estimate"
        className="link-orange"
      >
        Click here
      </a>{" "}
      to get started.
    </>
  ),
};

export const AuthContent = ({ setResetPassword }: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState(defaultData);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null) as any;

  useEffect(() => {
    const err: string = searchParams.get('err') as string;
    console.log({err})
    const errMsg = _.get(errMsgs, [err]);
    if (errMsg) {
      setLoginError({ type: err, msg: errMsg });
    }
  }, []);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setLoginError(null);
      const { email, password } = formData;

      await Auth.signIn(email, password);

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("loggedIn", { login_method: "Email" }),
      });

      navigate("/");
      return;
    } catch (error: any) {
      setIsLoading(false);

      const errorCode = error?.code || "FieldEmpty";
      setLoginError({ type: errorCode, msg: _.get(errMsgs, [errorCode]) });
    }
  };

  return (
    <>
      <Stack
        gap={3}
        textAlign="center"
        alignItems="center"
        sx={{ width: "340px", maxWidth: "100%" }}
      >
        <img src={MyFreedom} alt="new logo" loading="lazy" width="70%" />
        <Typography variant="h4" color="textPrimary" fontWeight={600}>
          Welcome back
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Log in to your Freedom Solar account
        </Typography>
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap={6}
      >
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <Stack width="100%" gap={2}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  !_.isEmpty(formData.email) &&
                  !_.isEmpty(formData.password)
                ) {
                  handleSubmit();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            color="text.light"
          >
            <Button
              variant="text"
              color="inherit"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </Button>
          </Stack>
        </Stack>
        {loginError && (
          <Stack
            sx={{
              width: "100%",
              borderRadius: "8px",
              padding: "18px 20px",
              border: "1px solid #E8EBED",
              borderLeft: "3px solid #D32F2F",
            }}
          >
            <Typography
              sx={{
                fontSize:
                  loginError?.type === "UserNotFoundException"
                    ? "12px"
                    : "14px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                color: "#3D4D5C",
              }}
            >
              {loginError?.msg}
            </Typography>
          </Stack>
        )}

        <Button
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={isLoading}
          fullWidth
          size="large"
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Login
        </Button>
      </Stack>

      <Stack
        direction="row"
        gap={4.5}
        alignItems="center"
        justifyContent="center"
        width="327px"
        maxWidth="100%"
      >
        <Divider sx={{ width: "137px", height: "2px" }} />
        <Typography variant="body1" color="textPrimary">
          or
        </Typography>
        <Divider sx={{ width: "137px", height: "2px" }} />
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <Button
          variant="contained"
          color="secondary"
          startIcon={<img src={GoogleLogo} alt="google logo" loading="lazy" />}
          fullWidth
          size="large"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: GenerateGTMArgs("loggedIn", {
                login_method: "Google",
              }),
            });
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Google,
            });
          }}
        >
          Sign in with Google
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<img src={FbLogo} alt="fb logo" loading="lazy" />}
          fullWidth
          size="large"
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: GenerateGTMArgs("loggedIn", {
                login_method: "Facebook",
              }),
            });
            Auth.federatedSignIn({
              provider: CognitoHostedUIIdentityProvider.Facebook,
            });
          }}
        >
          Sign in with Facebook
        </Button>
      </Stack>
    </>
  );
};
