import createTheme from "@mui/material/styles/createTheme";
import { typography } from "./typography";
import { primary, gray, error, warning, success } from "./colors";

const themeOptions: any = {
  palette: {
    primary: {
      main: primary[400],
      light: primary[300],
      dark: primary[500],
      contrastText: gray[0],
    },
    secondary: {
      main: gray[50],
      contrastText: gray[800],
      // main: gray[800],
      light: gray[500],
      // dark: gray[900],
      // contrastText: gray[0],
    },
    error: {
      main: error[400],
      light: error[300],
      dark: error[500],
      contrastText: gray[0],
    },
    warning: {
      main: warning[400],
      light: warning[300],
      dark: warning[500],
      contrastText: gray[0],
    },
    success: {
      main: success[400],
      light: success[300],
      dark: success[500],
      contrastText: gray[0],
    },
    text: {
      primary: gray[800],
      secondary: gray[700],
      light: gray[600],
      white: gray[0],
      dark: gray[900],
    },
  },
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontSize: "1rem",
          fontWeight: "600",
          lineHeight: "1.5rem",
          textTransform: "none",
          minWidth: "unset",
          padding: "12px 24px",
          display: "flex",
          gap: "0.5rem",
          "&:hover": {
            boxShadow: "none",
          },
          "& .MuiButton-startIcon": {
            margin: 0,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${primary[400]}`,
          "&:hover": {
            color: primary[500],
            border: `1px solid ${primary[500]}`,
          },
        },
        textPrimary: {
          "&:hover": {
            color: primary[500],
          },
        },
        outlinedSecondary: {
          "&:hover": {
            color: gray[900],
          },
        },
        textSecondary: {
          "&:hover": {
            color: gray[900],
          },
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            height: "40px",
          },
        },
        {
          props: { size: "medium" },
          style: {
            height: "48px",
          },
        },
        {
          props: { size: "large" },
          style: {
            height: "56px",
          },
        },
        {
          props: { variant: "text" },
          style: {
            height: "30px",
            padding: "8px",
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: {
            small: "16px",
            medium: "24px",
            large: "40px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: gray[50],
          height: "100%",
          color: gray[800],
          fontSize: "1.125rem",
          lineHeight: "1.75rem",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: gray[50],
          },
          "&.MuiInputBase-readOnly, &.MuiInputBase-readOnly *": {
            cursor: "default",
            color: gray[500],
          },
          "&.MuiInputBase-readOnly .MuiOutlinedInput-notchedOutline": {
            borderColor: gray[50],
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: gray[50],
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: error[500],
          },
          "&:hover:not(.Mui-disabled):not(.MuiInputBase-readOnly)": {
            background: gray[200],
          },
          "&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):not(.MuiInputBase-readOnly) .MuiOutlinedInput-notchedOutline":
            {
              borderColor: gray[200],
            },
          "&.MuiInputBase-readOnly:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: gray[50],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
          },
          "& .MuiSvgIcon-root, &:hover .MuiSvgIcon-root": {
            color: gray[500],
          },
          "&.Mui-disabled .MuiSvgIcon-root, &.Mui-disabled .MuiTypography-root":
            {
              color: gray[300],
            },
          "&.Mui-focused:not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiSvgIcon-root, &.Mui-focused:not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiTypography-root, &:has(input:not(:placeholder-shown):not(:disabled)):not(.Mui-error):not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiSvgIcon-root, &:has(input:not(:placeholder-shown):not(:disabled)):not(.Mui-error):not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiTypography-root, &:has(textarea:first-of-type:not(:placeholder-shown):not(:disabled)):not(.Mui-error):not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiSvgIcon-root, &:has(textarea:first-of-type:not(:placeholder-shown):not(:disabled)):not(.Mui-error):not(.MuiInputBase-readOnly) .MuiInputAdornment-positionStart .MuiTypography-root":
            {
              color: primary[400],
            },
          "&.Mui-error .MuiInputAdornment-positionStart .MuiSvgIcon-root, &.Mui-error .MuiTypography-root":
            {
              color: error[500],
            },
          "&.MuiInputBase-multiline": {
            alignItems: "unset",
          },
          "&.MuiInputBase-multiline .MuiInputAdornment-positionStart": {
            marginTop: "15px",
          },
          "&.MuiInputBase-multiline .MuiInputAdornment-positionEnd": {
            marginTop: "auto",
            marginBottom: "15px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&:has(.MuiInputBase-readOnly) .MuiFormLabel-root": {
            color: gray[600],
          },
          "&.MuiInputBase-readOnly:hover .MuiOutlinedInput-root": {
            background: gray[50],
            borderColor: gray[50],
          },
          "&.MuiInputBase-readOnly .MuiInputBase-root:hover": {
            background: `${gray[50]} !important`,
          },
          "&.MuiInputBase-readOnly .MuiOutlinedInput-root *": {
            cursor: "default",
            color: `${gray[500]} !important`,
          },
          "&.MuiInputBase-readOnly .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, &.MuiInputBase-readOnly .MuiOutlinedInput-root:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):not(.MuiInputBase-readOnly)  .MuiOutlinedInput-notchedOutline":
            {
              borderColor: gray[50],
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.3rem",
          lineHeight: "1.5rem",
          color: gray[600],
          "&.Mui-error": {
            color: error[500],
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "1.5rem",
          marginTop: "0.5rem",
          marginLeft: 0,
          marginRight: 0,
          color: gray[600],
          "&.Mui-error": {
            color: error[500],
          },
        },
      },
    },
    MuiTextareaAutosize: {
      styleOverrides: {
        root: {
          background: "red",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          color: gray[900],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          color: gray[700],
        },
        standardInfo: {
          backgroundColor: primary[50],
          border: `1px solid ${primary[100]}`,
          "& .MuiAlert-icon": { color: primary[400] },
          "& > div:last-child button": {
            color: primary[400],
          },
        },
        filledInfo: {
          backgroundColor: primary[50],
          border: `1px solid ${primary[100]}`,
          "& .MuiAlert-icon": { color: primary[400] },
          "& > div:last-child button": {
            color: primary[400],
          },
        },
        outlinedInfo: {
          borderColor: primary[100],
          "& .MuiAlert-icon": { color: primary[400] },
          "& > div:last-child button": {
            color: primary[400],
          },
        },
        standardError: {
          backgroundColor: error[50],
          border: `1px solid ${error[100]}`,
          "& .MuiAlert-icon": { color: error[400] },
          "& > div:last-child button": {
            color: error[400],
          },
        },
        filledError: {
          backgroundColor: error[50],
          border: `1px solid ${error[100]}`,
          "& .MuiAlert-icon": { color: error[400] },
          "& > div:last-child button": {
            color: error[400],
          },
        },
        outlinedError: {
          borderColor: error[100],
          "& .MuiAlert-icon": { color: error[400] },
          "& > div:last-child button": {
            color: error[400],
          },
        },
        standardWarning: {
          backgroundColor: warning[50],
          border: `1px solid ${warning[100]}`,
          "& .MuiAlert-icon": { color: warning[400] },
          "& > div:last-child button": {
            color: warning[400],
          },
        },
        filledWarning: {
          backgroundColor: warning[50],
          border: `1px solid ${warning[100]}`,
          "& .MuiAlert-icon": { color: warning[400] },
          "& > div:last-child button": {
            color: warning[400],
          },
        },
        outlinedWarning: {
          borderColor: warning[100],
          "& .MuiAlert-icon": { color: warning[400] },
          "& > div:last-child button": {
            color: warning[400],
          },
        },
        standardSuccess: {
          backgroundColor: success[50],
          border: `1px solid ${success[100]}`,
          "& .MuiAlert-icon": { color: success[400] },
          "& > div:last-child button": {
            color: success[400],
          },
        },
        filledSuccess: {
          backgroundColor: success[50],
          border: `1px solid ${success[100]}`,
          "& .MuiAlert-icon": { color: success[400] },
          "& > div:last-child button": {
            color: success[400],
          },
        },
        outlinedSuccess: {
          borderColor: success[100],
          "& .MuiAlert-icon": { color: success[400] },
          "& > div:last-child button": {
            color: success[400],
          },
        },
      },
      variants: [
        {
          props: { variant: "custom" },
          style: {
            background: "red",
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: gray[400],
          "& ~ .MuiFormControlLabel-label": {
            color: gray[900],
          },
          "&:hover": {
            color: primary[600],
          },
          "&:focus": {
            color: primary[400],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: gray[400],
          "& ~ .MuiFormControlLabel-label": {
            color: gray[900],
          },
          "&:hover": {
            color: primary[600],
          },
          "&:focus": {
            color: primary[400],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 41,
          height: 20,
          padding: 0,
          margin: "9px",
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",
          "&.Mui-checked": {
            transform: "translateX(21px)",
            color: gray[0],
            "& + .MuiSwitch-track": {
              backgroundColor: primary[400],
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: gray[100],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
          "&:hover  + .MuiSwitch-track": {
            backgroundColor: primary[500],
          },
        },
        thumb: {
          boxSizing: "border-box",
          width: 16,
          height: 16,
        },
        track: {
          borderRadius: "100px",
          backgroundColor: gray[400],
          opacity: 1,
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            width: "34px",
            height: "16px",
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: 2,
              "&.Mui-checked": {
                transform: "translateX(18px)",
              },
            },
            "& .MuiSwitch-thumb": {
              width: "12px",
              height: "12px",
            },
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          color: gray[800],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineheight: "1.5rem",
          fontWeight: 400,
          color: gray[600],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor: gray[50],
          "& .MuiSvgIcon-root, & .MuiChip-icon": {
            color: gray[500],
            width: "1.25rem",
            height: "1.25rem",
          },
          "&.MuiChip-clickableColorDefault, &.MuiChip-colorPrimary": {
            backgroundColor: primary[50],
          },
          "&.MuiChip-clickableColorDefault .MuiChip-label, &.MuiChip-clickableColorDefault .MuiChip-deleteIcon, &.MuiChip-colorPrimary .MuiChip-label, &.MuiChip-colorPrimary .MuiSvgIcon-root":
            {
              color: primary[400],
            },
        },
        label: {
          fontSize: "1rem",
          lineHeight: "1.5rem",
          fontWeight: "400",
          color: gray[500],
        },
      },
      variants: [
        {
          props: { color: "success" },
          style: {
            backgroundColor: success[50],
            "& .MuiSvgIcon-root, & .MuiChip-icon": {
              color: success[500],
            },
            "& .MuiChip-label": {
              color: success[500],
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: gray[50],
          color: gray[800],
          fontSize: "0.875rem",
          lineheight: "1.25rem",
          fontWeight: "400",
          borderRadius: "4px",
          padding: "1rem 1.5rem",
          "&.dark": {
            backgroundColor: gray[800],
            color: gray[0],
          },
        },
        arrow: {
          color: gray[50],
          "&.dark": {
            color: gray[800],
          },
        },
      },
      // defaultProps: {
      //   leaveDelay: 2500, // 2.5s
      // },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: "280px",
          flexShrink: 0,
        },
        paper: {
          width: "280px",
          boxSizing: "border-box",
          background: gray[800],
          color: gray[0],
          padding: "40px 24px 24px 24px",
          gap: "64px",
        },
      },
    },
  },
  shadows: [
    "none",
    "0px 1px 4px rgba(9, 32, 76, 0.1)",
    "0px 2px 6px rgba(9, 32, 76, 0.1)",
    "0px 2px 8px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 8px rgba(9, 32, 76, 0.12)",
    "0px 6px 10px rgba(9, 32, 76, 0.12)",
    ...createTheme().shadows.slice(5),
  ],
  shape: {
    borderRadius: 8,
  },
  spacing: (value: number) => `${value * 0.25}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
};

export const customSelectDropdown = {
  MenuProps: {
    PaperProps: {
      sx: {
        marginTop: 2,
      },
    },
    MenuListProps: {
      sx: {
        backgroundColor: gray[50],
        "& .MuiMenuItem-root": {
          backgroundColor: "transparent",
          borderBottom: `1px solid ${gray[100]}`,
          padding: "0.75rem 1rem",
        },
        "& .MuiMenuItem-root:last-of-type": {
          borderBottom: "none",
        },
        "&.dark": {
          backgroundColor: gray[700],
        },
        "&.dark .MuiMenuItem-root": {
          backgroundColor: "transparent",
          borderBottom: `1px solid ${gray[800]}`,
          padding: "0.75rem 1rem",
        },
        "&.dark .MuiMenuItem-root:last-of-type": {
          borderBottom: "none",
        },
      },
    },
  },
};

export const customDarkSelectDropdown = {
  MenuProps: {
    PaperProps: {
      sx: {
        marginTop: 2,
      },
    },
    MenuListProps: {
      sx: {
        backgroundColor: gray[700],
        "& .MuiMenuItem-root": {
          backgroundColor: "transparent",
          borderBottom: `1px solid ${gray[800]}`,
          padding: "0.75rem 1rem",
          color: gray[0],
        },
        "& li.MuiMenuItem-root.Mui-selected": {
          backgroundColor: "transparent",
          color: primary[400],
        },
        "& .MuiMenuItem-root:last-of-type": {
          borderBottom: "none",
        },
      },
    },
  },
};

export const customTheme = createTheme(themeOptions);

customTheme.typography.body2 = {
  [customTheme.breakpoints.up("md")]: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: "inherit",
  },
};
