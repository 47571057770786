import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import Link from "@mui/material/Link";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const ForgotPasswordContent = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  const handleSubmit = async (resend = false) => {
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      setResetSent(true);

      if (resend) {
        enqueueSnackbar("Reset password email sent!", {
          variant: "default",
          onClose: () => {},
          action: (key) => (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ),
        });
      }
    } catch (err: any) {
      const errMsg =
        err?.code === "UserNotFoundException"
          ? "Account created using social login or user not registered."
          : "Please try again!";

      enqueueSnackbar(`Failed to send reset password email. ${errMsg}`, {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
      // setEmail('');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <>
      {!resetSent && (
        <>
          <Stack gap={3} textAlign="center">
            <Typography variant="h4" color="textPrimary" fontWeight={600}>
              Reset password
            </Typography>
          </Stack>

          <Typography
            variant="body1"
            color="textPrimary"
            textAlign="center"
            sx={{
              width: "450px",
              maxWidth: "100%",
              color: "#272727",
            }}
          >
            If you created your account using Google or Facebook, your password
            cannot be reset here.
          </Typography>

          <Stack
            width="450px"
            maxWidth="100%"
            alignItems="center"
            justifyContent="center"
            gap={6}
          >
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isLoading}
              />
            </FormControl>
          </Stack>
        </>
      )}

      {resetSent && (
        <>
          <i
            className="i-verified-check"
            style={{
              width: "11rem",
              minWidth: "11rem",
              height: "9.5rem",
            }}
          />
          <Stack
            gap={4}
            sx={{
              width: "664px",
              maxWidth: "100%",
            }}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" color="textPrimary" fontWeight={600}>
              We’ve emailed you a password reset link
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              If there is a Freedom Solar account associated with this email
              address, you will receive a password rest link. If you haven’t
              received the reset link in five minutes,{" "}
              <Link
                color="primary"
                href="#"
                sx={{
                  textDecoration: "none",
                  fontWeight: 600,
                }}
                onClick={() => handleSubmit(true)}
              >
                click here
              </Link>{" "}
              to resend or contact{" "}
              <Link
                color="primary"
                href="mailto:customerservice@freedomsolarpower.com"
                sx={{
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                Customer Care
              </Link>{" "}
              for help.
            </Typography>
          </Stack>
        </>
      )}

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent={{
          xs: resetSent ? "flex-end" : "space-between",
          sm: "center",
        }}
        gap={6}
        flex={{
          xs: "auto",
          sm: "inherit",
        }}
        paddingBottom={{
          xs: 6,
          sm: 0,
        }}
      >
        {!resetSent && (
          <Button
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={isLoading}
            fullWidth
            size="large"
          >
            {isLoading && (
              <CircularProgress color="inherit" size={16} thickness={4.5} />
            )}
            Send me a reset link
          </Button>
        )}

        <Button variant="text" onClick={() => navigate("/signin")}>
          Back to login
        </Button>
      </Stack>
    </>
  );
};
