import { useDeviceData } from "react-device-detect";

export const GenerateGTMArgs = (action, options = {}) => {
  const { device, os } = useDeviceData(window.navigator.userAgent);

  const currentTimeStamp = Date.now();
  const platform_type = os.name;
  const device_type = device.type
    ? `${device.model} (${device.type})`
    : device.model;

  switch (action) {
    case "signedUpEmail":
      return {
        event: "signed_up_email",
        // email: options?.email,
        signup_at: currentTimeStamp,
      };
    case "signedUpSuccess":
      return {
        event: "signed_up_success",
        // user_id: options?.user_id,
        // first_name: options?.first_name,
        // last_name: options?.last_name,
        // email: options?.email,
        // phone: options?.phone,
        auth_method: options?.auth_method,
        signup_at: currentTimeStamp,
      };
    case "loggedIn":
      return {
        event: "logged_in",
        // login_method: options?.login_method, - old
        // login_platform: platform_type, - old
        auth_method: options?.login_method,
        platform_type,
        logged_in_at: currentTimeStamp,
      };
    case "loggedOut":
      return {
        event: "logged_out",
        // logout_platform: platform_type, - old
        platform_type,
        logged_out_at: currentTimeStamp,
      };
    case "emailRequestCode":
      return {
        event: "email_request_code",
        // email: options?.email,
        requested_at: currentTimeStamp,
      };
    case "emailVerification":
      return {
        event: "email_verification",
        // email: options?.email,
        verified_at: currentTimeStamp,
      };
    case "phoneRequestCode":
      return {
        event: "phone_request_code",
        // phone: options?.phone,
        requested_at: currentTimeStamp,
      };
    case "phoneVerification":
      return {
        event: "phone_verification",
        // phone: options?.phone,
        verified_at: currentTimeStamp,
      };
    case "accCreationPage5":
      return {
        event: "secondary_contact",
        // first_name: options?.secondary_contact_first_name,
        // last_name: options?.secondary_contact_last_name,
        // email: options?.secondary_contact_email,
        // phone: options?.secondary_contact_phone,
        continue_clicked: options?.page5_continue_clicked,
        skip_clicked: options?.page5_skip_clicked,
      };
    case "accCreationPage6":
      return {
        event: "property_details",
        // gate_code: options?.gate_code_added,
        continue_clicked: options?.page6_continue_clicked,
        no_clicked: options?.page6_no_gate_code_clicked,
        skip_clicked: options?.page6_skip_clicked,
      };
    case "accCreationPage7":
      return {
        event: "wifi_details",
        // wifi_network: options?.wifi_network_added,
        // wifi_pw: options?.wifi_pw_added,
        continue_clicked: options?.page7_continue_clicked,
        skip_clicked: options?.page7_skip_clicked,
      };
    case "accCreationPage8":
      return {
        event: "hoa_details",
        // hoa_name: options?.hoa_name_added,
        // email: options?.hoa_email_added,
        // phone: options?.hoa_phone_added,
        // website: options?.hoa_website_added,
        // username: options?.hoa_username_added,
        // password: options?.hoa_password_added,
        continue_clicked: options?.page8_continue_clicked,
        no_clicked: options?.page8_no_hoa_clicked,
        skip_clicked: options?.page8_skip_clicked,
      };
    case "pageView":
      return {
        event: "page_view_session",
        // user_id: options?.user_id,
        page_session_length: options?.page_session_length,
        page_name: options?.page_name,
        platform_type,
        device_type,
        page_viewed_at: currentTimeStamp,
      };
    case "buttonClick":
      return {
        event: "button_click",
        // user_id: options?.user_id,
        button_name: options?.button_name,
        button_clicked_at: currentTimeStamp,
        platform_type,
        device_type,
      };
    default:
      return {};
  }
};
