import _ from "lodash";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const Requirement = ({
  headText,
  subText,
  cta,
  note,
}: any) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stetch"
        gap={5}
        flexWrap={{ xs: "wrap", sm: "nowrap" }}
      >
        <Stack
          direction="row"
          gap={5}
          sx={{
            "&:before": {
              content: "''",
              width: "4px",
              borderRadius: "4px",
              backgroundColor: "#F98A3C",
            },
          }}
        >
          <Stack gap={1} justifyContent="center">
            {headText && _.isString(headText) ? (
              <Typography variant="body1" color="textPrimary" fontWeight={600}>
                {headText}
              </Typography>
            ) : headText}
            {subText && _.isString(subText) ? (
              <Typography variant="body1" color="textSecondary">
                {subText}
              </Typography>
            ) : subText}
          </Stack>
        </Stack>
        {note && (
        <Typography variant="body2" color="textSecondary" display={{
          xs: "block",
          sm: "none"
        }}>
          {note}
        </Typography>
      )}
        <Stack
          alignItems="flex-end"
          direction="row"
          width={{ xs: "100%", sm: "max-content" }}
        >
          {cta}
        </Stack>
      </Stack>
      {note && (
        <Typography variant="body2" color="textSecondary" display={{
          xs: "none",
          sm: "block"
        }}>
          {note}
        </Typography>
      )}
    </>
  );
};
