import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import HouseIcon from "@mui/icons-material/HolidayVillage";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/OpenInBrowser";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { matchIsValidTel } from "mui-tel-input";

import { PhoneInput } from "../../components/shared/phoneInput";
import { ContainerLayout } from "../../components/account/containerLayout";
import { ChangePromptDialog } from "../../components/shared/dialogs/changePrompt";

import { useAppSelector, useAppDispatch } from "../../store/hook";
import { setPortalProps } from "../../store/modules/portalSlice";
import * as api from "../../api";

const defaultValue = {
  name: "",
  email: "",
  phoneNumber: "",
  portalWebsite: "",
  portalUsername: "",
  portalPassword: "",
};

const errorDefault = {
  email: "",
  phoneNumber: "",
};

export const AccountHoaDetails = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id, hoaDetails } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const [formData, setFormData] = useState(defaultValue);

  const [showPassword, setShowPassword] = useState(false);
  const [isContactLoading, setIsContactLoading] = useState(false);
  const [isContactEditing, setIsContactEditing] = useState(false);
  const [errors, setErrors] = useState(errorDefault);
  const [isContactUpdateDisabled, setIsContactUpdateDisabled] = useState(false);

  const [isPortalLoading, setIsPortalLoading] = useState(false);
  const [isPortalEditing, setIsPortalEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [toastShowing, setToastShowing] = useState(false);

  useEffect(() => {
    if (hoaDetails) {
      setFormData({
        name: _.get(hoaDetails, "name", "") || "",
        email: _.get(hoaDetails, "email", "") || "",
        phoneNumber: _.get(hoaDetails, "phoneNumber", "") || "",
        portalWebsite: _.get(hoaDetails, "portalWebsite", "") || "",
        portalUsername: _.get(hoaDetails, "portalUsername", "") || "",
        portalPassword: _.get(hoaDetails, "portalPassword", "") || "",
      });
    }
  }, []);

  useEffect(() => {
    const { email, phoneNumber } = formData;

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phone = `+1${phoneNumber}`;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPhone = "",
        withError = false;

      if (email === "" && phoneNumber === "") {
        withError = true;
      }

      if (!emailPattern.test(email) && email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (phoneNumber !== "" && !matchIsValidTel(phone)) {
        errPhone = "Invalid phone number";
        withError = true;
      }

      if (phoneNumber !== "" && matchIsValidTel(phone)) {
        errPhone = "";
        withError = false;
      }

      setErrors({
        email: errEmail,
        phoneNumber: errPhone,
      });
      setIsContactUpdateDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  useEffect(() => {
    const oldData = {
      name: _.get(hoaDetails, "name", "") || "",
      email: _.get(hoaDetails, "email", "") || "",
      phoneNumber: _.get(hoaDetails, "phoneNumber", "") || "",
      portalWebsite: _.get(hoaDetails, "portalWebsite", "") || "",
      portalUsername: _.get(hoaDetails, "portalUsername", "") || "",
      portalPassword: _.get(hoaDetails, "portalPassword", "") || "",
    };
    setHasChanges(!_.isEqual(oldData, formData));
  }, [formData]);

  const toggleContactEditing = () => {
    const _isEditing = !isContactEditing;
    setIsContactEditing(_isEditing);
    setFormData({
      ...formData,
      name: _.get(hoaDetails, "name", "") || "",
      email: _.get(hoaDetails, "email", "") || "",
      phoneNumber: _.get(hoaDetails, "phoneNumber", "") || "",
    });
  };

  const togglePortalEditing = () => {
    const _isEditing = !isPortalEditing;
    setIsPortalEditing(_isEditing);
    setFormData({
      ...formData,
      portalWebsite: _.get(hoaDetails, "portalWebsite", "") || "",
      portalUsername: _.get(hoaDetails, "portalUsername", "") || "",
      portalPassword: _.get(hoaDetails, "portalPassword", "") || "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContactUpdate = async () => {
    setIsContactLoading(true);
    const payload: any = {
      hoaDetails: {
        ...hoaDetails,
        ...formData,
      },
    };
    await handleSubmit(payload, {
      successMsg: "Changes saved successfully",
      errMsg: "Failed to update HOA details. Please try again!",
    });
    setIsContactLoading(false);
    setIsContactEditing(false);
    setHasChanges(false);
  };

  const handlePortalUpdate = async () => {
    setIsPortalLoading(true);
    const payload: any = {
      hoaDetails: {
        ...hoaDetails,
        ...formData,
      },
    };
    await handleSubmit(payload, {
      successMsg: "Changes saved successfully",
      errMsg: "Failed to update HOA Portal details. Please try again!",
    });
    setIsPortalLoading(false);
    setIsPortalEditing(false);
    setHasChanges(false);
  };

  const handleAllUpdates = async () => {
    setIsContactLoading(true);
    setIsPortalLoading(true);

    const payload: any = {
      hoaDetails: {
        ...hoaDetails,
        ...formData,
      },
    };

    await handleSubmit(payload, {
      successMsg: "Changes saved successfully",
      errMsg: "Failed to update HOA details. Please try again!",
    });

    setIsPortalLoading(false);
    setIsContactLoading(false);
    setIsPortalEditing(false);
    setIsContactEditing(false);
    setHasChanges(false);
  };

  const handleSubmit = async (payload: any, options: any) => {
    try {
      await api.portal.update(id, payload);

      dispatch(setPortalProps(payload));
      setToastShowing(true);
      enqueueSnackbar(options.successMsg, {
        variant: "default",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } catch (error) {
      setToastShowing(true);
      enqueueSnackbar(options.errMsg, {
        variant: "error",
        onClose: () => setToastShowing(false),
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    }
  };

  return (
    <>
      <ContainerLayout
        headerText="HOA Details"
        subHeaderText={
          <>
            We'll communicate with your HOA on your behalf if your state
            requires approval. Looking for your HOA documents? View them in{" "}
            <Link component={RouterLink} to={`/${id}/documents`}>
              Documents
            </Link>
            .
          </>
        }
        cta={
          isContactEditing ? (
            <>
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={toggleContactEditing}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                disabled={isContactLoading}
              >
                <Typography variant="button" color="secondary.light">
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleContactUpdate()}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                disabled={
                  isContactLoading ||
                  isContactUpdateDisabled ||
                  !hasChanges ||
                  !!errors.email ||
                  !!errors.phoneNumber
                }
              >
                {isContactLoading && (
                  <CircularProgress color="inherit" size={16} thickness={4.5} />
                )}
                Update
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              size="small"
              onClick={toggleContactEditing}
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              disabled={toastShowing}
            >
              Edit
            </Button>
          )
        }
        withMobileSubHeaderText
      >
        <Stack gap={9} direction="row" flexWrap="wrap">
          <Box width="100%">
            <FormControl
              variant="outlined"
              sx={{
                width: { xs: "100%", sm: "calc(50% - 1.25rem)" },
              }}
            >
              <TextField
                label="HOA name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isContactEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <HouseIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isContactLoading}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="HOA email"
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isContactEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isContactLoading}
                error={errors.email !== "" ? true : false}
                helperText={errors.email}
              />
            </FormControl>
          </Box>
          <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
            <FormControl variant="outlined" fullWidth>
              <PhoneInput
                value={formData.phoneNumber}
                setValue={(v: any) =>
                  setFormData({ ...formData, phoneNumber: v })
                }
                error={errors.phoneNumber !== ""}
                setError={(e: any) => {
                  setErrors({
                    ...errors,
                    phoneNumber: e ? "Invalid phone number" : "",
                  });
                }}
                readOnly={!isContactEditing}
                disabled={isContactLoading}
              />
            </FormControl>
          </Box>
          <Box width="100%" display={{ xs: "block", sm: "none" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="HOA portal website"
                type="text"
                name="portalWebsite"
                value={formData.portalWebsite}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isContactEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <WebsiteIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isContactLoading}
              />
            </FormControl>
          </Box>
          <Box width="100%" display={{ xs: "block", sm: "none" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="HOA portal username"
                type="text"
                name="portalUsername"
                value={formData.portalUsername}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isContactEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder=" "
                disabled={isContactLoading}
                autoComplete="off"
              />
            </FormControl>
          </Box>
          <Box width="100%" display={{ xs: "block", sm: "none" }}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                name="portalPassword"
                value={formData.portalPassword}
                onChange={handleChange}
                InputProps={{
                  readOnly: !isContactEditing,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                placeholder=" "
                disabled={isContactLoading}
                autoComplete="off"
              />
            </FormControl>
          </Box>
        </Stack>
      </ContainerLayout>
      <Divider sx={{ display: { xs: "none", sm: "block" } }} />
      <Box display={{ xs: "none", sm: "block" }}>
        <ContainerLayout
          headerText="HOA Portal Login"
          cta={
            isPortalEditing ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  onClick={togglePortalEditing}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                  disabled={isPortalLoading}
                >
                  <Typography variant="button" color="secondary.light">
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handlePortalUpdate()}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                  disabled={isPortalLoading || !hasChanges}
                >
                  {isPortalLoading && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      thickness={4.5}
                    />
                  )}
                  Update
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={togglePortalEditing}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
                disabled={toastShowing}
              >
                Edit
              </Button>
            )
          }
        >
          <Stack gap={9} direction="row" flexWrap="wrap">
            <Box width="100%">
              <FormControl
                variant="outlined"
                sx={{
                  width: { xs: "100%", sm: "calc(50% - 1.25rem)" },
                }}
              >
                <TextField
                  label="HOA portal website"
                  type="text"
                  name="portalWebsite"
                  value={formData.portalWebsite}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: !isPortalEditing,
                    startAdornment: (
                      <InputAdornment position="start">
                        <WebsiteIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder=" "
                  disabled={isPortalLoading}
                />
              </FormControl>
            </Box>
            <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="HOA portal username"
                  type="text"
                  name="portalUsername"
                  value={formData.portalUsername}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: !isPortalEditing,
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder=" "
                  disabled={isPortalLoading}
                  autoComplete="off"
                />
              </FormControl>
            </Box>
            <Box width={{ xs: "100%", sm: "calc(50% - 1.25rem)" }}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="portalPassword"
                  value={formData.portalPassword}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: !isPortalEditing,
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                  placeholder=" "
                  disabled={isPortalLoading}
                  autoComplete="off"
                />
              </FormControl>
            </Box>
          </Stack>
          <ChangePromptDialog
            block={hasChanges}
            isLoading={isContactLoading || isPortalLoading}
            onSave={handleAllUpdates}
          />
        </ContainerLayout>
      </Box>
    </>
  );
};
