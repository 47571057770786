import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PasswordRequirements } from "../shared/passwordRequirements";

const defaultData = {
  password: "",
  confirm_password: "",
};

const errorDefault = {
  password: {
    characters: true,
    upperCases: true,
    numbers: true,
    specialCharacters: true,
  },
  confirm_password: "",
};

export const ResetPasswordContent = ({ email, code }: any) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(defaultData);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState(errorDefault);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, formData.password);
      enqueueSnackbar("Password successfully reset", {
        variant: "default",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
      navigate("/signin");
    } catch (err) {
      enqueueSnackbar("Failed change password. Please try again!", {
        variant: "error",
        onClose: () => {},
        action: (key) => (
          <IconButton
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const { password, confirm_password } = formData;

    const characterPattern = /^.{8,}$/;
    const upperCasePattern = /^(?=.*[A-Z]).+$/;
    const numberPattern = /\d/;
    const specialCharacterPattern = /[^a-zA-Z0-9]/;

    const debounce = setTimeout(() => {
      let errPass = {
          characters: true,
          upperCases: true,
          numbers: true,
          specialCharacters: true,
        },
        errConfirmPass = "",
        withError = false;

      if (password === "" || confirm_password === "") {
        withError = true;
      }

      if (password !== "") {
        let passCount = 0;

        if (characterPattern.test(password)) {
          errPass["characters"] = false;
          passCount++;
        }

        if (upperCasePattern.test(password)) {
          errPass["upperCases"] = false;
          passCount++;
        }

        if (numberPattern.test(password)) {
          errPass["numbers"] = false;
          passCount++;
        }

        if (specialCharacterPattern.test(password)) {
          errPass["specialCharacters"] = false;
          passCount++;
        }

        if (passCount !== 4) {
          withError = true;
        }
      }

      if (password !== confirm_password && confirm_password !== "") {
        errConfirmPass = "Passwords do not match";
        withError = true;
      }

      setErrors({
        password: { ...errors.password, ...errPass },
        confirm_password: errConfirmPass,
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  return (
    <>
      <Stack gap={3} textAlign="center">
        <Typography variant="h4" color="textPrimary" fontWeight={600}>
          Create a new password
        </Typography>
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        gap={6}
      >
        <Stack gap={2} width="100%">
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="New password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              error={errors.confirm_password !== "" ? true : false}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
          <PasswordRequirements errors={errors} />
        </Stack>
        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Confirm new password"
            type={showConfirmPassword ? "text" : "password"}
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            error={errors.confirm_password !== "" ? true : false}
            helperText={errors.confirm_password}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
      </Stack>

      <Stack
        width="450px"
        maxWidth="100%"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          sm: "center",
        }}
        gap={6}
        flex={{
          xs: "auto",
          sm: "inherit",
        }}
        paddingBottom={{
          xs: 6,
          sm: 0,
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={isLoading || isDisabled}
          fullWidth
          size="large"
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Reset password
        </Button>
        <Button variant="text" onClick={() => navigate("/signin")}>
          Back to login
        </Button>
      </Stack>
    </>
  );
};
