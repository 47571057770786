import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const SimpleList = ({ listData }: any) => {
  return (
    <Box
      component="ul"
      sx={{
        width: "100%",
        margin: 0,
        paddingLeft: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "& li::marker": { color: "#B4BDC5" },
      }}
    >
      {listData.map((item: any, key: any) => (
        <Box component="li" sx={{ paddingLeft: 4 }} key={`item${key}`}>
          <Typography variant="body1" color="textSecondary">
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
