import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EventIcon from "@mui/icons-material/Event";
import CustomAlertBG from "../../assets/images/alert-gray-bg.svg";

import { gray } from "../../theme/colors";

const RenderTopDetails = ({isComplete, headText, isCanceled}:any) => {
  let copy, icon;

  if(isCanceled) {
    copy = "Your Project is Canceled"
    icon = <CancelRoundedIcon color="primary" />
  } else {
    copy = isComplete ? "Complete" : "Up Next"
    icon = isComplete ? <TaskAltIcon color="primary" /> : <EventIcon color="primary" />
  }

  if (headText) {
    copy = headText
  }

  return (
    <Stack direction="row" gap={4} alignItems="center">
      {icon}
      <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
       {copy}
      </Typography>
    </Stack>
  )
}

export const Requirements = ({ headText, isComplete, isCanceled, note, children }: any) => {
  return (
    <Stack
      sx={{
        background: `url(${CustomAlertBG}) no-repeat top right`,
        backgroundSize: "289px 159.54px",
        backgroundColor: gray[25],
        borderColor: gray[25],
        paddingX: 6,
        paddingY: 4,
        borderRadius: { xs: 0, sm: "8px" },
      }}
      gap={4.5}
    >
      <RenderTopDetails headText={headText} isComplete={isComplete} isCanceled={isCanceled} />
      {children}
      {note && (
        <Typography variant="body2" color="textSecondary">
          {note}
        </Typography>
      )}
    </Stack>
  );
};
