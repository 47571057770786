import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";

import { HeaderContent } from "./headerContent";
import { MiddleContainer } from "./middleContainer";
import { BottomCtaContainer } from "./bottomCtaContainer";
import { PasswordRequirements } from "../shared/passwordRequirements";

import TagManager from "react-gtm-module";
import { GenerateGTMArgs } from "../../utils/gtmArgs";

const defaultData = {
  email: "",
  password: "",
};

const errorDefault = {
  email: "",
  password: {
    characters: true,
    upperCases: true,
    numbers: true,
    specialCharacters: true,
  },
};

export const EmailContent = ({ inviteId }: any) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState(defaultData);
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState(errorDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [showSignupError, setShowSignupError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const { email, password } = formData;

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
        validationData: {
          inviteId, // Pending, not used in BE checking for now
        },
      });

      TagManager.dataLayer({
        dataLayer: GenerateGTMArgs("signedUpEmail", {
          email: email
        }),
      });

      // Store to state
      await Auth.signIn(email, password);

      navigate("/onboarding/email");
      return;
    } catch (error: any) {
      setIsLoading(false);
      if (error.message === "An account with the given email already exists.") {
        setErrors({
          ...errors,
          email: "This email is already in use with another account",
        });
      } else if (
        error.message ===
        "PreSignUp failed with error No invitation found for this email."
      ) {
        setErrors({
          ...errors,
          email: "No invitation found for this email",
        });
      } else {
        setShowSignupError(true);
      }
    }
  };

  useEffect(() => {
    const { email, password } = formData;

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const characterPattern = /^.{8,}$/;
    const upperCasePattern = /^(?=.*[A-Z]).+$/;
    const numberPattern = /\d/;
    const specialCharacterPattern = /[^a-zA-Z0-9]/;

    const debounce = setTimeout(() => {
      let errEmail = "",
        errPass = {
          characters: true,
          upperCases: true,
          numbers: true,
          specialCharacters: true,
        },
        errConfirmPass = "",
        withError = false;

      if (email === "" || password === "") {
        withError = true;
      }

      if (!emailPattern.test(email) && email !== "") {
        errEmail = "Invalid Email";
        withError = true;
      }

      if (password !== "") {
        let passCount = 0;

        if (characterPattern.test(password)) {
          errPass["characters"] = false;
          passCount++;
        }

        if (upperCasePattern.test(password)) {
          errPass["upperCases"] = false;
          passCount++;
        }

        if (numberPattern.test(password)) {
          errPass["numbers"] = false;
          passCount++;
        }

        if (specialCharacterPattern.test(password)) {
          errPass["specialCharacters"] = false;
          passCount++;
        }

        if (passCount !== 4) {
          withError = true;
        }
      }

      setErrors({
        email: errEmail,
        password: { ...errors.password, ...errPass },
      });
      setIsDisabled(withError);
    }, 300);

    return () => clearTimeout(debounce);
  }, [formData]);

  return (
    <>
      <HeaderContent
        handleBack={() => navigate(-1)}
        headerText="Sign up"
        headerVariant="h4"
        withMobileHeaderText={true}
        withMobileBack={true}
        currentStep={0}
      />

      <MiddleContainer>
        {showSignupError && (
          <Alert
            severity="error"
            onClose={() => {
              setShowSignupError(false);
            }}
          >
            Something went wrong. Please try again!
          </Alert>
        )}

        <FormControl variant="outlined" fullWidth>
          <TextField
            label="Email address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            error={errors.email !== "" ? true : false}
            helperText={errors.email}
            placeholder=" "
            disabled={isLoading}
          />
        </FormControl>
        <Stack gap={2}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              placeholder=" "
              disabled={isLoading}
            />
          </FormControl>
          <PasswordRequirements errors={errors} />
        </Stack>
        <Typography
          variant="body1"
          color="textSecondary"
          display={{
            xs: "block",
            sm: "none",
          }}
        >
          By clicking "Continue", I hereby acknowledge that I have read and agree to Freedom Solar LLC's{" "}
          <Link
            color="primary"
            href="https://freedomsolarpower.com/privacy-policy"
            sx={{
              textDecoration: "none",
              fontWeight: 600,
            }}
          >
            Privacy Policy
          </Link>.
        </Typography>
      </MiddleContainer>

      <BottomCtaContainer>
        <Button
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={isDisabled || isLoading}
          fullWidth
          size="large"
        >
          {isLoading && (
            <CircularProgress color="inherit" size={16} thickness={4.5} />
          )}
          Continue
        </Button>
        <Typography
          variant="body1"
          color="textSecondary"
          display={{
            xs: "none",
            sm: "block",
          }}
        >
          By clicking "Continue", I hereby acknowledge that I have read and agree to Freedom Solar LLC's{" "}
          <Link
            color="primary"
            href="https://freedomsolarpower.com/privacy-policy"
            sx={{
              textDecoration: "none",
              fontWeight: 600,
            }}
          >
            Privacy Policy
          </Link>.
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          display={{
            xs: "none",
            sm: "flex",
          }}
        >
          <Typography variant="body1" color="textPrimary">
            Already have an account?
          </Typography>

          <Button variant="text" onClick={() => navigate("/signin")}>
            Login
          </Button>
        </Stack>
      </BottomCtaContainer>
    </>
  );
};
