import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export const Elevations = () => {
  return (
    <Box component={"section"} paddingY={5} paddingX={10}>
      <Box
        sx={{
          p: 2,
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr" },
          gap: 5,
        }}
      >
        {[0, 1, 2, 3, 4, 6, 8, 12, 16, 24].map((elevation) => (
          <Paper
            key={elevation}
            elevation={elevation}
            style={{ padding: 10, height: 60 }}
          >
            {`elevation=${elevation}`}
          </Paper>
        ))}
      </Box>
    </Box>
  );
};
