import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";
import { NotFound } from "./notFound";

//Pages
import { Theme } from "../pages/theme";
import { Signup } from "../pages/signup";
import { SignupEmail } from "../pages/signupEmail";
import { Signin } from "../pages/signin";
import { Logout } from "../pages/logout";
import { ForgotPassword } from "../pages/forgotPassword";
import { ResetPassword } from "../pages/resetPassword";
import { ImpersonateLanding } from "../pages/impersonateLanding";

import { OauthRedirect } from "../pages/oauthRedirect";

import { EmailOnboarding } from "../pages/onboarding/email";
import { EmailVerifyOnboarding } from "../pages/onboarding/emailVerify";

import { PhoneNumberOnboarding } from "../pages/onboarding/phoneNumber";
import { PhoneNumberVerifyOnboarding } from "../pages/onboarding/phoneNumberVerify";

import { SecondContact } from "../pages/onboarding/secondContact";
import { PropertyDetails } from "../pages/onboarding/propertyDetails";
import { WifiDetails } from "../pages/onboarding/wifiDetails";
import { HoaDetails } from "../pages/onboarding/hoaDetails";
import { SignupSuccess } from "../pages/onboarding/signupSuccess";

import { Account } from "../pages/account";
import { Project } from "../pages/project";
import { Documents } from "../pages/documents";
import { Support } from "../pages/support";
import { AllAccountDetails } from "../pages/account/allDetails";
import { AccountSecondContact } from "../pages/account/secondContact";
import { AccountContactPreferences } from "../pages/account/contactPreferences";
import { AccountPropertyDetails } from "../pages/account/propertyDetails";
import { AccountHoaDetails } from "../pages/account/hoaDetails";
import { AccountSystemDetails } from "../pages/account/systemDetails";
import { PersonalInfo } from "../pages/account/personalInfo";
import { AccountPassword } from "../pages/account/accountPassword";

import { ProjectMainScreen } from "../pages/project/projectMainScreen";
import { ProjectKickoff } from "../pages/project/kickoff";
import { SiteAssessment } from "../pages/project/siteAssessment";
import { SystemDesign } from "../pages/project/systemDesign";
import { ApplicationsPermits } from "../pages/project/applicationsPermits";
import { OnSiteInstallation } from "../pages/project/onSiteInstallation";
import { ThirdPartyInspections } from "../pages/project/thirdPartyInspections";
import { SystemLaunch } from "../pages/project/systemLaunch";

import { Folder } from "../pages/documents/folder";
import { FAQs } from "../pages/support/faqs";

import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from "../utils/history";

import { EventWrapper } from "../components/shared/eventWrapper";

export const AppRoutes = () => {
  return (
    // @ts-expect-error
    <HistoryRouter history={history}>
      <Routes>
        <Route
          path="theme"
          element={
            <EventWrapper>
              <Theme />
            </EventWrapper>
          }
        />
        <Route
          path="signin"
          element={
            <EventWrapper>
              <Signin />
            </EventWrapper>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="impersonate/:code" element={<ImpersonateLanding />} />
        <Route
          path="forgot-password"
          element={
            <EventWrapper>
              <ForgotPassword />
            </EventWrapper>
          }
        />
        <Route
          path="reset-password"
          element={
            <EventWrapper>
              <ResetPassword />
            </EventWrapper>
          }
        />
        <Route path="signup">
          <Route path="" element={<Navigate to="/signin" />} />
          <Route
            path=":id"
            element={
              <EventWrapper>
                <Signup />
              </EventWrapper>
            }
          />
          <Route
            path=":id/email"
            element={
              <EventWrapper>
                <SignupEmail />
              </EventWrapper>
            }
          />
        </Route>
        <Route path="oauth/redirect" element={<OauthRedirect />} />
        <Route path="onboarding" element={<ProtectedRoute />}>
          <Route path="" element={<Navigate to="email" />} />
          <Route path="email" element={<EmailOnboarding />} />
          <Route path="email/verify" element={<EmailVerifyOnboarding />} />
          <Route path="phone" element={<PhoneNumberOnboarding />} />
          <Route
            path="phone/verify"
            element={<PhoneNumberVerifyOnboarding />}
          />
          <Route path="second-contact" element={<SecondContact />} />
          <Route path="property-details" element={<PropertyDetails />} />
          <Route path="wifi-details" element={<WifiDetails />} />
          <Route path="hoa-details" element={<HoaDetails />} />
          <Route path="success" element={<SignupSuccess />} />
        </Route>
        <Route path=":portalId" element={<ProtectedRoute />}>
          <Route path="" element={<Navigate to="project" />} />
          <Route path="project" element={<Project />}>
            <Route path="" element={<ProjectMainScreen />} />
            <Route path="kickoff" element={<ProjectKickoff />} />
            <Route path="site-assessment" element={<SiteAssessment />} />
            <Route path="system-design" element={<SystemDesign />} />
            <Route
              path="applications-permits"
              element={<ApplicationsPermits />}
            />
            <Route
              path="on-site-installation"
              element={<OnSiteInstallation />}
            />
            <Route
              path="third-party-inspections"
              element={<ThirdPartyInspections />}
            />
            <Route path="system-launch" element={<SystemLaunch />} />
          </Route>
          <Route path="documents">
            <Route path="" element={<Documents />} />
            <Route path=":folder" element={<Folder />} />
          </Route>
          <Route path="account" element={<Account />}>
            <Route path="" element={<AllAccountDetails />} />
            <Route path="personal-information" element={<PersonalInfo />} />
            <Route path="change-password" element={<AccountPassword />} />
            <Route
              path="secondary-contact"
              element={<AccountSecondContact />}
            />
            <Route
              path="property-details"
              element={<AccountPropertyDetails />}
            />
            <Route path="hoa-details" element={<AccountHoaDetails />} />
            <Route path="system-details" element={<AccountSystemDetails />} />
          </Route>
          <Route path="support">
            <Route path="" element={<Support />} />
            <Route path="faqs" element={<FAQs />} />
          </Route>
        </Route>
        <Route path="" element={<ProtectedRoute />}>
          <Route path="no-access" element={<NotFound />} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
};
