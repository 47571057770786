import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "./theme";
import { SnackbarProvider } from "notistack";
import { customSnackbarComponents } from "./theme/snackbars";

import TagManager from "react-gtm-module";

import "./App.scss";
import { OfferDialog } from "./components/shared/offerDialog";

import Main from "./Main";
import { isSessionAuthenticated, removeImpersonate } from "./services/auth";
import { useAppDispatch, useAppSelector } from "./store/hook";

import { logout } from "./store/modules/meSlice";

const gtmId = process.env.REACT_APP_GTM_ID;

if (process.env.REACT_APP_HOST_ENV !== "development") {
  TagManager.initialize({ gtmId: gtmId });
}

function App() {
  const { isImpersonating, impersonateTtl, showOffer } = useAppSelector(
    (state) => state.me || ({} as any)
  );
  const dispatch = useAppDispatch();
  const [openOfferModal, setOpenOfferModal] = useState(false);

  useEffect(() => {
    const checkImpersonation = async () => {
      const isLoggedIn = await isSessionAuthenticated();

      if (isImpersonating && impersonateTtl) {
        if (!isLoggedIn) {
          removeImpersonate();
          return;
        }

        setInterval(() => {
          if (Number(impersonateTtl) < new Date().valueOf()) {
            console.log("Impersonation session expired!");
            Auth.signOut().then(() => {
              removeImpersonate();
              dispatch(logout());
              window.location.reload();
            });
          }
        }, 1000); // check every second
      }
    };

    checkImpersonation();
  }, []);

  useEffect(() => {
    setOpenOfferModal(showOffer)
  }, [showOffer]);

  return (
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        Components={customSnackbarComponents}
        autoHideDuration={3000}
      >
        <Main />
      </SnackbarProvider>
      <OfferDialog open={openOfferModal} />
    </ThemeProvider>
  );
}

export default App;
