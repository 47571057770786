import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import FullLogo from "../assets/images/full_logo.svg";
import { OauthContent } from "../components/signup/oauthContent";
import { Features } from "../components/signup/features";

export const Signup = () => {
  const { id: inviteId } = useParams();
  return (
    <>
      <Box
        component="header"
        width="100%"
        position="fixed"
        top={0}
        padding={10}
        display={{ xs: "none", sm: "block" }}
      >
        <img src={FullLogo} alt="full logo" loading="lazy" />
      </Box>
      <Stack direction="row" alignItems="stretch" minHeight="100dvh">
        <OauthContent inviteId={inviteId} />
        <Features />
      </Stack>
    </>
  );
};
