import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export const PasswordRequirements = ({ errors }: any) => {
  const getStatus = (statusKey: any) => {
    const passwordErr = errors.password;
    return passwordErr[statusKey as keyof typeof passwordErr]
      ? "default"
      : "success";
  };

  const getStatusIcon = (statusKey: string) => {
    const passwordErr = errors.password;
    return passwordErr[statusKey as keyof typeof passwordErr] ? (
      <CloseIcon sx={{ visibility: "hidden" }} />
    ) : (
      <CheckIcon />
    );
  };

  return (
    <>
      {errors.confirm_password && (
        <Typography variant="body1" color="text.light">
          Password requirements:
        </Typography>
      )}
      <Stack direction="row" gap={1} flexWrap="wrap">
        <Stack direction="column" gap={2} width="max-content">
          <Chip
            icon={getStatusIcon("characters")}
            label="8 characters"
            color={getStatus("characters")}
            sx={{
              background: "transparent",
            }}
          />
          <Chip
            icon={getStatusIcon("numbers")}
            label="number"
            color={getStatus("numbers")}
            sx={{
              background: "transparent",
              justifyContent: "flex-start",
            }}
          />
        </Stack>
        <Stack direction="column" gap={2} width="max-content">
          <Chip
            icon={getStatusIcon("upperCases")}
            label="uppercase letter "
            color={getStatus("upperCases")}
            sx={{
              background: "transparent",
              justifyContent: "flex-start",
            }}
          />
          <Chip
            icon={getStatusIcon("specialCharacters")}
            label="special character"
            color={getStatus("specialCharacters")}
            sx={{
              background: "transparent",
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};
