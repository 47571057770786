import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface UnhappyBannerProps {
    variant: 'info' | 'danger' | 'dangerT';
    title: string;
    subtitle?: string;
    desc: any;
    cta?: any;
}

export const UnhappyBanner = ({ variant, title, subtitle, desc, cta }: UnhappyBannerProps) => {
    const isDanger = variant === "danger"

    return (
        <Paper sx={{border: "1px solid #E8EBED", minHeight: "76px", padding: 4, borderRadius: {sm: "8px", xs: 0}}}>
            <Stack sx={{flexDirection: {xs: "column", sm: "row"}, alignItems: {xs: "flex-start", sm: "center"}, gap: {xs: "16px", sm: "30px"}}} height="100%">
                <Stack direction="row" gap="15px" alignItems="center" flex={isDanger ? "" : "auto"}>
                    {isDanger ? <WatchLaterRoundedIcon sx={{color: "#D92D20"}} /> : variant === "dangerT" ? <ReportProblemIcon sx={{color: "#D92D20"}} /> : <ErrorIcon sx={{color: "#2660DF"}} />}
                    <Stack gap="3px">
                        <Typography fontWeight={600} sx={{whiteSpace: {xs: "normal", sm: "nowrap"}}} fontSize="16px">{title}</Typography>
                        {!isDanger && <Typography variant="body2">{desc}</Typography>}
                    </Stack>
                </Stack>
                {
                    isDanger && 
                    <Stack gap="3px" flex="auto" sx={{borderLeft: "1px solid #B4BDC5", paddingLeft: "30px"}}>
                        <Typography variant="body2" fontWeight="600 !important">{subtitle}</Typography>
                        <Typography variant="body2">{desc}</Typography>
                    </Stack>
                }
                {cta}
            </Stack>
        </Paper>
    )
}