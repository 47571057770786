import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AccordionIconList } from "./accordionIconList";
import { AccordionList } from "./accordionList";
import { SimpleList } from "./simpleList";
import { IconList } from "./iconList";
import { LinkList } from "./linkList";

export const ListLayout = ({
  width,
  title,
  listType,
  listData,
  validate = false,
}: any) => {
  return (
    <Stack gap={4} direction="column" width={{ xs: "100%", md: width }}>
      <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
        {title}
      </Typography>
      {listType === "accordionIcon" && (
        <AccordionIconList listData={listData} validate={validate} />
      )}
      {listType === "accordion" && <AccordionList listData={listData} />}
      {listType === "accordionFaq" && (
        <AccordionList listData={listData} forFaq />
      )}
      {listType === "simple" && <SimpleList listData={listData} />}
      {listType === "icon" && (
        <IconList listData={listData} validate={validate} />
      )}
      {listType === "linkList" && <LinkList listData={listData} />}
    </Stack>
  );
};
