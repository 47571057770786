import { useLayoutEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import { DashboardLayout } from "../../components/dashboard/dashboardLayout";
import { gray } from "../../theme/colors";
import { Stages } from "../../components/project/stages";
import { getCurrentProjectStage } from "../../components/constant/navigation";
import { useAppSelector } from "../../store/hook";
import { Banners } from "./banners";

export const Project = () => {
  const { id, tracker } = useAppSelector(
    (state) => state.portal || ({} as any)
  );

  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (location.pathname.includes(`/${id}/project`)) {
      const currentProjectState = getCurrentProjectStage({ id, tracker });
      if (location.pathname !== currentProjectState.to) {
        if (
          currentProjectState.key === "projectKickoff" &&
          ![`/${id}/project`, `/${id}/project/kickoff`].includes(
            location.pathname
          )
        ) {
          navigate(`/${id}/project`);
        } else {
          navigate(
            currentProjectState.key === "projectKickoff"
              ? `/${id}/project`
              : currentProjectState.to
          );
        }
      }
    }
  }, []);

  return (
    <DashboardLayout>
      <Stack gap={{ xs: 0, sm: 10 }}>
        <Banners/>
        <Stack direction="row" gap={6}>
          <Stack
            display={{
              xs: "none",
              md: "flex",
            }}
          >
            <Stages />
          </Stack>
          <Paper
            sx={{
              display: "flex",
              flex: "auto",
              flexDirection: "column",
              background: {
                xs: gray[25],
                sm: gray[0],
              },
              borderRadius: {
                xs: 0,
                sm: "8px",
              },
              boxShadow: {
                xs: 0,
                sm: 3,
              },
            }}
          >
            <Outlet />
          </Paper>
        </Stack>
      </Stack>
    </DashboardLayout>
  );
};
