import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { Amplify, Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { UserbackProvider } from '@userback/react';

import { setupStore } from "./store";

import App from "./App";
// const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
// console.log({isLocalhost})

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    environment: process.env.REACT_APP_HOST_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    autoSessionTracking: false,
    tracesSampleRate: 0,
  });
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "SECURED_API",
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            'X-Sunrise-Impersonator': localStorage.getItem('impersonator')
          };
        },
      },
      {
        name: "PUBLIC_API",
        endpoint: process.env.REACT_APP_API_URL,
      },
    ],
  },
});


const rootElement = document.getElementById("root");
const reactRoot = ReactDOM.createRoot(rootElement as HTMLElement);

const store = setupStore();

setupListeners(store.dispatch);

const userBackToken: any = process.env.REACT_APP_USERBACK_TOKEN
const isDevelopment = process.env.REACT_APP_HOST_ENV === 'development';

reactRoot.render(
  <Provider store={store}>
    {isDevelopment ? 
      <App />
    :
      <UserbackProvider token={userBackToken}>
        <App />
      </UserbackProvider>
    }
  </Provider>
);
