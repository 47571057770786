import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { StageProjectCanceledAlert } from "../shared/stageProjectCanceledAlert";

import { useAppSelector } from "../../store/hook";

export const StageTopContent = ({
  stage,
  headerText,
  info,
  subHeaderText,
  children,
}: any) => {
  const { isCanceled } = useAppSelector((state) => state.portal || ({} as any));

  return (
    <Stack
      paddingX={{ xs: 0, sm: 6 }}
      paddingTop={{ xs: 0, sm: 10 }}
      paddingBottom={10}
      gap={5}
      borderBottom="1px solid #E8EBED"
      sx={{
        background: "#FFFFFF",
        borderTopLeftRadius: { xs: 0, sm: 8 },
        borderTopRightRadius: { xs: 0, sm: 8 },
      }}
    >
      <Stack gap={2} display={{ xs: "none", sm: "flex" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" color="textPrimary" fontWeight={600}>
            {headerText}
          </Typography>
          <Typography variant="body1" sx={{ color: "#5E6E7D " }}>
            {info}
          </Typography>
        </Stack>
        <Typography variant="body1" color="textSecondary">
          {subHeaderText}
        </Typography>
      </Stack>
      {isCanceled && !stage.complete ? <StageProjectCanceledAlert /> : children}
      <Stack
        gap={2}
        display={{ xs: "flex", sm: "none" }}
        direction="column"
        paddingX={6}
      >
        <Typography variant="body1" color="textSecondary">
          {subHeaderText}
        </Typography>
        <Typography variant="body1" sx={{ color: "#5E6E7D " }}>
          {info}
        </Typography>
      </Stack>
    </Stack>
  );
};
