import _ from "lodash";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import LinkElment from "@mui/material/Link";
import { useAppSelector } from "../../store/hook";
import { Requirements } from "../../components/project/requirements";
import { Requirement } from "../../components/project/requirement";

export const StageProjectCanceledAlert = () => {
  const { fields } = useAppSelector((state) => state.portal || ({} as any));

  const canceledProjectCopy = {
    'Customer Initiated Cancellation': (
    <Typography variant="body1" color="textSecondary">
      Your request to cancel your project is confirmed. To restart your project, please connect with our team at <LinkElment
          component={Link}
          to="mailto:customerservice@freedomsolarpower.com"
        >
          customerservice@freedomsolarpower.com
        </LinkElment>. We'd be delighted to assist you in restarting or exploring alternative solutions to better meet your needs.
    </Typography>
    ),
    'Customer Unresponsive':  (
      <Typography variant="body1" color="textSecondary">
        We haven’t been able to get in touch with you, so your solar installation project has been canceled. To restart your project, please connect with our team at <LinkElment
          component={Link}
          to="mailto:customerservice@freedomsolarpower.com"
        >
          customerservice@freedomsolarpower.com
        </LinkElment>. We'd be delighted to assist you in restarting or exploring alternative solutions to better meet your needs.
      </Typography>
    ),
    'Freedom Solar DNQ': (
      <Typography variant="body1" color="textSecondary">
        Your solar project has been canceled. To restart your project, please connect with our team at <LinkElment
          component={Link}
          to="mailto:customerservice@freedomsolarpower.com"
        >
          customerservice@freedomsolarpower.com
        </LinkElment>. We'd be delighted to assist you in restarting or exploring alternative solutions to better meet your needs.
      </Typography>
    )
  };

  return (
    <Requirements isCanceled={true}>
      {fields.retention_reason && <Requirement
        subText={_.get(canceledProjectCopy, [fields.retention_reason], '')}
      />}
    </Requirements>
  );
};
